import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import appStatic from "../../config/appStaticData";
import { TableFacility } from "../layouts/Facility";
import Notiflix from "notiflix";

const HandOver = (props) => {
  const { location, userData, staticData, history } = props;
  const { state } = location;
  const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {

    goBack();
    // console.log(state.ggParam);
    getCompany();
  }, []);

  function getCompany() {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId: PROFILE_ID,
      userToken: token,
    };

    // console.log(body);

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.companyListing;

        setCompanyData(objList);
      }
    });
  }
  const onFormSubmit = (objParam) => {
    const body = {
      profileId: PROFILE_ID,
      coId: objParam.CO_ID,
      episodeId: ggParam.EP_ID,
      userToken: token,
    };

    // console.log(body);
    // return;
    const statusLabel =
      appLabel.handOver + " your patient to Dr " + objParam.USER_FULLNAME;
    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
    BOM.LoadAlert(cnt.LOAD, "Processing");
        BOM.FetchReqAction(body, api.NewHandOver, (err, res) => {
          if (err) {
            BOM.NotifyMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);
            getCompany();
          }
        });
      },
      function () {
        // No button
      }
    );
  };

  function goBack(){
    BOM.AlertMsg(cnt.WARNING, 'Hand Over module is not yet available. Please contact system administration.');
    history.goBack()
    return;
  }

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.handOver} />

      <div className="animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              {/* <div className="ibox-title">
                <div className="ibox-tools">
                  <span className="label label-navy-light pull-right">
                    {`${companyData.length > 1
                      ? companyData.length + " Records"
                      : companyData.length + " Record"
                      }`}
                  </span>
                </div>
              </div> */}
              <div className="ibox-content panel-primarys contentCard">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="feed-activity-list">
                      {companyData.length === 0 && (
                        <div className="alert alert-warning text-center">
                          <a className="alert-link">
                            {appLabel.noDataAvailableMsg}
                          </a>
                        </div>
                      )}
                      {companyData.map(
                        (item, index) =>
                          item.PROFILE_ID !== PROFILE_ID && (
                            <div key={index} className="feed-element">
                              <span className="pull-left text-navys">
                                <i className="fa fa-hospital-o fa-2x" />
                              </span>
                              <div className="media-body ">
                                <strong>{item.CO_NAME}</strong> <br />
                                <div className="well">
                                  <i className="fa fa-user-md text-navy fa-2x" />{" "}
                                  &nbsp;{item.USER_FULLNAME}
                                </div>
                                <div className="actions">
                                  <a
                                    onClick={() => onFormSubmit(item)}
                                    className={`${
                                      ROLE_SHORT.toLowerCase() === "sys"
                                        ? "hide"
                                        : "btn btn-xs btn-white m-r"
                                    }`}
                                  >
                                    <i className="fa fa-arrow-circle-right text-info" />{" "}
                                    &nbsp;
                                    {appLabel.proceedBtn}
                                  </a>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(HandOver);
