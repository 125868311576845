import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import appLabel from "../../config/appLabel";

const ContentTop = ({ pageTitle }) => {
  useEffect(() => {}, []);
  return (
      <div
        className="row wrapper border-bottom white-bg page-heading"
        style={{ marginBottom: "20px" }}
      >
        <div className="col-lg-10">
          <h2 style={{ fontSize: "18px" }}>{pageTitle}</h2>
          <ol className="breadcrumb">
            <li style={{ fontSize: "12px" }}>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="active" style={{ fontSize: "12px" }}>
              <strong>{pageTitle}</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2 pull-right m-t"><span className="label label-primary"><i className="fa fa-star text-navy"/>&nbsp;{appLabel.version}</span></div>
        {/* <div className="col-lg-2 pull-right m-t"><span className="label label-primary"><i className="fa fa-copy text-navy"/>&nbsp;Invite Doctors</span></div> */}
      </div>
  );
};

export default ContentTop;
