import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import RightSide from "../../ui-setup/RightSide";
import AppModal from "../../layouts/AppModal";
import appStatic from "../../../config/appStaticData";

function CompanyDetail(props) {
  const { location, userData } = props;
  const { state } = location;
  const { PROFILE_ID, token } = userData;

  const [companyDetail, setCompanyDetail] = useState({});

  useEffect(() => {
    // console.log(state.ggParam);
    getDetailById(state.ggParam);
  }, []);
  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId: PROFILE_ID,
      coId: params.CO_ID,
      userToken: token,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let companyDetail = res.companyDetail;
        setCompanyDetail(companyDetail);
      }
    });
  };

  const updateItem = (coStatus, statusLabel) => {
    let body = {
      profileId: PROFILE_ID,
      coId: companyDetail.CO_ID,
      coStatus,
      userToken: token,
    };

    // console.log(body);

    BOM.GlobalApproveRejectAction(
      body,
      api.ActiveInactiveCompany,
      "manage-company",
      companyDetail.CO_NAME,
      statusLabel + " l'entreprise "
    );
  };


  return (
    <Main {...props}>
      <form method="POST" autoComplete="off">
        <div
          className="row wrapper border-bottom white-bg page-heading"
          style={{ marginBottom: "20px" }}
        >
          <div className="col-lg-4">
            <h2>{appLabel.coDetail}</h2>
            <ol className="breadcrumb">
              <li>
                <Link to="/dashboard">Tableau de bord</Link>
              </li>
              <li className="active">
                <strong>{appLabel.coDetail}</strong>
              </li>
            </ol>
          </div>
          <div className="col-sm-8">
          <div className="title-action">
            {companyDetail.CO_STATUS === "DEACTIVATED" && (
              <div className="col-sm-4">
                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  onClick={() => updateItem("ACTIVATED", appLabel.activateBtn)}
                >
                  <i className="fa fa-thumbs-up" />
                  &nbsp; {appLabel.activateBtn}
                </button>
              </div>
            )}

            {companyDetail.CO_STATUS === "ACTIVATED" && (
              <div className="col-sm-4">
                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  onClick={() => updateItem("DEACTIVATED", appLabel.deactivateBtn)}
                >
                  <i className="fa fa-thumbs-down" />
                  &nbsp; {appLabel.deactivateBtn}
                </button>
              </div>
            )}
            {/* <div className="col-sm-2">
              <button
                type="button"
                className="btn btn-info btn-sm"
                onClick={() => deleteItem()}
              >
                <i className="fa fa-times" />
                &nbsp; {appLabel.deleteBtn}
              </button>
            </div> */}
            </div>
          </div>
        </div>
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="ibox-tools">
                    <Link
                      to="manage-company"
                      className="btn btn-info btn-xs"
                    >
                      <i className="fa fa-arrow-circle-left text-info" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle text-navy" />
                        &nbsp; {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      
                      <div className="form-group col-md-3">
                        <label className="al-subtitle">
                          {appLabel.coName}&nbsp;
                          
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          defaultValue={companyDetail.CO_NAME}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label className="al-subtitle">
                          {appLabel.coEmail}&nbsp;
                          
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          defaultValue={companyDetail.CO_EMAIL}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label className="al-subtitle">
                          {appLabel.coPhone}&nbsp;
                          
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          defaultValue={companyDetail.CO_PHONE}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label className="al-subtitle">
                          {appLabel.coReg}&nbsp;
                          
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          defaultValue={companyDetail.CO_REG}
                        />
                      </div>
                      
                    </div>
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle text-navy" />
                        &nbsp; {appLabel.addressInformation}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="form-group col-md-5">
                        <label className="al-subtitle">
                          {appLabel.coAddress}&nbsp;
                          
                        </label>
                        <textarea
                          rows={5}
                          cols={50}
                          className="form-control input-sm"
                          disabled
                          defaultValue={companyDetail.CO_ADDRESS}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="al-heading">
                        <i className="fa fa-circle text-navy" />
                        &nbsp; {appLabel.recordStatus}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />
                      <div className="form-group col-md-2">
                        <label className="al-subtitle">
                          {appLabel.status}&nbsp;
                          
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          value={companyDetail.CO_STATUS === 'ACTIVATED' ? 'Activé':'Desactivé'}
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <label className="al-subtitle">
                          {appLabel.makerId}&nbsp;
                          
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          defaultValue={companyDetail.MAKER_ID}
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <label className="al-subtitle">
                          {appLabel.makerDate}&nbsp;
                          
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          defaultValue={companyDetail.MAKER_DATE}
                        />
                      </div>

                      <div className="form-group col-md-2">
                        <label className="al-subtitle">
                          {appLabel.checkerId}&nbsp;
                          
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          value={!companyDetail.CHECKER_ID ?  'Jamais mis à jour': companyDetail.CHECKER_ID}
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <label className="al-subtitle">
                          {appLabel.checkerDate}&nbsp;
                          
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          value={!companyDetail.CHECKER_DATE ?  'Jamais mis à jour' : companyDetail.CHECKER_DATE}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail);
