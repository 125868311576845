import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import appStatic from "../../../config/appStaticData";
import { TableFacility } from "../../layouts/Facility";
import Notiflix from "notiflix";


const ManageLocation = (props) => {
  const { location, userData, staticData } = props;
  const { state } = location;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;
  const { tableData } = appStatic;
  const [locationData, setLocationData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [recordCount, setRecordCount] = useState(0);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: "",
      color: "primary",
      icon: "thumbs-up",
      criteria: "ACTIVATED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: "",
      color: "warning",
      icon: "thumbs-down",
      criteria: "DEACTIVATED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    getListByCriteria(state ? state.ggParam : tiles[0]);
  }, []);

  function getListByCriteria(params) {

    // console.log(params);
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);
    setRecordCount(0)

    setLocationData([]);
    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: params.criteria,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetLocation, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.locationListing;

        /** ADD KEY IN EACH OBJECT */
        const tempArr = objList.map((item) => {
          return {
            ...item,
            isSelected: false,
          };
        });
        setLocationData(tempArr);
        setIsLoading(false);
        setRecordCount(tempArr.length)
      }
    });
  }

  const handleStatusBtn = (key) => {
    switch (key.toLowerCase()) {
      case "activated":
        return (
          <>
            <i className="fa fa-thumbs-down text-info" />{" "}
            {appLabel.deactivateBtn}
          </>
        );
      case "deactivated":
        return (
          <>
            <i className="fa fa-thumbs-up text-info" /> {appLabel.activateBtn}
          </>
        );
    }
  };
  const updateItem = (objParam) => {
    // console.log(objParam);

    const { LO_STATUS, LO_ID, LO_NAME } = objParam;
    const body = {
      profileId: PROFILE_ID,
      loId: LO_ID,
      userToken: token,
    };
    let statusLabel = "";
    switch (LO_STATUS.toLowerCase()) {
      case "activated":
        body.criteria = "DEACTIVATED";
        statusLabel = appLabel.deactivateBtn + ' ' + objParam.LO_NAME;
        break;
      case "deactivated":
        body.criteria = "ACTIVATED";
        statusLabel = appLabel.activateBtn + ' ' + objParam.LO_NAME;
        break;
    }

    // BOM.SendReqWithConfirm(body, api.ActiveInactiveLocation, statusLabel);

    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.ActiveInactiveLocation, (err, res) => {
          if (err) {
            // BOM.AlertMsg(cnt.DANGER, err);
            BOM.NotifyMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);

            /** REDIRECTION TABLE */
            switch (LO_STATUS.toLowerCase()) {
              case 'activated':
                getListByCriteria(tiles[0])
                break;
              case 'deactivated':
                getListByCriteria(tiles[1])
                break;
              default:
                getListByCriteria(tiles[0])
                break;
            }
          }
        });
      },
      function () {
        // No button
      }
    );
  };
  const handleEditBtn = (objParam, keyParam) => {
    // console.log(objParam);

    switch (keyParam.toLowerCase()) {
      case "proceed":
        var tempArr = locationData.map((item) =>
          item.LO_ID === objParam.LO_ID
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        );
        break;

      default:
        var tempArr = locationData.map((item) =>
          item.LO_ID === objParam.LO_ID
            ? { ...item, isSelected: false }
            : { ...item, isSelected: false }
        );
        break;
    }

    setLocationData(tempArr);
  };
  const onFormSubmit = (objParam) => {
    const { LO_ID, LO_NAME, LO_STATUS } = objParam;
    // console.log(objParam);

    const loName = document.getElementById("locationName" + LO_ID).value;
    const body = {
      profileId: PROFILE_ID,
      loId: LO_ID,
      loName,
      userToken: token,
    };

    // console.log(body);
    // return;
    const statusLabel = appLabel.editLocation;
    // BOM.SendReqWithConfirm(body, api.EditLocation, statusLabel);
    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.EditLocation, (err, res) => {
          if (err) {
            // BOM.AlertMsg(cnt.DANGER, err);
            BOM.NotifyMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);

            /** REDIRECTION TABLE */
            switch (LO_STATUS.toLowerCase()) {
              case 'activated':
                getListByCriteria(tiles[0])
                break;
              case 'deactivated':
                getListByCriteria(tiles[1])
                break;
              default:
                getListByCriteria(tiles[0])
                break;
            }
          }
        });
      },
      function () {
        // No button
      }
    );

  };

  const deleteItem = (objParam) => {
    const { LO_ID, LO_NAME } = objParam;

    let body = {
      profileId: PROFILE_ID,
      itemId: LO_ID,
      itemType: 'location',
      userToken: token,
    };

    // console.log(body);

    const statusLabel = appLabel.deleteBtn + ' ' + LO_NAME;
    // BOM.SendReqWithConfirm(body, api.DeleteItem, statusLabel);

    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.DeleteItem, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
            // BOM.NotifyMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);
            setLocationData(locationData.filter((item) => item.LO_ID !== LO_ID));
          }
        });
      },
      function () {
        // No button
      }
    );
  };

  const handleSkeletonFac = () =>
  Array(4)
    .fill()
    .map((el, elIndex) => (
      <div key={elIndex} className="feed-element">
        <span className="pull-left text-navy">
          <Skeleton circle={true} height={35} width={35} />
        </span>
        <div className="media-body ">
          <small className="pull-right"></small>
          <strong>
            {" "}
            <Skeleton style={{width:'80%'}} />
          </strong>{" "}
          <div className="actions">
            <a className="btn btn-xs btn-white">
              <Skeleton height={18} width={40} />
            </a>
            <a className="btn btn-xs btn-white">
              <Skeleton height={18} width={40} />
            </a>
            <a className="btn btn-xs btn-white">
              <Skeleton height={18} width={40} />
            </a>
          </div>
        </div>
      </div>
    ));

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.manageLocation} />

      <div className="animated fadeInRight">
        <div className="row m-b">
          <div className="col-xs-12 col-xs-offset-2 col-sm-6 col-sm-offset-4 col-md-6 col-md-offset-4">
            <div data-toggle="buttons" className="btn-group">
              <label
                className={`${ROLE_SHORT.toLowerCase() === "sys"
                  ? "hide"
                  : "btn btn-sm btn-white"
                  }`}
              >
                <Link to="/new-location" style={{ color: "inherit" }}>
                  <i className="fa fa-plus-circle text-info" /> &nbsp; {appLabel.add1}{" "}
                </Link>
              </label>
              <label className="btn btn-sm btn-white">
                <a
                  className="dropdown-toggle"
                  style={{ color: "inherit" }}
                  data-toggle="dropdown"
                >
                  <i className={`fa fa-${icon} text-info`}></i> &nbsp; 
                  {/* {titleRight} &nbsp; */}
                  {`${titleRight} ( ${recordCount} )`} &nbsp;
                  <i className="fa fa-chevron-down"></i>
                </a>
                <ul className="dropdown-menu dropdown-user">
                  {tiles.map((tile, tileIndex) => {
                    if (tile.title !== titleRight) {
                      return (
                        <li key={tileIndex}>
                          <a onClick={() => tile.callback(tile)}>
                            {" "}
                            {tile.title}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                {/* <h5></h5> */}
                <div className="ibox-tools">
                  <span className="label label-navy-light pull-right">
                    {`${locationData.length > 1
                      ? locationData.length + " Records"
                      : locationData.length + " Record"
                      }`}
                  </span>
                </div>
              </div>
              <div className="ibox-content panel-primary contentCard">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="feed-activity-list">
                    {isLoading ? (
                        handleSkeletonFac()
                      ) : (
                        <>
                      {locationData.length === 0 && (
                        <div className="alert alert-warning text-center">
                          <a className="alert-link">
                            {appLabel.noDataAvailableMsg}
                          </a>
                        </div>
                      )}
                      {locationData.map((item, index) => (
                        <div key={index} className="feed-element">
                          <span className="pull-left text-navy">
                            <i className="fa fa-map-marker fa-2x" />
                          </span>
                          <div className="media-body ">
                            <small className="pull-right"></small>
                            <strong>{item.LO_NAME}</strong> <br />

                            {/* <div
                              className={`${item.isSelected ? "hide" : "well"}`}
                            >
                              {'No Description'}
                            </div> */}
                            <div className={`${item.isSelected ? "" : "hide"}`}>
                              <input
                                type="text"
                                className="form-control  list-text-area"
                                id={`locationName${item.LO_ID}`}
                                placeholder={appLabel.locationName}
                                defaultValue={item.LO_NAME}
                              />
                              {/* <textarea
                                rows={2}
                                cols={5}
                                className="form-control  list-text-area"
                                id={`loDescription${item.LO_ID}`}
                                placeholder={appLabel.codeDescription}
                                defaultValue={'No desscription'}
                              ></textarea> */}
                            </div>
                            <div
                              className={`${item.isSelected ? "hide" : "actions"
                                }`}
                            >
                              <a
                                className="btn btn-xs btn-white m-r"
                                onClick={() => handleEditBtn(item, "proceed")}
                              >
                                <i className="fa fa-edit text-info" />{" "}
                                {appLabel.edit}
                              </a>
                              <a
                                className="btn btn-xs btn-white m-r"
                                onClick={() => updateItem(item)}
                              >
                                {handleStatusBtn(item.LO_STATUS)}
                              </a>
                              <a
                                className="btn btn-xs btn-white"
                                onClick={() => deleteItem(item)}
                              >
                                <i className="fa fa-times text-info" />{" "}
                                {appLabel.deleteBtn}
                              </a>
                            </div>
                            <div
                              className={`${item.isSelected ? "actions" : "hide"
                                }`}
                            >
                              <a
                                className="btn btn-xs btn-white m-r"
                                onClick={() => onFormSubmit(item)}
                              >
                                <i className="fa fa-save text-info" />{" "}
                                {appLabel.save}
                              </a>
                              <a
                                className="btn btn-xs btn-white m-r"
                                onClick={() => handleEditBtn(item, "cancel")}
                              >
                                <i className="fa fa-times text-navy" />{" "}
                                {appLabel.cancelBtn}
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                      </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageLocation);
