/**
 * @author Gedeon NdundeCode
 * @date 06/12/2021
 */

import { combineReducers } from "redux";
import auth from "../../redux/reducers/auth-red";
import { recordStatus, staticDataRed as staticData, rightSideBtnRed as rightSideBtn } from "../reducers/staticData-red";
import { securityDataRed as securityData } from "./security-red";

export default combineReducers({
  auth,
  staticData,
  rightSideBtn,
  recordStatus
});
