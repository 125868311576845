import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import appStatic from "../../config/appStaticData";
import { ColBlockFac, handleSkeletonFac } from "../layouts/Facility";
import Select from "react-select";

const ManageEpisode = (props) => {
  const { location, userData, staticData, history } = props;
  const { state, pathname } = location;
  // const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const { tableData, skeletonCountData } = appStatic;
  const [episodeData, setEpisodeData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backUpData, setbackUpData] = useState("");
  const [currentSearchCriteria, setCurrentSearchCriteria] = useState("");

  const [locationData, setLocationData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [episodeCount, setEpisodeCount] = useState(0);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.new,
      value: 30,
      icon: "users",
      color: "primary",
      criteria: "NEW",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    // {
    //   title: appLabel.inProgress,
    //   value: 13,
    //   icon: "refresh",
    //   color: "warning",
    //   criteria: "IN PROGRESS",
    //   callback: function (params) {
    //     getListByCriteria(params);
    //   },
    // },
    {
      title: appLabel.discharged,
      value: 61,
      icon: "tags",
      color: "navy",
      criteria: "DISCHARGED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.rejected,
      value: 2,
      icon: "thumbs-down",
      color: "danger",
      criteria: "REJECTED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.private,
      value: 211,
      icon: "rub",
      color: "none",
      criteria: "PRIVATE",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.closed,
      value: 230,
      icon: "gavel",
      color: "none",
      criteria: "CLOSED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);
  const searchCriteria = [
    {
      title: appLabel.patient,
      criteria: "patient",
      callback: function (params) {
        handleSearchCriteriaShowHide(appLabel.patient, params);
      },
    },
    {
      title: appLabel.locationName,
      criteria: "location",
      callback: function (params) {
        handleSearchCriteriaShowHide(appLabel.locationName, params);
      },
    },
    {
      title: appLabel.entryDate,
      criteria: "date",
      callback: function (params) {
        handleSearchCriteriaShowHide(appLabel.entryDate, params);
      },
    },
  ];

  useEffect(() => {
    // console.log(props);
    getListByCriteria(state ? state.ggParam : tiles[0]);
    getLocation();
    getPatient();
  }, []);

  function getListByCriteria(params) {
    //calling function
    handleShowHide(params);

    setIsLoading(true);
    setEpisodeData([]);
    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);
    setEpisodeCount(0)
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: params.criteria,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetEpisode, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setEpisodeData(res.episodeListing);
        setbackUpData(res.episodeListing);
        setIsLoading(false);
        setEpisodeCount(res.episodeListing.length)
      }
    });
  }

  function handleShowHide(objParam) {
    setShow(false);
    const { criteria } = objParam;
    switch (criteria.toLowerCase()) {
      case "new":
      case "in progress":
        setShow(true);
        break;

      default:
        setShow(false);
        break;
    }
  }

  const onSearch = (keyword) => {
    switch (keyword) {
      case "":
        console.log("empty search...");
        setEpisodeData(backUpData);
        break;

      default:
        //search with all cases
        keyword = keyword.toLowerCase();
        const filtered = episodeData.filter((entry) =>
          Object.values(entry).some(
            (val) =>
              typeof val.toLowerCase() === "string" &&
              val.toLowerCase().includes(keyword)
          )
        );

        // const filtered = episodeData.filter((entry) =>
        //   Object.values(entry).some(
        //     (val) =>
        //       typeof val === "string" &&
        //       val.includes(keyword)
        //   )
        // );

        setEpisodeData(filtered);
        // if (filtered.length > 0) {
        //   setEpisodeData(filtered);
        // } else {
        //   // setEpisodeData(backUpData);
        // }

        break;
    }
  };

  const handleSearchCriteriaShowHide = (criteria, params) => {
    // console.log(params);
    setCurrentSearchCriteria(criteria);
    setEpisodeData(backUpData)


  };

  function getLocation() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: "ACTIVATED",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetLocation, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // setLocationData(res.locationListing);

        let objList = res.locationListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.LO_ID,
            label: element.LO_NAME,
            ...element,
          });
        });

        setLocationData(temp);
      }
    });
  }
  function getPatient() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: "ACTIVATED",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetPatient, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.patientListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.PATIENT_ID,
            label: element.PATIENT_FULLNAME,
            ...element,
          });
        });

        setPatientData(temp);
      }
    });
  }

  function onSearchCriteria(param, key) {
    // console.log(param);
    // console.log(key);
    
    var filtered;

    // console.log(filtered);

    switch (key) {
      case "patient":
        filtered = backUpData.filter(
          (item, index) => item.PATIENT_ID === param
        );
        break;
      case "location":
        filtered = backUpData.filter((item, index) => item.LO_ID === param);
        break;

      case "date":
        filtered = backUpData.filter(
          (item, index) => item.START_DATE === param
        );
        break;
    }

    setEpisodeData(filtered);
  }
  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.manageEpisode} />
      <div className="animated fadeInRight">
        <div className="row m-b">
          <div className="col-xs-12 col-xs-offset-1 col-sm-6 col-sm-offset-4 col-md-6 col-md-offset-4">
            {/* <div className="col-sm-6 col-lg-4"> */}
            <div data-toggle="buttons" className="btn-group">
              {/* <label
                className="btn btn-sm btn-white"
                onClick={() => BOM.BackButton()}
              >
                {" "}
                <i className="fa fa-long-arrow-left text-info" />{" "}
                {appLabel.back} &nbsp;
              </label> */}
              <label
                className={`${
                  ROLE_SHORT.toLowerCase() === "sys"
                    ? "hide"
                    : "btn btn-sm btn-white"
                }`}
              >
                <Link to="/new-episode" style={{ color: "inherit" }}>
                  <i className="fa fa-plus-circle text-info" /> &nbsp;{" "}
                  {appLabel.add1}{" "}
                </Link>
              </label>
              <label className="btn btn-sm btn-white">
                <a
                  className="dropdown-toggle"
                  style={{ color: "inherit" }}
                  data-toggle="dropdown"
                >
                  <i className={`fa fa-${icon} text-info`}></i>{" "}
                  {/* {titleRight} &nbsp; */}
                  {`${titleRight} ( ${episodeCount} )`} &nbsp;
                  <i className="fa fa-chevron-down"></i>
                </a>
                <ul className="dropdown-menu dropdown-user">
                  {tiles.map((tile, tileIndex) => {
                    if (tile.title !== titleRight) {
                      return (
                        <li key={tileIndex}>
                          <a onClick={() => tile.callback(tile)}>
                            {" "}
                            {tile.title}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </label>
              {/* <label className="btn btn-sm btn-white">
                <a
                  className="dropdown-toggle"
                  style={{ color: "inherit" }}
                  data-toggle="dropdown"
                >
                  <i className={`fa fa-search text-info`}></i> &nbsp;{" "}
                  {`${appLabel.search} ${currentSearchCriteria}`} &nbsp;
                  <i className="fa fa-chevron-down"></i>
                </a>
                <ul className="dropdown-menu dropdown-user">
                  {searchCriteria.map((item, index) => {
                    if (item.title !== currentSearchCriteria) {
                      return (
                        <li key={index}>
                          <a onClick={() => item.callback(item)}>
                            {" "}
                            {item.title}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </label> */}
               <label
                className="btn btn-sm btn-white"
                onClick={() => history.push("report")}
              >
                {" "}
                <i className="fa fa-pie-chart text-info" />{" "}
                {appLabel.report} &nbsp;
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          {isLoading ? (
            handleSkeletonFac()
          ) : (
            <>
              <ColBlockFac>
                <div>
                  <div className="col-sm-6">
                    {
                      currentSearchCriteria === appLabel.locationName &&(<Select
                      className="basic-single m-b input-sm "
                      classNamePrefix="select"
                      options={locationData}
                      onChange={(e) => onSearchCriteria(e.LO_ID, "location")}
                    />)
                    }
                    {
                      currentSearchCriteria === appLabel.patient &&( <Select
                      className="basic-single m-b input-sm "
                      classNamePrefix="select"
                      options={patientData}
                      onChange={(e) =>
                        onSearchCriteria(e.PATIENT_ID, "patient")
                      }
                    />)
                    }
                    {
                      currentSearchCriteria === appLabel.entryDate &&(<input
                      type="date"
                      className="form-control "
                      onChange={(e) => onSearchCriteria(e.target.value, "date")}
                    />)
                    }

                    
                   

                    
                  </div>
                </div>
              </ColBlockFac>

              <ColBlockFac>
                {episodeData.length === 0 && (
                  <div
                    className="alert alert-warning text-center"
                    style={{ marginTop: "5%" }}
                  >
                    <a className="alert-link">{appLabel.noDataAvailableMsg}</a>
                  </div>
                )}
              </ColBlockFac>
              <ColBlockFac>
                {episodeData.map((item, index) => (
                  <div key={index} className="col-sm-6 col-md-4 col-lg-4 m-t">
                    <div className="contact-box center-version">
                      <small className="pull-right font-bold">
                        {" "}
                        <i className="fa fa-calendar text-info" /> &nbsp;{" "}
                        {item.START_DATE}
                      </small>
                      <Link
                        to={{
                          pathname: "/episode-detail",
                          state: {
                            ggParam: item,
                          },
                        }}
                      >
                        <i className="fa fa-medkit text-navy fa-2x m-b" />
                        <h3 className="m-b-xs">
                          {/* <strong>{item.PATIENT_FULLNAME}</strong> */}
                          <i className="fa fa-hand-o-right text-navy" />
                          &nbsp;
                          <strong>
                            {item.PATIENT_FULLNAME.length < 17
                              ? item.PATIENT_FULLNAME
                              : item.PATIENT_FULLNAME.substring(0, 17) + "..."}
                          </strong>
                        </h3>
                        <div className="font-light">
                          {BOM.Uwfirst(item.PATIENT_GENDER)}
                        </div>
                        <div className="font-bold h6 text-info">
                          <>{BOM.Uwfirst(item.LOCATION)}</>
                        </div>
                      </Link>
                      <div
                        className={`${show ? "contact-box-footer" : "hide"}`}
                      >
                        <div className="m-t-xs btn-group">
                          <Link
                            to={{
                              pathname: "/image-setup",
                              state: {
                                ggParam: item,
                              },
                            }}
                            className="btn btn-xs btn-white"
                          >
                            <i className="fa fa-file-image-o text-warning" />{" "}
                            {appLabel.image}
                          </Link>
                          <Link
                            to={{
                              pathname: "/edit-episode",
                              state: {
                                ggParam: item,
                              },
                            }}
                            className="btn btn-xs btn-white"
                          >
                            <i className="fa fa-edit text-info" />{" "}
                            {appLabel.edit}
                          </Link>
                          <Link
                            to={{
                              pathname: "/hand-over-episode",
                              state: {
                                ggParam: item,
                              },
                            }}
                            className="btn btn-xs btn-white"
                          >
                            <i className="fa fa-share-alt text-info" />{" "}
                            {appLabel.handOver}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}{" "}
              </ColBlockFac>
            </>
          )}
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageEpisode);
