import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import appStatic from "../../../config/appStaticData";
import {
  RecordStatus,
  Col12BlockFacility,
  InputTextFacility,
  TableFacility,
  ColBlockFac,
} from "../../layouts/Facility";

function PatientDetail(props) {
  const { location, userData, staticData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;
  const { tableData } = appStatic;

  const [patientDetail, setPatientDetail] = useState({});
  const [fieldData, setFieldData] = useState([]);
  const [statusBtn, setStatusBtn] = useState("");
  const [fileDetail, setFileDetail] = useState({});
  const [record, setRecord] = useState({});

  useEffect(() => {
    // console.log(state.ggParam);
    getDetailById(ggParam);
  }, []);
  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      patientId: params.PATIENT_ID,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetPatient, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objDetail = res.patientDetail;
        setPatientDetail(objDetail);
        // setPatientDateData(objDetail.PATIENT_DATE);
        setFieldData([
          {
            name: appLabel.id,
            value: objDetail.PATIENT_ID,
            fieldType: "general",
          },
          {
            name: appLabel.firstName,
            value: objDetail.PATIENT_FNAME,
            fieldType: "general",
          },
          {
            name: appLabel.lastName,
            value: objDetail.PATIENT_LNAME,
            fieldType: "general",
          },
          {
            name: appLabel.gender,
            value: objDetail.PATIENT_GENDER,
            fieldType: "general",
          },
          // {
          //   name: appLabel.comment,
          //   value: objDetail.PATIENT_COMMENT,
          //   fieldType: "general",
          // },
          {
            name: appLabel.status,
            value: objDetail.PATIENT_STATUS,
            fieldType: "general",
          },
        ]);
        handleStatusBtn(objDetail.PATIENT_STATUS);
        setRecord(objDetail.RECORD_STATUS);
        
      }
    });
  };
  function getUploadedFile() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      moduleId: ggParam.PATIENT_ID,
      docModule: "patient",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetUploadedFile, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.documents;
        setFileDetail(objDetail);
      }
    });
  }
  const handleStatusBtn = (key) => {
    let tempBtn = "";
    setStatusBtn(tempBtn);

    switch (key.toLowerCase()) {
      case "activated":
        return setStatusBtn(
          <button
            type="button"
            className={`${
              ROLE_SHORT.toLowerCase() === "sys"
                ? "hide"
                : "btn btn-info btn-xs"
            }`}
            onClick={() => updateItem("DEACTIVATED", appLabel.deactivateBtn)}
          >
            <i className="fa fa-thumbs-down" />
            &nbsp; {appLabel.deactivateBtn}
          </button>
        );
      case "deactivated":
        return setStatusBtn(
          <button
            type="button"
            className={`${
              ROLE_SHORT.toLowerCase() === "sys"
                ? "hide"
                : "btn btn-info btn-xs"
            }`}
            onClick={() => updateItem("ACTIVATED", appLabel.activateBtn)}
          >
            <i className="fa fa-thumbs-up" />
            &nbsp; {appLabel.activateBtn}
          </button>
        );
    }

    setStatusBtn(tempBtn);
  };
  const updateItem = (criteria, statusLabel) => {
    let body = {
      profileId: PROFILE_ID,
      patientId: ggParam.PATIENT_ID,
      criteria,
      userToken: token,
    };

    // console.log(body);

    BOM.GlobalApproveRejectAction(
      body,
      api.ActiveInactivePatient,
      "manage-patient",
      ggParam.PATIENT_FNAME,
      statusLabel + " "
    );
  };

  // const deleteItem = () => {
  //   let body = {
  //     profileId: PROFILE_ID,
  //     vaId: patientDetail.VA_ID,
  //     userToken: token,
  //   };

  //   // console.log(body);

  //   BOM.GlobalApproveRejectAction(
  //     body,
  //     api.DeleteVisaApplication,
  //     "manage-visa-application",
  //     patientDetail.VA_ID,
  //     "Supprimer la demande n° "
  //   );
  // };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.patientDetail} />

      <form method="POST" autoComplete="off">
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <div className="ibox-tools">
                    <a
                      onClick={() => BOM.BackButton()}
                      className="btn btn-white btn-xs m-r"
                    >
                       <i className="fa fa-long-arrow-left text-info" />
                      &nbsp;
                      {appLabel.backBtn}
                    </a>
                    {/* {statusBtn} */}
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <Col12BlockFacility topTitle={appLabel.generalInfo}>
                      {fieldData.map((item, index) => (
                        <InputTextFacility
                          key={index}
                          inputLabel={item.name}
                          inputValue={item.value}
                        />
                      ))}
                    </Col12BlockFacility>
                    {/* record status */}
                    <RecordStatus record={record} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetail);
