import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";

const ManageCompany = (props) => {
  const { location, userData } = props;
  const { state } = location;
  const { PROFILE_ID, token } = userData;

  const [companyData, setCompanyData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");

  const [tiles, setTiles] = useState([
    {
      title: "Activé",
      otherTitle: "Liste des pays activés",
      color: "primary",
      icon: "tag",
      criteria: "ACTIVATED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: "Désactivé",
      otherTitle: "Liste des pays désactivés",
      color: "warning",
      icon: "tag",
      criteria: "DEACTIVATED",
      callback: function (params) {
        getListByCriteria(params);
      },
    }

  ]);

  useEffect(() => {
    getListByCriteria(tiles[0]);
  }, []);

  function getListByCriteria(params) {

    setCompanyData([]);
    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    const body = {
      coStatus:params.criteria,
    };

    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCompanyData(res.companyListing);
        BOM.DatatableUsage();
      }
    });
  }

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      {/* <ContentTop pageTitle={appLabel.manageVisaApplication} /> */}
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-4">
          <h2>{appLabel.manageCompany}</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/dashboard">Tableau de bord</Link>
            </li>
            <li className="active">
              <strong>{appLabel.manageCompany}</strong>
            </li>
          </ol>
        </div>
        <div className="col-sm-8">
          <div className="title-action">
            <Link to="/new-company" className="btn btn-primary">
              <i className="fa fa-plus-circle" />
              &nbsp;{appLabel.add1}
            </Link>
          </div>
        </div>
      </div>

      <div className="animated fadeInRight">
        <div className="row">
          <form className="m-t" autoComplete="off">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{titleLeft}</h5>
                  <div className="ibox-tools">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <span className={`badge badge-${color}`}>
                        <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight}{" "}
                        &nbsp;
                        <i className="fa fa-chevron-down"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                      {tiles.map((tile, tileIndex) => {
                        if (tile.title !== titleRight) {
                          return (
                            <li key={tileIndex}>
                              <a onClick={() => tile.callback(tile)}>
                                {" "}
                                {tile.title}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>

                <div className="ibox-content panel-primary contentCard">
                  {companyData.length === 0 ? (
                    <div className="alert alert-warning text-center">
                      <a className="alert-link">{appLabel.noDataInTableMsg}</a>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <div className="table-responsive">
                        <table
                          id="myTable"
                          className="table table-striped table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>{appLabel.coId}</th>
                              <th>{appLabel.coName}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {companyData.map((co, index) => (
                              <tr className="gradeX" key={index}>
                                <td className="center">{index + 1}</td>
                                <td>{co.CO_ID}</td>
                                <td>{co.CO_NAME}</td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/company-detail",
                                      state: {
                                        ggParam: co,
                                      },
                                    }}
                                    className="btn btn-info btn-xs"
                                  >
                                    <i className="fa fa-folder-open" />
                                    &nbsp;{appLabel.viewMore}
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                          <tr>
                              <th></th>
                              <th>{appLabel.coId}</th>
                              <th>{appLabel.coName}</th>
                              <th></th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageCompany);
