import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const RouteGuard = (props) => {
  const { component: Component, userData, ...rest } = props;
  const { token } = userData;

  useEffect(() => {
    // console.log(props);
  }, []);
  function hasJWT() {
    return token ? true : false;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        hasJWT() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/sign-in" }} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RouteGuard);
