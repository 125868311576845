import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import {
  RecordStatus,
  Col12BlockFacility,
  InputTextFacility,
  TableFacility,
  ColBlockFac
} from "../layouts/Facility";

function EpisodeDetail(props) {
  const { location, userData, staticData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;
  const { tableData } = appStatic;

  const [episodeDetail, setEpisodeDetail] = useState({});
  const [fieldData, setFieldData] = useState([]);
  const [patientDateData, setPatientDateData] = useState([]);
  const [record, setRecord] = useState({});
  const [fileDetail, setFileDetail] = useState({});
  const criteriaObj = [
    {
      title: appLabel.new,
      value: 30,
      icon: "users",
      color: "primary",
      criteria: "NEW",
    },
    // {
    //   title: appLabel.progress,
    //   value: 13,
    //   icon: "refresh",
    //   color: "warning",
    //   criteria: "IN PROGRESS",
    // },
    {
      title: appLabel.discharge,
      value: 61,
      icon: "tags",
      color: "navy",
      criteria: "DISCHARGED",
    },
    {
      title: appLabel.reject,
      value: 2,
      icon: "thumbs-down",
      color: "danger",
      criteria: "REJECTED",
    },
    {
      title: appLabel.private,
      value: 211,
      icon: "rub",
      color: "none",
      criteria: "PRIVATE",
    },
    {
      title: appLabel.close,
      value: 230,
      icon: "gavel",
      color: "none",
      criteria: "CLOSED",
    },
  ];
  useEffect(() => {
    // console.log(state.ggParam);
    getDetailById(ggParam);
    getUploadedFile();
  }, []);
  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      episodeId: params.EP_ID,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetEpisode, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objDetail = res.episodeDetail;
        setEpisodeDetail(objDetail);
        setPatientDateData(objDetail.PATIENT_DATE);
        setFieldData([
          {
            name: appLabel.userFullName,
            value: objDetail.PATIENT_FULLNAME,
            fieldType: "general",
          },
          // {
          //   name: appLabel.firstName,
          //   value: objDetail.PATIENT_FNAME,
          //   fieldType: "general",
          // },
          // {
          //   name: appLabel.lastName,
          //   value: objDetail.PATIENT_LNAME,
          //   fieldType: "general",
          // },
          // {
          //   name: appLabel.gender,
          //   value: objDetail.PATIENT_GENDER,
          //   fieldType: "general",
          // },
          {
            name: appLabel.status,
            value: objDetail.EP_STATUS,
            fieldType: "general",
          },
        ]);
        setRecord(objDetail.RECORD_STATUS);
      }
    });
  };
  function getUploadedFile() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      moduleId: ggParam.EP_ID,
      docModule: "patient",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetUploadedFile, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.documents;
        setFileDetail(objDetail);
      }
    });
  }
  const updateItem = (criteria, statusLabel=null) => {
    let body = {
      profileId: PROFILE_ID,
      episodeId: ggParam.EP_ID,
      criteria,
      userToken: token,
    };

    // console.log(body);
    // statusLabel = `Make ${ggParam.PATIENT_FNAME}'s episode ${criteria.toLowerCase()==='closed' ? 'CLAIMED': criteria}`;
    // statusLabel = `Make the episode status ${criteria}`;
    statusLabel = `Change the episode status to ${criteria}`;

    BOM.GlobalApproveRejectAction(
      body,
      api.UpdateEpisodeStatus,
      "dashboard",
      '',
      statusLabel + " "
    );
  };

  const handleEpisodeStatus = (arrParam = []) => {
    const { EP_STATUS } = ggParam;
    // console.log(EP_STATUS);

    switch (EP_STATUS.toLowerCase()) {
      case "new":
        return arrParam.filter(
          (item) => item.criteria.toLowerCase() !== EP_STATUS.toLowerCase()
        );
      case "in progress":
        return arrParam.filter(
          (item) =>
            item.criteria.toLowerCase() !== "new" &&
            item.criteria.toLowerCase() !== EP_STATUS.toLowerCase()
        );
      case "discharged":
        return arrParam.filter(
          (item) =>
            // item.criteria.toLowerCase() !== "new" &&
            item.criteria.toLowerCase() !== "in progress" &&
            item.criteria.toLowerCase() !== "rejected" &&
            item.criteria.toLowerCase() !== EP_STATUS.toLowerCase()
        );
      case "rejected":
        return arrParam.filter(
          (item) =>
            // item.criteria.toLowerCase() !== "new" &&
            item.criteria.toLowerCase() !== "discharged" &&
            item.criteria.toLowerCase() !== "closed" &&
            item.criteria.toLowerCase() !== EP_STATUS.toLowerCase()
        );
      case "closed":
        return arrParam.filter(
          (item) =>
            // item.criteria.toLowerCase() !== "new" &&
            item.criteria.toLowerCase() !== "in progress" &&
            item.criteria.toLowerCase() !== "rejected" &&
            item.criteria.toLowerCase() !== "discharged" &&
            item.criteria.toLowerCase() !== EP_STATUS.toLowerCase()
        );
      default:
        return arrParam;
    }
  };

  // const deleteItem = () => {
  //   let body = {
  //     profileId: PROFILE_ID,
  //     vaId: episodeDetail.VA_ID,
  //     userToken: token,
  //   };

  //   // console.log(body);

  //   BOM.GlobalApproveRejectAction(
  //     body,
  //     api.DeleteVisaApplication,
  //     "manage-visa-application",
  //     episodeDetail.VA_ID,
  //     "Supprimer la demande n° "
  //   );
  // };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.episodeDetail} />

      <form method="POST" autoComplete="off">
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="input-group-btn">
                    <button
                      data-toggle="dropdown"
                      className={`${
                        ROLE_SHORT.toLowerCase() === "sys"
                          ? "hide"
                          : "btn btn-info btn-xs dropdown-toggle"
                      }`}
                      type="button"
                    >
                      {appLabel.updateStatus} <span className="caret" />
                    </button>
                    <ul className="dropdown-menu">
                      {handleEpisodeStatus(criteriaObj).map((item, index) => (
                        <li key={index}>
                          <a
                            onClick={() =>
                              updateItem(item.criteria, item.title)
                            }
                          >
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="ibox-tools">
                    <a
                      onClick={() => BOM.BackButton()}
                      className="btn btn-white btn-xs m-r"
                    >
                       <i className="fa fa-long-arrow-left text-info" />
                      &nbsp;
                      {appLabel.backBtn}
                    </a>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <ColBlockFac>
                      {fileDetail.DOCUMENT ? (
                        <div className="col-sm-8 co-xs-8">
                          <div className="row" style={{ marginTop: 15 }}>
                            <div className="col-md-3 col-sm-3 co-xs-12">
                              <div className="box">
                                <a href={fileDetail.DOCUMENT} target={"_blank"}>
                                  <img
                                    style={{ width: 201, height: 200 }}
                                    src={fileDetail.DOCUMENT}
                                    className="img-ht img-fluid rounded"
                                  />
                                </a>
                              </div>
                              <label
                                className="control-label"
                                style={{ marginTop: 5 }}
                              >
                                {/* &nbsp; {fileDetail.DOC_DESCRIPTION} */}
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-sm-8 co-xs-8">
                          <div className="alert alert-warning text-center">
                            <a className="alert-link">{appLabel.noImage}</a>
                          </div>
                        </div>
                      )}
                    </ColBlockFac>
                    <Col12BlockFacility topTitle={appLabel.generalInfo}>
                      {fieldData.map((item, index) => (
                        <InputTextFacility
                          key={index}
                          inputLabel={item.name}
                          inputValue={item.value}
                        />
                      ))}
                    </Col12BlockFacility>
                    <div className="col-md-12">
                      <div className="form-group col-md-6">
                        <label className="al-subtitle">
                          {appLabel.comment}&nbsp;
                        </label>
                        <textarea
                          rows={1}
                          cols={5}
                          className="form-control input-sm list-text-area"
                          disabled
                          defaultValue={episodeDetail.EP_COMMENT}
                        ></textarea>
                      </div>
                    </div>
                    <Col12BlockFacility topTitle={appLabel.consultationInfo}>
                      <div className="feed-activity-list" style={{marginBottom:'20px'}}>
                        {patientDateData.length === 0 && (
                          <div className="alert alert-warning text-center">
                            <a className="alert-link">
                              {appLabel.noDataAvailableMsg}
                            </a>
                          </div>
                        )}
                        {patientDateData.map((item, index) => (
                          <div key={index} className="feed-element">
                            <span className="pull-left text-navy">
                              <i className="fa fa-medkit fa-2x" />
                            </span>
                            <div className="media-body ">
                              <small className="pull-right">
                                {item.LO_NAME}
                              </small>
                              <strong>{item.GP_CODE_NAME}</strong> <br />
                              <small className="text-muted">
                                {item.PD_DATE}
                              </small>
                              <div className="well">
                                {item.GP_CODE_DESCRIPTION}
                              </div>
                              {/* <div className="actions">
                                <a
                                  className="btn btn-xs btn-white"
                                  onClick={() => deleteItem(item)}
                                >
                                  <i className="fa fa-times text-info" />{" "}
                                  {appLabel.deleteBtn}
                                </a>
                              </div> */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col12BlockFacility>
                    {/* <Col12BlockFacility topTitle={appLabel.recordStatus}>
                      <div className="feed-element">
                        <div className="media-body ">
                          <div className="well">
                            {appLabel.makerId} : <strong>{record.MAKER_ID}</strong> <br />
                            {appLabel.makerDate} : <strong>{record.MAKER_DATE}</strong> <br />
                            {appLabel.checkerId} : <strong>{record.CHECKER_ID}</strong> <br />
                            {appLabel.checkerDate} : <strong>{record.CHECKER_DATE}</strong>
                          </div>
                        </div>
                      </div>
                    </Col12BlockFacility> */}
                    <RecordStatus record={record} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeDetail);
