import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../dashboard/Dashboard.css";
import Main from "../../components/layouts/Main";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { BOM } from "../../services/defined.action";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";
import Logo from "../../assets/img/logoLogin/ncare.png";
import history from "../../history";
const Landing = (props) => {
  const { isSignedIn, userData } = props;
  const { PROFILE_ID, token, CO_ID } = userData;
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    if (!isSignedIn) {
      history.push("/sign-in");
      return;
    }
    $(document).ready(function () {
      $("body").scrollspy({
        target: ".navbar-fixed-top",
        offset: 80,
      });

      // Page scrolling feature
      $("a.page-scroll").bind("click", function (event) {
        var link = $(this);
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $(link.attr("href")).offset().top - 50,
            },
            500
          );
        event.preventDefault();
        $("#navbar").collapse("hide");
      });
    });

    var cbpAnimatedHeader = (function () {
      var docElem = document.documentElement,
        header = document.querySelector(".navbar-default"),
        didScroll = false,
        changeHeaderOn = 200;
      function init() {
        window.addEventListener(
          "scroll",
          function (event) {
            if (!didScroll) {
              didScroll = true;
              setTimeout(scrollPage, 250);
            }
          },
          false
        );
      }
      function scrollPage() {
        var sy = scrollY();
        if (sy >= changeHeaderOn) {
          $(header).addClass("navbar-scroll");
        } else {
          $(header).removeClass("navbar-scroll");
        }
        didScroll = false;
      }
      function scrollY() {
        return window.pageYOffset || docElem.scrollTop;
      }
      init();
    })();

    getCompany();
  }, []);
  function getCompany() {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId: PROFILE_ID,
      userToken: token,
    };

    // console.log(body);

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.companyListing;

        setCompanyData(objList);
      }
    });
  }

  const onSelectCompany = (objParam) => {
    // console.log(objParam);
    dispatch({
      type: cnt.CURRENT_CO,
      payload: objParam,
    });
    history.push("/dashboard");
  };
  return (
    <div id="page-top" className="landing-page">
      <div className="navbar-wrapper">
        <nav
          className="navbar navbar-default navbar-fixed-top"
          role="navigation"
        >
          <div className="container">
            <div className="navbar-header page-scroll">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand">
                <img
                  alt="image"
                  style={{
                    width: "100px",
                  }}
                  src={Logo}
                />
              </a>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a className="page-scroll" href="#page-top">
                    Home
                  </a>
                </li>
                <li>
                  <Link to="/sign-in">
                    <i className="fa fa-sign-out text-navy" />
                    &nbsp;{appLabel.logoutBtn}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <section className="timeline gray-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="navy-line" />
              <h1>Manage Company</h1>
              <p> All the companies nCare have </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <div className="row">
            {companyData.map((item, index) => (
              <div key={index} className="col-sm-4 col-lg-4">
                <div className="ibox">
                  {/* <Link
                    to={{
                      pathname: "/manage-patient",
                      state: {
                        ggParam: item,
                      },
                    }}
                  > */}
                  <div
                    className="ibox-content dashCard"
                    onClick={() => onSelectCompany(item)}
                  >
                    <h5 className="m-b-md">
                      <i className="fa fa-user-md text-white" /> &nbsp;{" "}
                      {item.CO_TYPE_NAME}
                    </h5>
                    <h3>
                      <i className={`fa fa-hospital-o text-navy`} />
                      &nbsp; {item.CO_NAME}
                    </h3>
                    <h6>
                      <small>{item.CO_ADDRESS.substring(0, 66)}</small>
                    </h6>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
