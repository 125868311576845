import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import RightSide from "../../ui-setup/RightSide";

function NewUser(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { location, userData } = props;
  const { state } = location;
  const { PROFILE_ID, token, ROLE_SHORT } = userData;

  const [roleData, setRoleData] = useState([]);

  useEffect(() => {
    getRole();
  }, []);

  const getRole = () => {
    const body = {
      profileId: PROFILE_ID,
      roleShort: ROLE_SHORT,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetRole, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setRoleData(res.roleListing);
      }
    });
  };

  const formSubmit = (formValues) => {
    let body = {
      profileId: PROFILE_ID,
      ...formValues,
      userToken: token,
    };

    // console.log(body);
    // return;
    BOM.LoadAlert(cnt.LOAD, "Processing");
    /** SEND REQ */
    BOM.SendReqAction(body, api.RegisterLink, "/manage-user");
  };

  const validator = (data) => {

    console.log(data);
  };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.newUser} />
      <form
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
        onBlur={handleSubmit(validator)}
      >
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-user"
                      className="btn btn-white btn-xs m-r"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                    {/* <button
                      type="submit"
                      className={`${
                        ROLE_SHORT.toLowerCase() === "sys"
                          ? "hide"
                          : "btn btn-info btn-xs"
                      }`}
                    >
                      <i className="fa fa-save" />
                      &nbsp;{appLabel.save}
                    </button> */}
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle text-navy" />
                        &nbsp; {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />

                      <div className="form-group col-md-4">
                        <label className="al-subtitle">
                          {appLabel.email}&nbsp;
                          <span className="asterisks">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm list-text-area"
                          placeholder={appLabel.email}
                          {...register("email", {
                            required: appLabel.fieldReq,
                            pattern: {
                              value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                              message: "Adresse e-mail non valide",
                            },
                          })}
                        />
                        <span className="asterisks">
                          {errors?.email?.message}
                        </span>
                      </div>

                      <div className="form-group col-md-4">
                        <label className="al-subtitle">
                          {appLabel.roleName}&nbsp;
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control input-lg list-text-area"
                          {...register("roleId", {
                            required: appLabel.fieldReq,
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {roleData.map((item, index) => (
                            <option value={item.ROLE_ID} key={index}>
                              {item.ROLE_NAME}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors?.roleId?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        <hr />
                        <div className="form-group">
                          <div className="col-sm-6">
                            <button
                              type="submit"
                              className={`${
                                ROLE_SHORT.toLowerCase() === "sys"
                                  ? "hide"
                                  : "btn btn-info btn-xs"
                              }`}
                            >
                              <i className="fa fa-save" />
                              &nbsp;{appLabel.save}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
