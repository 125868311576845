/**
 * @author Gedeon NdundeCode
 * @date 06/12/2021
 */
import Auth, { headerOptions as options, apis as api } from "../../api.action";
import { cnt } from "../../constant.action";
import history from "../../../history";
import { BOM } from "../../defined.action";
import { store } from "../store";

/** AUTH LOGIC */
export const SignInAction = (body) => {
  // console.log(body);
  BOM.LoadAlert(cnt.LOAD, "Processing");

  return async (dispatch) => {
    BOM.SessionLessReqAction(body, api.Login, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        dispatch({
          type: cnt.SIGN_IN,
          payload: res.userData,
        });

        /** REDIRECT USER */
        switch (res.userData.ROLE_SHORT.toLowerCase()) {
          case "sys":
            history.push("/landing");
            break;
          default:
            switch (res.userData.USER_DEFAULT_PWD.toLowerCase()) {
              case "default":
                history.push("/change-password");
                break;
              case "edited":
                history.push("/dashboard");
                break;
            }
            break;
        }
      }
    });
  };
};

export const VerifyAuthAction = (body) => {
  BOM.LoadAlert(cnt.LOAD, "Processing");

  return async (dispatch) => {
    BOM.SessionLessReqAction(body, api.Authentication, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        getRoleMenu(res.userData); //GET DYNAMIC MENU FOR CURRENT USER

        dispatch({
          type: cnt.SIGN_IN,
          payload: res,
        });
        history.push("/order-to-verify");
      }
    });
  };
};

function getRoleMenu(user) {
  // BOM.LoadAlert(cnt.LOAD, "Processing");

  let body = {
    profileId: user.profileId,
    roleId: user.userRoleId,
    session: user.session,
  };

  BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
    if (err) {
      BOM.AlertMsg(cnt.DANGER, err);
    } else {
      store.dispatch({
        type: cnt.MENU_DATA,
        payload: res.roleData.FUNCTIONS,
      });
      // $("#side-menu").metisMenu();
    }
  });
}

// export const ChangePasswordAction = (userData) => {
//   LoadAlert(cnt.LOAD, "Processing");
//   try {
//     return async (dispatch) => {
//       const res = await Auth.post(api.ChangePassword, userData, options);

//       const response = res.data;
//       switch (response.status.toLowerCase()) {
//         case "ok":
//           AlertMsg(cnt.SUCCESS, response.message);
//           history.push("/company");
//           break;

//         default:
//           AlertMsg(cnt.DANGER, response.message);
//           break;
//       }
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     AlertMsg(cnt.DANGER, "Something went wrong!");
//   }
// };

// export const ResetPasswordAction = (userData) => {
//   LoadAlert(cnt.LOAD, "Processing");
//   try {
//     return async (dispatch) => {
//       const res = await Auth.post(api.ResetPassword, userData, options);

//       const response = res.data;
//       switch (response.status.toLowerCase()) {
//         case "ok":
//           AlertMsg(cnt.SUCCESS, response.message);
//           history.push("/sign-in");
//           break;

//         default:
//           AlertMsg(cnt.DANGER, response.message);
//           break;
//       }
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     AlertMsg(cnt.DANGER, "Something went wrong!");
//   }
// };

// export const ForgotPasswordAction = (userData) => {
//   LoadAlert(cnt.LOAD, "Processing");
//   try {
//     return async (dispatch) => {
//       const res = await Auth.post(api.ForgotPassword, userData, options);

//       const response = res.data;
//       switch (response.status.toLowerCase()) {
//         case "ok":
//           AlertMsg(cnt.SUCCESS, response.message);
//           history.push("/sign-in");
//           break;

//         default:
//           AlertMsg(cnt.DANGER, response.message);
//           break;
//       }
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     AlertMsg(cnt.DANGER, "Something went wrong!");
//   }
// };

export const SignOutAction = () => {
  return () => {
    // storage.removeItem('persistor:root')

    store.dispatch({ type: cnt.CLEAR_RIGHT_BTN });
    store.dispatch({ type: cnt.CLEAR_STATIC_DATA });
    store.dispatch({ type: cnt.SIGN_OUT });

    // history.push("/sign-in");
  };
};
