import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import appStatic from "../../../config/appStaticData";
import { TableFacility, ColBlockFac } from "../../layouts/Facility";
import Notiflix from "notiflix";

const ManagePatient = (props) => {
  const { location, userData, staticData } = props;
  const { state } = location;
  // const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const { tableData } = appStatic;
  const [patientData, setPatientData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [recordCount, setRecordCount] = useState(0);


  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: "",
      color: "primary",
      icon: "thumbs-up",
      criteria: "ACTIVATED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: "",
      color: "warning",
      icon: "thumbs-down",
      criteria: "DEACTIVATED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    // console.log(ggParam);
    getListByCriteria(state ? state.ggParam : tiles[0]);
  }, []);

  function getListByCriteria(params) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);
    setRecordCount(0)

    setPatientData([]);
    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: params.criteria,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetPatient, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setPatientData(res.patientListing);
        setIsLoading(false);
        setRecordCount(res.patientListing.length)
      }
    });
  }

  const updateItem = (objParam) => {
    // console.log(objParam);

    const { PATIENT_STATUS, PATIENT_ID, PATIENT_FNAME } = objParam;
    const body = {
      profileId: PROFILE_ID,
      patientId: PATIENT_ID,
      userToken: token,
    };
    let statusLabel = "";
    switch (PATIENT_STATUS.toLowerCase()) {
      case "activated":
        body.criteria = "DEACTIVATED";
        statusLabel = appLabel.deactivateBtn + " " + PATIENT_FNAME;
        break;
      case "deactivated":
        body.criteria = "ACTIVATED";
        statusLabel = appLabel.activateBtn + " " + PATIENT_FNAME;
        break;
    }

    // BOM.SendReqWithConfirm(body, api.ActiveInactiveCode, statusLabel);
    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.ActiveInactivePatient, (err, res) => {
          if (err) {
            // BOM.AlertMsg(cnt.DANGER, err);
            BOM.NotifyMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);

            /** REDIRECTION TABLE */
            switch (PATIENT_STATUS.toLowerCase()) {
              case "activated":
                getListByCriteria(tiles[0]);
                break;
              case "deactivated":
                getListByCriteria(tiles[1]);
                break;
              default:
                getListByCriteria(tiles[0]);
                break;
            }
          }
        });
      },
      function () {
        // No button
      }
    );
  };
  const deleteItem = (objParam) => {
    const { PATIENT_ID, PATIENT_FNAME, PATIENT_STATUS } = objParam;

    let body = {
      profileId: PROFILE_ID,
      itemId: PATIENT_ID,
      itemType: "patient",
      userToken: token,
    };

    // console.log(body);

    const statusLabel = appLabel.deleteBtn + " " + PATIENT_FNAME;
    // BOM.SendReqWithConfirm(body, api.DeleteItem, statusLabel);
    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.DeleteItem, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
            // BOM.NotifyMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);
            setPatientData(
              patientData.filter((item) => item.PATIENT_ID !== PATIENT_ID)
            );
          }
        });
      },
      function () {
        // No button
      }
    );
  };
  const handleStatusBtn = (key) => {
    switch (key.toLowerCase()) {
      case "activated":
        return (
          <>
            <i className="fa fa-thumbs-down text-info" />{" "}
            {appLabel.deactivateBtn}
          </>
        );
      case "deactivated":
        return (
          <>
            <i className="fa fa-thumbs-up text-info" /> {appLabel.activateBtn}
          </>
        );
    }
  };
  const handleSkeletonFac = () =>
    Array(4)
      .fill()
      .map((el, elIndex) => (
        <div key={elIndex} className="col-sm-6 col-md-4 col-lg-4">
          <div className="contact-box center-version">
            <a>
              <Skeleton circle={true} height={35} width={35} />
              <h3 className="m-b-xs">
                <Skeleton />
              </h3>
              <div className="font-bold">
                <Skeleton />
              </div>
            </a>
            <div className="contact-box-footer">
              <div className="m-t-xs btn-group">
                <a className="btn btn-xs btn-white">
                  <Skeleton height={18} width={40} />
                </a>
                <a className="btn btn-xs btn-white">
                  <Skeleton height={18} width={40} />
                </a>
                <a className="btn btn-xs btn-white">
                  <Skeleton height={18} width={40} />
                </a>
              </div>
            </div>
          </div>
        </div>
      ));
  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.managePatient} />
      <div className="animated fadeInRight">
        <div className="row m-b">
          <div className="col-xs-12 col-xs-offset-2 col-sm-6 col-sm-offset-4 col-md-6 col-md-offset-4">
            <div data-toggle="buttons" className="btn-group">
              <label
                className={`${
                  ROLE_SHORT.toLowerCase() === "sys"
                    ? "hide"
                    : "btn btn-sm btn-white"
                }`}
              >
                <Link to="/new-patient" style={{ color: "inherit" }}>
                  <i className="fa fa-plus-circle text-info" /> &nbsp;{" "}
                  {appLabel.add1}{" "}
                </Link>
              </label>
              <label className="btn btn-sm btn-white">
                <a
                  className="dropdown-toggle"
                  style={{ color: "inherit" }}
                  data-toggle="dropdown"
                >
                  <i className={`fa fa-${icon} text-info`}></i> &nbsp;{" "}
                  {/* {titleRight} &nbsp; */}
                  {`${titleRight} ( ${recordCount} )`} &nbsp;
                  <i className="fa fa-chevron-down"></i>
                </a>
                <ul className="dropdown-menu dropdown-user">
                  {tiles.map((tile, tileIndex) => {
                    if (tile.title !== titleRight) {
                      return (
                        <li key={tileIndex}>
                          <a onClick={() => tile.callback(tile)}>
                            {" "}
                            {tile.title}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          {isLoading ? (
            handleSkeletonFac()
          ) : (
            <>
              <ColBlockFac>
                {patientData.length === 0 && (
                  <div
                    className="alert alert-warning text-center"
                    style={{ marginTop: "15%" }}
                  >
                    <a className="alert-link">{appLabel.noDataAvailableMsg}</a>
                  </div>
                )}
              </ColBlockFac>
              <ColBlockFac>
                {patientData.map((item, index) => (
                  <div key={index} className="col-sm-6 col-md-4 col-lg-4">
                    <div className="contact-box center-version">
                      <Link
                        to={{
                          pathname: "/patient-detail",
                          state: {
                            ggParam: item,
                          },
                        }}
                      >
                        <i className="fa fa-user text-navy fa-2x m-b" />
                        <h3 className="m-b-xs">
                          <i className="fa fa-hand-o-right text-navy" />
                          &nbsp;
                          <strong>
                            {item.PATIENT_FULLNAME.length < 17
                              ? item.PATIENT_FULLNAME
                              : item.PATIENT_FULLNAME.substring(0, 17) + "..."}
                          </strong>
                        </h3>
                        <div className="font-bold">
                          {BOM.Uwfirst(item.PATIENT_GENDER)}
                        </div>
                      </Link>
                      <div className="contact-box-footer">
                        <div className="m-t-xs btn-group">
                          {/* <Link
                      to={{
                        pathname: "/image-setup",
                        state: {
                          ggParam: item,
                        },
                      }}
                      className="btn btn-xs btn-white"
                    >
                      <i className="fa fa-file-image-o text-warning" />{" "}
                      {appLabel.image}
                    </Link> */}
                          <Link
                            to={{
                              pathname: "/edit-patient",
                              state: {
                                ggParam: item,
                              },
                            }}
                            className="btn btn-xs btn-white m-r"
                          >
                            <i className="fa fa-edit text-info" />{" "}
                            {appLabel.edit}
                          </Link>
                          <a
                            className="btn btn-xs btn-white m-r"
                            onClick={() => updateItem(item)}
                          >
                            {handleStatusBtn(item.PATIENT_STATUS)}
                          </a>
                          <a
                            className="btn btn-xs btn-white"
                            onClick={() => deleteItem(item)}
                          >
                            <i className="fa fa-times text-info" />{" "}
                            {appLabel.deleteBtn}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </ColBlockFac>
            </>
          )}
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManagePatient);
