import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import appLabel from "../../config/appLabel";
import { Button, ButtonToolbar, Modal } from "react-bootstrap";

export const AmountFacility = (props) => {
  const { setIsRequiredField, setAmount, isRequiredField, callbackFunc } =
    props;

  return (
    <>
      <NumberFormat
        thousandSeparator={true}
        className="form-control input-sm list-text-area"
        placeholder={100}
        onValueChange={(values, sourceInfo) => {
          // setAmount(values.floatValue);
          if (values.floatValue >= 0) {
            setIsRequiredField(false);
            setAmount(values.floatValue);
          } else {
            setIsRequiredField(true);
          }
        }}
        onChange={callbackFunc}
      />
      <span className="asterisks">
        {isRequiredField ? "Field is required." : ""}
      </span>
    </>
  );
};
export const TooltipsFAke = () => {
  //this will remove later it linked with orderDetail
  return <></>;
};
export const Tooltips = (props) => {
  const { title } = props;
  // return (
  //   <>
  //     <i
  //       className="fa fa-info-circle cursor-pointer text-info"
  //       data-tip={title}
  //       data-type="info"
  //     />
  //     &nbsp;
  //     <ReactTooltip />
  //   </>
  // );
  return (
    <>
      <i
        className="fa fa-info-circle cursor-pointer text-default"
        title={title}
      />
    </>
  );
};

export const ButtonRightFacility = (props) => {
  const { btnFuction, btnClass, btnIcon, title } = props;

  return (
    <>
      <div className="row text-center" style={{ marginBottom: "20px" }}>
        <a
          className={`btn ${btnClass} btn-circle btn-lg btn-outline`}
          title={title}
          onClick={() => btnFuction()}
        >
          <i className={`fa ${btnIcon}`}></i>
        </a>
      </div>
    </>
  );
};

export const InputTextFacility = (props) => {
  const { inputLabel, inputValue, toolTipLabel } = props;
  return (
    <div className="form-group col-sm-6 col-md-4">
      <label className="al-subtitle">
        {inputLabel}&nbsp;
        {toolTipLabel && <Tooltips title={toolTipLabel} />}
      </label>
      <input
        type="text"
        className="form-control input-sm list-text-area"
        defaultValue={inputValue}
        disabled
      />
    </div>
  );
};

export const InputAmountFacility = (props) => {
  const { inputLabel, inputValue, toolTipLabel, currency } = props;

  return (
    <div className="row form-group col-md-6">
      <div className="col-md-4 text-left">
        <label className="al-subtitle">
          {inputLabel}&nbsp;
          {toolTipLabel && <Tooltips title={toolTipLabel} />}
        </label>
      </div>
      <div className="col-md-7">
        <NumberFormat
          className="form-control input-sm list-text-area"
          value={inputValue}
          displayType="text"
          disabled
          thousandSeparator={true}
          prefix={currency ? currency + " " : ""}
        />
      </div>
    </div>
  );
};
export const AmntFormatFac = (props) => {
  const { inputValue, currency } = props;

  return (
    <NumberFormat
      value={inputValue}
      displayType="text"
      thousandSeparator={true}
      prefix={currency ? currency + " " : ""}
    />
  );
};

export const Col12BlockFacility = (props) => {
  const { children, topTitle } = props;
  return (
    <div className="col-sm-12">
      {topTitle && (
        <>
          <label className="al-heading">
            <i className="fa fa-circle-o text-navy"></i>&nbsp; {topTitle}
          </label>
          <div className="hr-line-dashed" style={{ marginTop: 0 }} />
        </>
      )}
      {children}
    </div>
  );
};
export const ColBlockFac = (props) => {
  const { children, topTitle, colNo } = props;
  return (
    <div className={`col-sm-${colNo ? colNo : "12"}`}>
      {topTitle && (
        <>
          <label className="al-heading">
            <i className="fa fa-circle-o text-navy"></i>&nbsp; {topTitle}
          </label>
          <div className="hr-line-dashed" style={{ marginTop: 0 }} />
        </>
      )}
      {children}
    </div>
  );
};

export const TableHeader = (props) => {
  const { thData } = props;
  return (
    <>
      <thead>
        <tr>
          {thData.map((item, index) => (
            <th key={index}>{item.name}</th>
          ))}
          <th></th>
        </tr>
      </thead>
    </>
  );
};
export const TableBody = (props) => {
  const { tbodyData, tdData, children } = props;
  console.log(props);
  return (
    <>
      <tbody>
        {tbodyData.map((item, index) => (
          <tr className="gradeX" key={index}>
            <td>
              <label className="myradio-button">
                <input
                  type="radio"
                  name="radio"
                  // onClick={() => onSelectCharge(charge)}
                />
                <span className="label-visible">
                  <span className="myfake-radiobutton"></span>
                </span>
              </label>
            </td>
            {children}
          </tr>
        ))}
      </tbody>
    </>
  );
};
export const TableFooter = (props) => {
  const { thData } = props;
  return (
    <>
      <thead>
        <tr>
          {thData.map((item, index) => (
            <th key={index}>{item.name}</th>
          ))}
          <th></th>
        </tr>
      </thead>
    </>
  );
};
export const TableFacility = (props) => {
  const { children } = props;
  // console.log(children);
  return (
    <div className="table-responsive">
      <table
        id="myTable"
        className="table table-striped table-bordered table-hover"
      >
        <TableHeader {...props} />
        <tbody>{children}</tbody>
        <TableFooter {...props} />
      </table>
    </div>
  );
};
export const TableFacility2 = (props) => {
  const { children } = props;
  // console.log(children);
  return (
    <div className="table-responsive">
      <table className="myTable table table-striped table-bordered table-hover">
        <TableHeader {...props} />
        <tbody>{children}</tbody>
        <TableFooter {...props} />
      </table>
    </div>
  );
};
export const TableSimpleFac = (props) => {
  const { thData, children } = props;
  return (
    <table
      className="footable table table-stripped toggle-arrow-tiny"
      data-page-size="10"
      data-filter="#filter"
    >
      <thead>
        <tr>
          <th></th>
          {thData.map((item, index) => (
            <th key={index}>{item.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
      <tfoot>
        <tr>
          <td colSpan={thData.length + 1}>
            <ul className="pagination pull-right"></ul>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
export const IboxTitleFacility = (props) => {
  const { titleLeft, color, icon, titleRight, tiles } = props;
  return (
    <div className="ibox-title">
      <h5>{titleLeft}</h5>
      <div className="ibox-tools">
        <a className="dropdown-toggle" data-toggle="dropdown">
          <span className={`badge badge-${color}`}>
            <i className={`fa fa-${icon}`}></i> &nbsp; {titleRight} &nbsp;
            <i className="fa fa-chevron-down"></i>
          </span>
        </a>
        <ul className="dropdown-menu dropdown-user">
          {tiles.map((tile, tileIndex) => {
            if (tile.title !== titleRight) {
              return (
                <li key={tileIndex}>
                  <a onClick={() => tile.callback(tile)}> {tile.title}</a>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};
export const NoDataFac = (props) => {
  const { arrList, title } = props;
  return (
    <>
      {arrList.length === 0 && (
        <div className="alert alert-warning text-center">
          <a className="alert-link">
            {appLabel.noDataMsg} {title}
          </a>
        </div>
      )}
    </>
  );
};

export const NavTabFac = (props) => {
  const { tabsList } = props;
  return (
    <ul className="nav nav-tabs">
      {tabsList.map((item, index) => (
        <li key={index} className={` ${item.isActive ? "active" : ""}`}>
          <a data-toggle="tab" href={item.ref}>
            <i className={item.icon} />
            &nbsp;{item.listName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export const RecordStatus = (props) => {
  const { record } = props;

  useEffect(() => {
    // console.log(props);
  }, []);
  return (
    <Col12BlockFacility topTitle={appLabel.recordStatus}>
      <div className="feed-element">
        <div className="media-body ">
          <div className="well">
            {appLabel.makerId} : <strong>{record.MAKER_ID}</strong> <br />
            {appLabel.makerDate} :{" "}
            <span>
              {record.MAKER_DATE} &nbsp;{record.MAKER_TIME ? "at" : ""}&nbsp;
              {record.MAKER_TIME}
            </span>{" "}
            <br />
            {appLabel.checkerId} :{" "}
            <strong>
              {record.CHECKER_ID ? record.CHECKER_ID : "Never updated"}
            </strong>{" "}
            <br />
            {appLabel.checkerDate} :{" "}
            <span>
              {record.CHECKER_DATE ? record.CHECKER_DATE : "Never updated"}{" "}
              &nbsp;{record.CHECKER_TIME ? "at" : ""}&nbsp;{record.CHECKER_TIME}
            </span>
          </div>
        </div>
      </div>
    </Col12BlockFacility>
  );
};

export const AppModalFac = (props) => {
  const { handleSave, handleClose, show, children, title, saveTitle } = props;
  const showHideClassName = show ? "" : "hide";

  useEffect(() => {}, []);

  return (
    <div>
      <Modal.Dialog className={`${showHideClassName}`}>
        <Modal.Header>
          <br />
          <Modal.Title>{title}</Modal.Title>
          <hr />
          <Modal.Body>{children}</Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <div className="text-right">
            <Button
              className="btn btn-danger btn-xs"
              onClick={() => handleClose()}
            >
              {appLabel.close}
            </Button>
            &nbsp;
            <Button
              className="btn btn-primary btn-xs"
              onClick={() => handleSave()}
            >
              {" "}
              <i className="fa fa-plus-circle"></i>&nbsp; {appLabel.add1}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

export const handleStatusBtnFac = (props) => {
  // const [statusBtn, setStatusBtn] = useState("");
  // const { key, roleParam } = props;
  // useEffect(() => {
  //   let tempBtn = "";
  //   setStatusBtn(tempBtn);

  //   switch (key.toLowerCase()) {
  //     case "activated":
  //       return setStatusBtn(
  //         <button
  //           type="button"
  //           className={`${
  //             roleParam.toLowerCase() === "sys"
  //               ? "hide"
  //               : "btn btn-primary btn-xs"
  //           }`}
  //           onClick={() => updateItem("DEACTIVATED", appLabel.deactivateBtn)}
  //         >
  //           <i className="fa fa-thumbs-down" />
  //           &nbsp; {appLabel.deactivateBtn}
  //         </button>
  //       );
  //     case "deactivated":
  //       return setStatusBtn(
  //         <button
  //           type="button"
  //           className={`${
  //             roleParam.toLowerCase() === "sys"
  //               ? "hide"
  //               : "btn btn-primary btn-xs"
  //           }`}
  //           onClick={() => updateItem("ACTIVATED", appLabel.activateBtn)}
  //         >
  //           <i className="fa fa-thumbs-up" />
  //           &nbsp; {appLabel.activateBtn}
  //         </button>
  //       );
  //   }

  //   setStatusBtn(tempBtn);
  // }, []);

  return <div>{"statusBtn"}</div>;
};

export const handleSkeletonFac = () =>
  Array(3)
    .fill()
    .map((el, elIndex) => (
      <div key={elIndex} className="col-sm-6 col-md-4 col-lg-4">
        <div className="contact-box center-version">
          <a>
            <Skeleton circle={true} height={35} width={35} />
            <h3 className="m-b-xs">
              <Skeleton />
            </h3>
            <div className="font-bold">
              <Skeleton />
            </div>
          </a>
          <div className="contact-box-footer">
            <div className="m-t-xs btn-group">
              <a className="btn btn-xs btn-white">
                <Skeleton height={18} width={40} />
              </a>
              <a className="btn btn-xs btn-white">
                <Skeleton height={18} width={40} />
              </a>
              <a className="btn btn-xs btn-white">
                <Skeleton height={18} width={40} />
              </a>
            </div>
          </div>
        </div>
      </div>
    ));

export const SubmitBtnLoad = (props) => {
  const { isLoading, btnName, isFullWidth, icon } = props;

  return (
    <button
      type="submit"
      className={`btn-sm btn btn-${isLoading ? "white" : "primary"} ${
        isFullWidth ? "block full-width" : ""
      } `}
      style={{
        marginTop: isFullWidth && 30,
        boxShadow: isFullWidth && "0 10px 6px -6px #fff",
      }}
    >
      {isLoading && (
        <span style={{ color: "#000", fontWeight: "600" }}>
          <i className="fa fa-spinner fa-spin" /> &nbsp; {appLabel.processing}
        </span>
      )}

      {!isLoading && (
        <span>
          <i className={icon} /> &nbsp; {btnName}
        </span>
      )}
    </button>
  );
};
