import React, { useState, useEffect } from "react";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import appStatic from "../../config/appStaticData";
import RightSide from "../ui-setup/RightSide";
import appLabel from "../../config/appLabel";

const ChangePassword = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { auth, userData, history } = props;
  const { PROFILE_ID, token, ROLE_SHORT } = userData;
  const [currentPwdShown, setCurrentPwdShown] = useState(false);
  const [newPwdShown, setNewPwdShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);

  useEffect(() => {}, []);

  // Password toggle handler
  const togglePassword = (key) => {
    switch (key.toLowerCase()) {
      case "current":
        return setCurrentPwdShown(!currentPwdShown);
      case "new":
        return setNewPwdShown(!newPwdShown);
      case "confirm":
        return setConfirmPwdShown(!confirmPwdShown);
    }
  };

  const formSubmit = (formValues) => {
    if (formValues.newPassword !== formValues.confirmPassword) {
      BOM.AlertMsg(cnt.WARNING, appLabel.passwordDonMatch);
      return;
    }

    let body = {
      profileId: PROFILE_ID,
      ...formValues,
      userToken: token,
    };

    // console.log(body);

    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.ChangePassword, "/dashboard");
  };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.changePwd} />

      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="ibox-tools">
                    <Link to="/dashboard" className="btn btn-white btn-xs m-r">
                      <i className="fa fa-times" />
                      &nbsp;
                      {appLabel.cancelBtn}
                    </Link>
                    <button type="submit" className="btn btn-primary btn-xs">
                      <i className="fa fa-save" />
                      &nbsp;{appLabel.save}
                    </button>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="al-subtitle">
                          {appLabel.currentPwd}
                        </label>
                        <input
                          type={currentPwdShown ? "text" : "password"}
                          className="form-control m-b input-sm list-text-area"
                          {...register("currentPassword", {
                            required: appLabel.fieldReq,
                            minLength: {
                              value: 5,
                              message: appLabel.passwordShort,
                            },
                          })}
                          placeholder="************"
                        />
                        <span
                          className="p-change-viewer"
                          onClick={() => togglePassword("current")}
                        >
                          <i
                            className={
                              currentPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          />
                        </span>
                        <span className="asterisks">
                          {errors?.currentPassword?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <label className="al-subtitle">{appLabel.newPwd}</label>
                        <input
                          type={newPwdShown ? "text" : "password"}
                          className="form-control m-b input-sm list-text-area"
                          {...register("newPassword", {
                            required: appLabel.fieldReq,
                            minLength: {
                              value: 5,
                              message: appLabel.passwordNotGreater,
                            },
                          })}
                          placeholder="************"
                        />
                        <span
                          className="p-change-viewer"
                          onClick={() => togglePassword("new")}
                        >
                          <i
                            className={
                              newPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          />
                        </span>
                        <span className="asterisks">
                          {errors?.newPassword?.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <label className="al-subtitle">
                          {appLabel.confirmPwd}
                        </label>
                        <input
                          type={confirmPwdShown ? "text" : "password"}
                          className="form-control m-b input-sm list-text-area"
                          placeholder="************"
                          {...register("confirmPassword", {
                            required: appLabel.fieldReq,
                            minLength: {
                              value: 5,
                              message: appLabel.passwordShort,
                            },
                          })}
                        />
                        <span
                          className="p-change-viewer"
                          onClick={() => togglePassword("confirm")}
                        >
                          <i
                            className={
                              confirmPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          />
                        </span>
                        <span className="asterisks">
                          {errors?.confirmPassword?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="col-md-6 col-md-offset-4"
                        style={{ marginTop: "20%" }}
                      >
                        <i className="fa fa-lock text-navy fa-5x" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
