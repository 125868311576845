import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import RightSide from "../../ui-setup/RightSide";
import AppModal from "../../layouts/AppModal";
import appStatic from "../../../config/appStaticData";
import {
  Col12BlockFacility,
  ColBlockFac,
  TableFacility,
} from "../../layouts/Facility";

function NewPatient(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    resetField: resetField2,
    unregister: unregister2,
    formState: { errors: errors2 },
  } = useForm();

  const { location, userData, staticData } = props;
  const { state } = location;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const { tableData } = appStatic;

  const currentDate = BOM.CurrentDate();

  const [locationData, setLocationData] = useState([]);
  const [codeData, setCodeData] = useState([]);
  const [addedcodeData, setAddedCodeData] = useState([]);

  useEffect(() => {
    getLocation();
    getCode();
  }, []);

  function getLocation() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: "ACTIVATED",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetLocation, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setLocationData(res.locationListing);
      }
    });
  }
  function getCode() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: "ACTIVATED",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetCoCode, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCodeData(res.gpCodeListing);
      }
    });
  }

  const onAddCode = (formValues) => {
    // console.log(formValues);

    const keyCode = codeData.find(
      (item) => item.GP_CODE_ID === formValues.codeId
    );
    const keyLoc = locationData.find((item) => item.LO_ID === formValues.loId);

    setAddedCodeData(
      BOM.GetUnique(
        [
          ...addedcodeData,
          {
            codeId: keyCode.GP_CODE_ID,
            codeName: keyCode.GP_CODE_NAME,
            codeDesc: keyCode.GP_CODE_DESCRIPTION,
            loName: keyLoc.LO_NAME,
            ...formValues,
          },
        ],
        "codeId"
      )
    );
    $(".clearInput").val("");
    unregister2("");
    // console.log(addedcodeData);
  };
  const removeItem = (indexParam) => {
    setAddedCodeData([
      ...addedcodeData.slice(0, indexParam),
      ...addedcodeData.slice(indexParam + 1),
    ]);
  };
  const formSubmit = (formValues) => {
    let tempPd = [];
    addedcodeData.forEach((el) => {
      tempPd.push({
        codeId: el.codeId,
        loId: el.loId,
        consultationDate: el.consultationDate,
      });
    });
    formValues.codeData = tempPd;
    let body = {
      profileId: PROFILE_ID,
      coId: CO_ID,
      ...formValues,
      userToken: token,
    };

    // console.log(body);
    // return;
    BOM.LoadAlert(cnt.LOAD, "Processing");
    /** SEND REQ */
    // BOM.SendReqAction(body, api.NewPatient, "/manage-patient");

    BOM.FetchReqAction(body, api.NewPatient, (err, res) => {
      if (err) {
        // BOM.AlertMsg(cnt.DANGER, err);
        BOM.NotifyMsg(cnt.DANGER, err);
      } else {
        BOM.NotifyMsg(cnt.SUCCESS, res.message);
          history.push("/manage-patient", {
            ggParam: BOM.RedirectListObj,
          })
      }
    });
  };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.newPatient} />

      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-patient"
                      className="btn btn-white btn-xs m-r"
                    >
                     <i className="fa fa-long-arrow-left text-info" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                    {/* <button type="submit" className="btn btn-info btn-xs">
                      <i className="fa fa-save" />
                      &nbsp;{appLabel.save}
                    </button> */}
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <Col12BlockFacility topTitle={appLabel.generalInfo}>
                      <div className="form-group col-md-4">
                        <label className="al-subtitle">
                          {appLabel.lastName}{" "}
                          <span className="asterisks">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control  list-text-area"
                          placeholder={appLabel.lastName}
                          {...register("lname", {
                            required: appLabel.fieldReq,
                          })}
                        />
                        <span className="asterisks">
                          {errors?.lname?.message}
                        </span>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="al-subtitle">
                          {appLabel.firstName}{" "}
                          <span className="asterisks">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control  list-text-area"
                          placeholder={appLabel.firstName}
                          {...register("fname", {
                            required: appLabel.fieldReq,
                          })}
                        />
                        <span className="asterisks">
                          {errors?.fname?.message}
                        </span>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="al-subtitle">
                          {appLabel.gender} <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control  list-text-area"
                          {...register("gender", {
                            required: appLabel.fieldReq,
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {appStatic.gender.map((item, index) => (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors?.gender?.message}
                        </span>
                      </div>
                    </Col12BlockFacility>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        <hr />
                        <div className="form-group">
                          <div className="col-sm-6">
                            <button
                              type="submit"
                              className={`${
                                ROLE_SHORT.toLowerCase() === "sys"
                                  ? "hide"
                                  : "btn btn-info btn-xs"
                              }`}
                            >
                              <i className="fa fa-save" />
                              &nbsp;{appLabel.save}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewPatient);
