/** CONSTANTS LIST */
export const cnt = {
  /** ALERT */
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  INFO: "INFO",
  PROCESS: "PROCESS",
  LOAD: "LOAD",
  COPIED: "COPIED",

  /** AUTH */
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  SIGN_UP: "SIGN_UP",
  AUTH_BUFFER: "AUTH_BUFFER",
  LOCKSCREEN: "LOCKSCREEN",
  COUNT_PARAM:"COUNT_PARAM",

  /** RIGHT SIDE BTN */
  RIGHT_ADD_BTN: "RIGHT_ADD_BTN",
  RIGHT_SAVE_BTN: "RIGHT_SAVE_BTN",
  RIGHT_DETAIL_BTN: "RIGHT_DETAIL_BTN",
  RIGHT_EDIT_BTN: "RIGHT_EDIT_BTN",
  RIGHT_DELETE_BTN: "RIGHT_DELETE_BTN",
  RIGHT_APPROVE_BTN: "RIGHT_APPROVE_BTN",
  RIGHT_REJECT_BTN: "RIGHT_REJECT_BTN",
  CLEAR_RIGHT_BTN: "CLEAR_RIGHT_BTN",

  /**STATIC DATA */
  RESPONSE_SUCCESS_DATA: "RESPONSE_SUCCESS_DATA",
  RESPONSE_FAILED_DATA: "RESPONSE_FAILED_DATA",
  RECORD_STATUS: "RECORD_STATUS",
  MENU_DATA: "MENU_DATA",
  VERIFY_ORDER_ID: "VERIFY_ORDER_ID",
  CLEAR_STATIC_DATA: "CLEAR_STATIC_DATA",
  CURRENT_CO: "CURRENT_CO",
};
