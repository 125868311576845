import React, { useEffect } from "react";
import Sidebar from "../ui-setup/Sidebar";
import { connect } from "react-redux";
import TopHeader from "../ui-setup/TopHeader";
import RightSide from "../ui-setup/RightSide";
import Footer from "../ui-setup/Footer";
import { correctHeight, detectBody } from "./Helpers";
import { RPB, BOM } from "../../services/defined.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { useIdleTimer } from "react-idle-timer";
import { ggTemplateInit } from "../../services/defined.action";
import history from "../../history";
const Main = (props) => {
  const { isSignedIn, userData } = props;

  // start idle logic
  const handleOnIdle = (event) => {
    history.push("/lockscreen");
    // console.log("user is idle", props);
    console.log("last active", getLastActiveTime());
    // BOM.AlertMsg(
    //   cnt.WARNING,
    //   "Due to inactivity, the session has expired. Re-login if necessary."
    // );
  };

  const handleOnActive = (event) => {
    // console.log("user is active", event);
    // console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    // console.log("user did something", event);
  };
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 250,
  });

  // end idle logic

  useEffect(() => {
    // console.log(isSignedIn);

    switch (isSignedIn) {
      case "":
      case false:
      case undefined:
        return history.push("/sign-in");
    }

    // // Run correctHeight function on load and resize window event
    // $(window).bind("load resize", function () {
    //   correctHeight();
    //   detectBody();
    // });

    // // Correct height of wrapper after metisMenu animation.
    // $(".metismenu a").click(() => {
    //   setTimeout(() => {
    //     correctHeight();
    //   }, 300);
    // });
    // console.log(props);
    
    RPB(props);
    window.scrollTo(0, 0);
    ggTemplateInit();
    // $('select')
  }, []);

  return (
    <div id="wrapper">
      <Sidebar location={props.location} />

      <div id="page-wrapper" className="gray-bg">
        <TopHeader />

        {/* <RightSide /> */}
        <div className="wrapper wrapper-content">{props.children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    isSignedIn: state.auth.isSignedIn,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
