import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { smoothlyMenu } from "../layouts/Helpers";
import { Link } from "react-router-dom";
import appLabel from "../../config/appLabel";

const TopHeader = (props) => {
  const { isSignedIn, userData, staticData } = props;
  const { PROFILE_ID, ROLE_SHORT, USER_FNAME, USER_LNAME, CO_NAME, ROLE_NAME, USER_TITLE } =
    userData;
  const { coConfig } = staticData;

  const fullName = `${USER_TITLE} ${USER_LNAME} ${USER_FNAME}`;

  useEffect(() => {
     $("body").removeClass("mini-navbar");
  }, []);

  const toggleNavigation = (e) => {
    e.preventDefault();
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  };
  return (
    <div className="row border-bottom white-bg">
      <nav
        className="navbar navbar-static-top white-bg"
        role="navigation"
        style={{ marginBottom: 0, boxShadow: "0 6px 6px -6px gray" }}
      >
        <div className="navbar-header">
          <a
            className="navbar-minimalize minimalize-styl-2 btn btn-primary "
            onClick={(e) => toggleNavigation(e)}
            href="#"
          >
            <i className="fa fa-bars"></i>{" "}
          </a>
        </div>
        <div
          className="navbar-header"
          style={{
            position: "relative",
            paddingLeft: "18px",
          }}
        >
          <h2
            style={{
              fontWeight: "600",
              color: "#000",
              fontSize: "18px",
            }}
          >
            {/* {appLabel.mainTitle} */}
            {ROLE_SHORT.toLowerCase() === "sys" ? (
              <Link to="/landing" style={{ fontSize: "14px" }}>
                <i className="fa fa-long-arrow-left" /> {appLabel.back} &nbsp;
              </Link>
            ) : (
              ""
            )}
            {`${
              ROLE_SHORT.toLowerCase() === "sys"
                ? coConfig.CO_NAME.length < 12
                  ? coConfig.CO_NAME
                  : coConfig.CO_NAME.substring(0, 12) + "..."
                : ""
            }`}
          </h2>
        </div>
        <ul className="nav navbar-top-links navbar-right">
          <li className="dropdown">
            <a className="dropdown-toggle count-info" data-toggle="dropdown">
              {/* <i className="fa fa-user text-navy" /> */}
              <i className={USER_TITLE.toLowerCase() === 'dr' ? "fa fa-user-md text-info":"fa fa-user text-navy"} style={{fontSize:'18px'}} />
              &nbsp;{fullName.length < 24 ? fullName : fullName.substring(0, 24) + "..."} &nbsp;
              <b className="caret" />
            </a>
            <ul className="dropdown-menu dropdown-messages">
              <div>
                <li>
                  <div className="dropdown-messages-box">
                    <a ui-sref="home" className="pull-left">
                      <i
                        className={`fa-2x text-navy fa fa-${
                          ROLE_SHORT.toLowerCase() === "sys"
                            ? "building-o"
                            : "hospital-o"
                        }`}
                      />
                    </a>
                    <div className="media-body">
                      <h3>{`${
                        ROLE_SHORT.toLowerCase() === "sys" ? "nCare" : CO_NAME
                      }`}</h3>
                      <small className="text-muted">{ROLE_NAME}</small>
                    </div>
                  </div>
                </li>
                <li className="divider" />
              </div>

              <li>
                <Link to="/change-password">
                  <i className="fa fa-edit text-navy" />
                  &nbsp; {appLabel.changePassword}
                </Link>
              </li>
              <li>
                <Link to="/lockscreen">
                  <i className="fa fa-lock text-navy" />
                  &nbsp; {appLabel.lockscreen}
                </Link>
              </li>
              <li>
                <Link to="/sign-in">
                  <i className="fa fa-sign-out text-navy" />
                  &nbsp; {appLabel.logoutBtn}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    userData: state.auth.userData,
    staticData: state.staticData,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
