import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Dashboard.css";
import Main from "../../components/layouts/Main";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import { BOM } from "../../services/defined.action";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";

export const Dashboard = (props) => {
  const { userData, staticData, auth } = props;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getDashboardCount();

    setTimeout(() => {
      onHideCount()
    }, 20000);

  }, []);

  // window.location.href = "";
  function getDashboardCount() {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);
    let body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      userToken: token,
    };

    // console.log(body);

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetDashboardCount, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.dashboardCount;
        setDashboardData([
          {
            title: appLabel.new,
            value: objDetail.new,
            icon: "users",
            color: "primary",
            criteria: "NEW",
          },
          // {
          //   title: appLabel.inProgress,
          //   value: objDetail.inProgress,
          //   icon: "refresh",
          //   color: "warning",
          //   criteria: "IN PROGRESS",
          // },
          {
            title: appLabel.discharged,
            value: objDetail.discharged,
            icon: "tags",
            color: "navy",
            criteria: "DISCHARGED",
          },
          {
            title: appLabel.rejected,
            value: objDetail.rejected,
            icon: "thumbs-down",
            color: "danger",
            criteria: "REJECTED",
          },
          {
            title: appLabel.private,
            value: objDetail.private,
            icon: "rub",
            color: "none",
            criteria: "PRIVATE",
          },
          {
            title: appLabel.closed,
            value: objDetail.closed,
            icon: "gavel",
            color: "none",
            criteria: "CLOSED",
          },
        ]);
        setIsLoading(false);
      }
    });
  }

  const handleSkeletonFac = () =>
    Array(4)
      .fill()
      .map((el, elIndex) => (
        <div key={elIndex} className="col-sm-4 col-lg-3">
          <div className="ibox">
            <div
              className="ibox-content"
              style={{ borderRadius: "15px", boxShadow: "0 6px 6px -3px gray" }}
            >
              <h5 className="m-b-md">
                <Skeleton
                  circle={true}
                  style={{ display: "inline-block" }}
                  height={20}
                  width={20}
                />
                <Skeleton width={`40%`} style={{ left: "35px" }} />
              </h5>
              <h3>
                <Skeleton
                  circle={true}
                  style={{ display: "inline-block" }}
                  height={20}
                  width={20}
                />
                <Skeleton width={`80%`} style={{ left: "35px" }} />
              </h3>
              <h6>
                {" "}
                <Skeleton width={`20%`} style={{ left: "35px" }} />
              </h6>
            </div>
          </div>
        </div>
        // <div key={elIndex} className="col-sm-6 col-md-4 col-lg-4">
        //   <div className="contact-box center-version">
        //     <a>
        //       <Skeleton circle={true} height={35} width={35} />
        //       <h3 className="m-b-xs">
        //         <Skeleton />
        //       </h3>
        //       <div className="font-bold">
        //         <Skeleton />
        //       </div>
        //     </a>
        //     <div className="contact-box-footer">
        //       <div className="m-t-xs btn-group">
        //         <a className="btn btn-xs btn-white">
        //           <Skeleton height={18} width={40} />
        //         </a>
        //         <a className="btn btn-xs btn-white">
        //           <Skeleton height={18} width={40} />
        //         </a>
        //         <a className="btn btn-xs btn-white">
        //           <Skeleton height={18} width={40} />
        //         </a>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      ));

  const onHideCount = ()=>{
        
    dispatch({
      type: cnt.COUNT_PARAM
    });
  }
  return (
    <Main {...props}>
      <div
        className="row wrapper border-bottom white-bg page-heading"
        style={{ marginBottom: "70px" }}
      >
        <div className="col-sm-2 pull-right m-t">
          <span className="label label-primary">
            <i className="fa fa-star text-navy" />
            &nbsp;{appLabel.version}{" "}
          </span>
        </div>

        <div className="col-sm-4 pull-right">
          <div className="title-action">
            <Link to="/new-episode" className="btn btn-white btn-sm">
              <i className="fa fa-plus-circle text-info" />
              &nbsp;{appLabel.addApplication}
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`${auth.countParam ? "hide": "text-center float-e-margins p-md"}`}>
          <div className="alert alert-success alert-dismissable">
            <button
              aria-hidden="true"
              data-dismiss="alert"
              className="close"
              type="button"
              onClick={onHideCount}
            >
              ×
            </button>
            {`Welcome back`}{" "}
            <a className="alert-link" href="#">
              {`${userData.USER_TITLE} ${userData.USER_LNAME}`}
            </a>
            .
          </div>
        </div>

        {isLoading
          ? handleSkeletonFac()
          : dashboardData.map((item, index) => (
              <div key={index} className="col-sm-4 col-lg-3">
                <div className="ibox">
                  <Link
                    to={{
                      pathname: "/manage-episode",
                      state: {
                        ggParam: item,
                      },
                    }}
                  >
                    <div className="ibox-content dashCard">
                      <h5 className="m-b-md">
                        <i className="fa fa-medkit text-navy" /> &nbsp;{" "}
                        {appLabel.episode}
                      </h5>
                      <h3>
                        <i className={`fa fa-${item.icon} text-navy`} />
                        &nbsp; {item.title}
                      </h3>
                      <h6>{item.value}</h6>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
      </div>
      {/* <div className="row" style={{ marginTop: "120" }}>
        <div className="col-lg-12">
          <div className="ibox float-e-margins">
            <div className="ibox-content text-center p-md">
              <h2>
                <span className="text-info">
                  {"There is currently no patient activity."}
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div> */}
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
  auth: state.auth,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
