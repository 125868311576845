const appLabel = {
  systemName: "nCare",
  welcomeTo: "Welcome to",
  fillTogetStarted: "Fill in the form to get started.",
  manageUser: "Manage User",
  userDetail: "User Detail",
  userId: "User Id",
  userPassword: "Password",
  userFullName: "Full Name",
  userPhone: "Phone Number",

  newUser: "New User Setup",
  editUser: "Edit User Setup",
  userEmail: "Email",
  email: "Email",
  nuban: "Nuban",

  yetToConfigure: "Yet to configure.",
  this_menu_has_no_sub_menu: "This menu has no sub menu.",
  has_all_menu_configured: "All menus are configured.",
  You_are_about_to_add_all_config:
    "You are about to add all menus configuration.",
  entityType: "Entity Type",
  entityMenuDetail: "Entity Config Detail",
  create: "Create",
  view: "View",
  edit: "Edit",
  approve: "Approve",
  reject: "Reject",
  recordStatus: "Record Status",
  authStatus: "Auth Status",
  makerId: "Maker ID",
  makerDate: "Maker Date",
  checkerId: "Checker ID",
  checkerDate: "Checker Date",

  //ciav
  personalInfo: "Personal",
  generalInfo: "General Information",
  addressInfo: "Address Information",
  manageUser: "Manage User",
  userSetup: "User Setup",
  username: "Username",
  roleName: "Role",
  password: "Password",
  btnLogin: "Login",
  forgotpass: "Forgot your password?",
  currentPwd: "Current password",
  newPwd: "New password",
  confirmPwd: "Confirm password",
  changePwd: "Setting the password",
  proceedBtn: "Proceed",
  resetPwd: "Reset password",
  confirmEmail: "Confirm email",
  goToLogin: "Go back to Login",
  back: "Back",
  changePassword: "Change Password",
  logoutBtn: "Log out",
  lockScreenMessage:
    "Your are in lock screen. You need to enter your password to go back to the app.",
  unlockBtn: "Unlock",
  yes: "Yes",
  no: "No",
  passwordDonMatch:
    "The new password and the confirmation password do not match.",
  passwordShort: "Password too short.",
  passwordNotGreater: "The password must be longer than 5 characters.",
  status: "Statut",
  recordStatus: "Record Status",
  contactInformation: "Contact",
  addressInformation: "Address information",
  applicationInformation: "Information on the application",
  contactPersonInformation: "Contact information",
  addressInfo: "Address",
  nextOfkinInfo: "Next of Kin Information",
  applicantDetail: "Détails de la demande",
  profileOverview: "Profile",
  contactFullName: "Nom complet du contact",
  contactPhone: "Téléphone du contact ",
  phoneNumber: "Phone",
  contactEmail: "Adresse e-mail du contact",
  firstName: "First Name",
  lastName: "Surname",
  mdleName: "Middle Name",
  middleName: "Middle Name",
  dob: "Date of birth",
  gender: "Gender",
  female: "Female",
  male: "Male",
  lastLogin: "Last Login",
  nationality: "Nationalité",
  placeOfBirth: "Lieu de naissance",
  applicationComment: "Commentaire sur la demande",

  cardTop1: "Nouvelle",
  cardTop2: "En cours",
  cardTop3: "Approuvée",
  cardTop4: "Rejetée",
  cardTop5: "Collectée",
  application: "Demande",
  mainTitle: "Easy Capture",
  fieldReq: "Field is required.",
  pwdShort: "Password too short",
  manageVisaApplication: "Gestion de la demande",
  noDataInTableMsg: "No data in table",
  noDataAvailableMsg: "There is no data available.",
  authorized: "Authorized",
  unauthorized: "Unauthorized",
  disabled: "Disabled",
  approved: "Approved",
  inProgress: "In Progress",
  rejected: "Rejected",
  discharged: "Discharged",

  process: "Process",
  progress: "Progress",
  discharge: "Discharge",
  close: "Claime",

  listing: "listing",
  authorizedListing: "Authorized Listing",
  unauthorizedListing: "Unauthorized Listing",
  disabledListing: "Disabled Listing",
  backBtn: "Back",
  add: "Add to List",
  addApplication: "Add New",
  date: "Date",
  vaId: "Demande Id",
  country: "Pays",
  visaCategory: "Type de visa",
  viewMore: "View More",
  deleteBtn: "Delete",
  approveBtn: "Approuver",
  rejectBtn: "Rejeter",
  submitBtn: "Envoyer",
  collectBtn: "Collecter",
  cancelBtn: "Cancel",
  activateBtn: "Activate",
  deactivateBtn: "Deactivate",
  choose: "Select",

  manageCategory: "Gestion du type de visa",
  categoryId: "Id.",
  categoryName: "Nom du type de visa",
  categoryDescription: "Description du type de visa",
  newCategoryName: "Configuration du type de visa",
  categoryInformation: "Informations sur le type de visa",
  categoryDetail: "Détail du type de visa",

  coId: "Id.",
  coName: "Company Name",
  coEmail: "Company Email",
  coPhone: "Company Phone",
  coReg: "N° de registre",
  coAddress: "Adress",
  coPostcode: "Code postal",
  coCountry: "Pays",
  coProvince: "Province",
  coCity: "Ville",
  flag: "Drapeau",
  newCoName: "Configuration du pays",
  coInformation: "Informations sur le pays",
  coDetail: "Détail du pays",
  manageCompany: "Gestion du pays",

  manageCategoryCo: "Gestion du type de visa par pays",
  ccdId: "Id.",
  requirementDoc: "Liste des documents à fournir",
  requiredDoc: "Document requis",
  formInfo: "Infos sur le formulaire",
  uploadInfo: "Téléchargement d'infos",

  numberOfApplicant: "Nombre de demandeurs",
  applicantInfo: "Informations sur le demandeur",
  add1: "Add New",
  closed: "Claimed",
  private: "Private",
  claimed: "Claimed",
  save: "Save",
  newVaApplication: "Configuration de la demande",

  applicantWarningMsg: "Veuillez fournir des informations sur le demandeur.",
  addMultiple: "Ajouter plusieurs",
  optional: "Optional",
  applicationFee: "Frais de demande ($)",
  additionalInfo: "Informations complémentaires",
  id: "ID No",
  poweredBy: "Powered by",
  poweredByLabel: "nAcomoda",
  new: "New",
  patient: "Patient",
  activated: "Activated",
  deactivated: "Deactivated",
  manageCode: "Manage Code",
  newCode: "New Code",
  codeDetail: "Code Detail",
  consultationCode: "Code",
  codeInfo: "Code Information",
  codeName: "Code",
  codeDescription: "Description ( Optional )",
  manageLocation: "Manage Location",
  newLocation: "New Location",
  locationDetail: "Location Detail",
  locationName: "Location",

  managePatient: "Manage Patient",
  newPatient: "New Patient",
  patientDetail: "Patient Detail",
  comment: "ICD Code / Comment",
  brefComment: "Liste ICDs here e.g 000, 111, 254 ",
  consultationInfo: "Consultation Information",
  imageBtn: "Image",
  imageSetup: "Image Setup",
  image: "Image",
  uploadInfo: "Upload Information",
  noImage: "There is currently no image of the patient.",
  updateStatus: "Update Status",
  newTo: "New to nCare?",
  createAccount: "Register Now",
  companyInfo: "Company",
  companyType: "Company Type",
  patientFullName: "Patient Full Name",
  manageEpisode: "Manage Episode",
  episodeDetail: "Episode Detail",
  editCode: "Edit Code",
  editEpisode: "Edit Episode",
  episodeId: "Episode No",
  saveChange: "Save Changes",
  newEpisode: "New Episode",
  editEpisodeNo: "Edit Episode No ",
  editLocation: "Edit Location",
  handOver: "Hand Over",
  episode: "Episode",
  lockscreen: "Lock Screen",
  episode: "Episode",
  setupConsultation: "Setup Consultation",
  consultationList: "Consultation List",
  processing:"Processing..",
  codeNotification: "You can choose more than one code at the same time.",
  pageNotFound:"Page Not Found",
  dashboard:"Dashboard",
  version:'Trial Version',
  entryDate:"Entry Date",
  search:"Search By",
  report:"Report",
  configuration:"Configuration",
  title:"Title",
  administrator:"Administrator",
  patient:"Patient",
  code:"Code",
  location:"Location",
  startDate:"Start Date",
  endDate:"End Date",
  searchBtn:"Search",
  totalResult:"Total Result",
  all:"All",
  closedStatus:"Closed",
};

export default appLabel;
