import axios from "axios";
import appConfig from "../config/appConfig.json";

const { dev, prod, isProduction, apiSettings } = appConfig;

/** DEFINED METHOD */
const isProductionUrl = (key) => {
  switch (key) {
    case true:
      return prod.baseURL;
    case false:
      return dev.baseURL;
  }
};
/** LIVE SERVICE */
export default axios.create({
  baseURL: isProductionUrl(isProduction),
});

/** HEADER */
export const headerOptions = {
  headers: apiSettings.headers,
};

/** APIS CONSTANT */
export const apis = {
  /** AUTH */
  Login: "login",
  ChangePassword: "changePassword",
  ForgotPassword: "forgotPassword",
  ResetPassword: "resetPassword",

  // USER
  GetProfile: "getProfile",
  CheckRegLinkToken: "checkRegLinkToken",
  Signup: "signup",
  RegisterLink: "registerLink",
  ActiveInactiveProfile: "activeInactiveProfile",

  // ROLE
  GetRole: "getRole",

  // CODE
  GetCoCode: "getCoCode",
  NewCoCode: "newCoCode",
  ActiveInactiveCode: "activeInactiveCode",
  EditCoCode: "editCoCode",

  // LOCATION
  NewLocation: "newLocation",
  GetLocation: "getLocation",
  ActiveInactiveLocation: "activeInactiveLocation",
  EditLocation: "editLocation",  

  //PATIENT
  NewPatient: "newPatient",
  GetPatient: "getPatient",
  ActiveInactivePatient: "activeInactivePatient",
  EditPatient: "editPatient",

  //FILE UPLOAD
  UploadFile: "uploadFile",
  GetUploadedFile: "getUploadedFile",

  // COMPANY TYPE
  GetCoType: "getCoType",

  // COMPANY
  GetCompany: "getCompany",
  NewCompany: "newCompany",
  ActiveInactiveCompany: "activeInactiveCompany",

  //DASHBOARD
  GetDashboardCount: "getDashboardCount",

  //PATIENT
  NewEpisode: "newEpisode",
  GetEpisode: "getEpisode",
  UpdateEpisodeStatus: "updateEpisodeStatus",
  EditEpisode: "editEpisode",

  // GLOBAL DELETE
  DeleteItem: "deleteItem",

  //handover
  NewHandOver: "newHandOver",

  //Report
  GetReport: "getReport",

};
