import React from "react";
import { Link } from "react-router-dom";
import appStatic from "../../config/appStaticData";
import { BOM } from "../../services/defined.action";
import { apis as api } from "../../services/api.action";
import { cnt } from "../../services/constant.action";
import Logo from "../../assets/img/logoLogin/ncare.png";
import history from "../../history";
import appLabel from "../../config/appLabel";

const HomeScreen = () => {
  const homeMenuData = [
    // { name: appLabel.configuration, value: "configuration" },
    { name: appLabel.episode, value: "dashboard", icon: "medkit" },
    { name: appLabel.administrator, value: "manage-user", icon: "users" },
    { name: appLabel.patient, value: "manage-patient", icon: "ambulance" },
    { name: appLabel.code, value: "manage-code", icon: "code-fork" },
    { name: appLabel.location, value: "manage-location", icon: "map-marker" },
    { name: appLabel.report, value: "report", icon: "pie-chart" },
  ];
  const onNavigateTo = (link) => {
    history.push(link);
  };
  return (
    <div id="page-top" className="landing-page">
      <div className="navbar-wrapper">
        <nav
          className="navbar navbar-default navbar-fixed-top"
          role="navigation"
        >
          <div className="container">
            <div className="navbar-header page-scroll">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand">
                <img
                  alt="image"
                  style={{
                    width: "100px",
                  }}
                  src={Logo}
                />
              </a>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                {/* <li>
                  <a className="page-scroll" href="#page-top">
                    Home
                  </a>
                </li> */}
                <li>
                  <Link to="/sign-in">
                    <i className="fa fa-sign-out text-navy" />
                    &nbsp;{appLabel.logoutBtn}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <section className="gray-sections">
        <div className="container">
          <div
            className="row"
            style={{ marginTop: "60px"}}
          >
            {/* <div className="col-lg-7 col-md-offset-3">
              {homeMenuData.map((item, index) => (
                <div className="col-xs-6 col-sm-4 col-md-3 col-lg-4" key={index} style={{marginBottom:'10%'}}>
                  <button
                    className="btn btn-primary dim btn-large-dim"
                    type="button"
                    onClick={() => onNavigateTo(item.value)}
                  >
                    <i className={`fa fa-${item.icon} fa-2x text-navy`}></i>
                  </button>
                  <h3>{item.name}</h3>
                </div>
              ))}
            </div> */}
            <div className="col-lg-6 col-md-offset-3" style={{ marginTop: "22%"}}>
            {homeMenuData.map((item, index) => (
              <div className="col-xs-6 col-sm-4 col-md-3 col-lg-4" key={index} onClick={() => onNavigateTo(item.value)} style={{cursor:"pointer"}}>
                <div className="ibox float-e-margins">
                  <div style={{ marginTop: "5px" }}>
                    <h1 className="no-margins text-center">
                    <i className={`fa fa-${item.icon} text-navy`}></i>
                    </h1>
                    <h5 className="text-center text-white" >{item.name}</h5>
                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeScreen;
