import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// -------------- PAGES IMPORTS --------------

import SignIn from "../components/auth/SignIn";
import Registration from "../components/auth/Registration";
import ChangePassword from "../components/auth/ChangePassword";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import LockScreen from "../components/auth/LockScreen";

import Dashboard from "../components/dashboard/Dashboard";
import PageNotFound from "../components/ui-setup/PageNotFound";

import ManageUser from "../components/configuration/user/ManageUser";
import NewUser from "../components/configuration/user/NewUser";
import UserDetail from "../components/configuration/user/UserDetail";

import ManageCode from "../components/configuration/gpCode/ManageCode";
import NewCode from "../components/configuration/gpCode/NewCode";
import CodeDetail from "../components/configuration/gpCode/CodeDetail";

import ManageLocation from "../components/configuration/location/ManageLocation";
import LocationDetail from "../components/configuration/location/LocationDetail";
import NewLocation from "../components/configuration/location/NewLocation";

import ManagePatient from "../components/configuration/patient/ManagePatient";
import PatientDetail from "../components/configuration/patient/PatientDetail";
import NewPatient from "../components/configuration/patient/NewPatient";
import EditPatient from "../components/configuration/patient/EditPatient";

import ManageEpisode from "../components/episode/ManageEpisode";
import NewEpisode from "../components/episode/NewEpisode";
import EpisodeDetail from "../components/episode/EpisodeDetail";
import EditEpisode from "../components/episode/EditEpisode";

import ImageSetup from "../components/episode/ImageSetup";
import ManageCompany from "../components/configuration/company/ManageCompany";
import NewCompany from "../components/company/NewCompany";
import CompanyDetail from "../components/configuration/company/CompanyDetail";

import Landing from "../components/ui-setup/Landing";

import HandOver from "../components/episode/HandOver";

import RouteGuard from "../components/layouts/RouteGuard";

import HomeScreen from "../components/homeScreen";
import ReportScreen from "../components/Report/ReportScreen";

export const RoutedContent = () => {
  return (
    <Switch>
      {/* <Navigate from="/" to="landing" exact /> */}
      {/* ROOT PATH */}
      <Route path="/landing" component={Landing} exact />
      <Route path="/" component={SignIn} exact />
      <RouteGuard path="/home" component={HomeScreen} exact />

      <RouteGuard path="/dashboard" component={Dashboard} exact />

      {/* USER */}
      <RouteGuard path="/manage-user" component={ManageUser} exact />
      <RouteGuard path="/new-user" component={NewUser} exact />
      <RouteGuard path="/user-detail" component={UserDetail} exact />

      {/* AUTH */}
      <Route path="/sign-in" component={SignIn} exact />
      <Route
        path="/registration/:registrationToken"
        component={Registration}
        exact
      />
      <Route path="/change-password" component={ChangePassword} exact />
      <Route path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/reset-password/:token" component={ResetPassword} exact />
      <Route path="/lockscreen" component={LockScreen} exact />
      <Route path="/new-company" component={NewCompany} exact />


      {/* GP CODE */}
      <RouteGuard path="/manage-code" component={ManageCode} exact />
      <RouteGuard path="/new-code" component={NewCode} exact />
      <RouteGuard path="/code-detail" component={CodeDetail} exact />

      {/* LOCATION */}
      <RouteGuard path="/manage-location" component={ManageLocation} exact />
      <RouteGuard path="/new-location" component={NewLocation} exact />
      <RouteGuard path="/location-detail" component={LocationDetail} exact />

      {/* PATIENT */}
      <RouteGuard path="/manage-patient" component={ManagePatient} exact />
      <RouteGuard path="/new-patient" component={NewPatient} exact />
      <RouteGuard path="/patient-detail" component={PatientDetail} exact />
      <RouteGuard path="/edit-patient" component={EditPatient} exact />

      {/* EPISODE */}
      <RouteGuard path="/manage-episode" component={ManageEpisode} exact />
      <RouteGuard path="/new-episode" component={NewEpisode} exact />
      <RouteGuard path="/episode-detail" component={EpisodeDetail} exact />
      <RouteGuard path="/edit-episode" component={EditEpisode} exact />
      <RouteGuard path="/hand-over-episode" component={HandOver} exact />
      <RouteGuard path="/image-setup" component={ImageSetup} exact />

      {/* REPORT */}
      <RouteGuard path="/report" component={ReportScreen} exact />

      {/* COMPANY */}
      {/* <RouteGuard path="/manage-company" component={ManageCompany} exact /> */}
      {/* <RouteGuard path="/company-detail" component={CompanyDetail} exact /> */}

      {/* NOT FOUND */}
      <RouteGuard path="*" component={PageNotFound} />
    </Switch>
  );
};
