/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import useGeolocation from "react-hook-geolocation";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { SignOutAction } from "../../services/redux/actions/auth-act";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Bg from "../../assets/img/logoLogin/bg1.jpg";

const ResetPassword = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { SignOutAction, match } = props;
  const { params } = match;

  const [newPwdShown, setNewPwdShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);

  useEffect(() => {
    console.log("this is props: ", props);
    SignOutAction();
  }, []);

  // Password toggle handler
  const togglePassword = (key) => {
    switch (key.toLowerCase()) {
      case "new":
        return setNewPwdShown(!newPwdShown);
      case "confirm":
        return setConfirmPwdShown(!confirmPwdShown);
    }
  };

  const formSubmit = (formValues) => {
    if (formValues.newPassword !== formValues.confirmPassword) {
      BOM.AlertMsg(cnt.WARNING, appLabel.passwordDonMatch);
      return;
    }

    formValues.token = params.token;
    let body = {
      ...formValues,
    };

    // console.log(body);

    // /** SEND REQ */
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.ResetPassword, "/sign-in");
  };

  return (
    <React.Fragment>
      <img className="auth-bg" src={Bg} />
      <div className="centeredContent">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="middle-box loginscreen animated fadeInDown login-card"
                style={{ marginBottom: "0%", marginTop: "16%" }}
              >
                <div className="slogan text-center">
                  <h2>
                    <i className="fa fa-lock text-navy" /> &nbsp;{" "}
                    {appLabel.resetPwd}
                  </h2>
                </div>
                <p>Veuillez remplir le formulaire.</p>
                <form
                  // className="m-t"
                  onSubmit={handleSubmit(formSubmit)}
                  autoComplete="off"
                >
                  <div className="form-group">
                    <label className="text-left">{appLabel.newPwd}</label>
                    <input
                      type={newPwdShown ? "text" : "password"}
                      className="form-control input-sm"
                      placeholder="************"
                      {...register("newPassword", {
                        required: appLabel.fieldReq,
                        minLength: {
                          value: 5,
                          message: appLabel.passwordNotGreater,
                        },
                      })}
                    />
                    <span
                      className="p-viewer"
                      onClick={() => togglePassword("new")}
                    >
                      <i
                        className={
                          newPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </span>
                    <span className="asterisks">
                      {errors?.newPassword?.message}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="text-left">{appLabel.confirmPwd}</label>
                    <input
                      type={confirmPwdShown ? "text" : "password"}
                      className="form-control input-sm"
                      placeholder="************"
                      {...register("confirmPassword", {
                        required: appLabel.fieldReq,
                        minLength: {
                          value: 5,
                          message: appLabel.passwordShort,
                        },
                      })}
                    />
                    <span
                      className="p-viewer"
                      onClick={() => togglePassword("confirm")}
                    >
                      <i
                        className={
                          confirmPwdShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </span>
                    <span className="asterisks">
                      {errors?.confirmPassword?.message}
                    </span>
                  </div>
                  <div className="form-group text-center">
                    <button
                      type="submit"
                      className="btn btn-primary block full-width"
                      style={{
                        marginTop: 30,
                        boxShadow: "0 10px 6px -6px #fff",
                      }}
                    >
                      <i className="fa fa-arrow-circle-right" /> &nbsp;{" "}
                      {appLabel.proceedBtn}
                    </button>
                  </div>
                  <div className="form-group text-center">
                    <Link
                      to="/sign-in"
                      style={{ fontWeight: "bold", color: "#000" }}
                    >
                      <small>
                        <i className="fa fa-arrow-circle-left" />
                        &nbsp;{appLabel.goToLogin}
                      </small>
                    </Link>
                  </div>
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { SignOutAction })(ResetPassword);
