/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import useGeolocation from "react-hook-geolocation";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";

import {
  SignInAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Logo from "../../assets/img/logoLogin/logo1.png";
import Bg from "../../assets/img/logoLogin/bg1.jpg";

const ForgotPassword = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    SignOutAction();
  }, []);

  /** SUBMISSION */
  const onForgot = (formValues) => {
    const body = {
      ...formValues,
    };

    // console.log(body);
    /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.ForgotPassword, "/sign-in");
  };

  return (
    <React.Fragment>
      <img className="auth-bg" src={Bg} />
      <div className="centeredContent">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="middle-box loginscreen animated fadeInDown login-card"
                style={{ marginTop: "16%", marginBottom: "0%" }}
              >
                <div className="slogan text-center">
                  <h2>
                    <i className="fa fa-send text-navy " />
                    &nbsp; {appLabel.forgotpass}
                  </h2>
                </div>
                <p>Please fill in the form.</p>
                <form
                  // className="m-t"
                  onSubmit={handleSubmit(onForgot)}
                  autoComplete="off"
                >
                  <div className="form-group">
                    <label className="text-left">{appLabel.email} </label>
                    <input
                      type="text"
                      className="form-control input-sm"
                      placeholder={appLabel.email}
                      {...register("email", {
                        required: appLabel.fieldReq,
                        pattern: {
                          value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                          message: "Adresse e-mail non valide",
                        },
                      })}
                    />
                    <span className="asterisks">{errors?.email?.message}</span>
                  </div>
                  <div className="form-group text-center">
                    <button
                      type="submit"
                      className="btn btn-primary block full-width"
                      style={{
                        marginTop: 30,
                        boxShadow: "0 10px 6px -6px #fff",
                      }}
                    >
                      <i className="fa fa-arrow-circle-right" /> &nbsp;{" "}
                      {appLabel.proceedBtn}
                    </button>
                  </div>
                  <div className="form-group text-center">
                    <Link
                      to="/sign-in"
                      style={{ fontWeight: "bold", color: "#000" }}
                    >
                      <small>
                        <i className="fa fa-arrow-circle-left" />
                        &nbsp;{appLabel.goToLogin}
                      </small>
                    </Link>
                  </div>
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { SignOutAction })(ForgotPassword);
