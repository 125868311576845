import React from "react";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import appLabel from "../../config/appLabel";
import { BOM } from "../../services/defined.action";
export default function PageNotFound(props) {
  return (
    <Main {...props}>
      {/* <ContentTop pageTitle={appLabel.pageNotFound} /> */}
      <div
        className="text-center animated fadeInDown"
        style={{ margiTop: "0 !important" }}
      >
        <h1>404</h1>
        <h3 className="font-bold">Page Not Found</h3>
        <div className="error-desc">
         Sorry, but the page you requested could not be found.
        </div>
        <a onClick={() => BOM.BackButton()} className="m-t btn-sm btn btn-white"><i className="fa fa-long-arrow-left text-info"/> &nbsp; {appLabel.back}</a>
      </div>
    </Main>
  );
}
