import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import {
  Col12BlockFacility,
  ColBlockFac,
  TableFacility,
  InputTextFacility,
} from "../layouts/Facility";
import Select from "react-select";
import makeAnimated from "react-select/animated";

function EditEpisode(props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    resetField: resetField2,
    unregister: unregister2,
    setValue:setValue2,
    formState: { errors: errors2 },
  } = useForm();

  const { location, userData, staticData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const { tableData } = appStatic;

  const currentDate = BOM.CurrentDate();

  const [locationData, setLocationData] = useState([]);
  const [codeData, setCodeData] = useState([]);
  const [addedcodeData, setAddedCodeData] = useState([]);
  const [patientDateData, setPatientDateData] = useState([]);
  const [episodeDetail, setEpisodeDetail] = useState({});
  const [fieldData, setFieldData] = useState([]);

  const [selectedCodeData, setSelectedCodeData] = useState([]);
  const [codeOptionsData, setCodeOptionsData] = useState([]);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    // console.log(state.ggParam);
    getLocation();
    getCode();
  }, []);

  const getDetailById = (params) => {
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      episodeId: params.EP_ID,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetEpisode, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objDetail = res.episodeDetail;
        setEpisodeDetail(objDetail);
        setPatientDateData(objDetail.PATIENT_DATE);

        setValue("comment", objDetail.EP_COMMENT);

        if (objDetail.PATIENT_DATE.length !== 0) {
          let tempCode = [];
          objDetail.PATIENT_DATE.forEach((element) => {
            tempCode.push({
              codeId: element.GP_CODE_ID,
              codeName: element.GP_CODE_NAME,
              codeDesc: element.GP_CODE_DESCRIPTION,
              loId: element.LO_ID,
              loName: element.LO_NAME,
              consultationDate: element.PD_DATE,
            });
          });
          setAddedCodeData(tempCode);
          setValue2("loId", tempCode[0].loId)
        }

        setFieldData([
          {
            name: appLabel.patientFullName,
            value: objDetail.PATIENT_FULLNAME,
            fieldType: "general",
          },
        ]);
      }
    });
  };
  function getLocation() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: "ACTIVATED",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetLocation, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const locationData = res.locationListing;
        setLocationData(locationData);

        getDetailById(ggParam);
      }
    });
  }
  function getCode() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: "ACTIVATED",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetCoCode, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // setCodeData(res.gpCodeListing);
        let objList = res.gpCodeListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.GP_CODE_ID,
            label: element.GP_CODE_NAME,
            ...element,
          });
        });

        setCodeOptionsData(temp);
      }
    });
  }
  const onAddCode = (formValues) => {
    // console.log(formValues);

    //Validation
    if (selectedCodeData.length === 0) {
      return BOM.AlertMsg(cnt.WARNING, "Select code");
    }
    const keyLoc = locationData.find((item) => item.LO_ID === formValues.loId);
    let temp = [];

    selectedCodeData.forEach((element) => {
      const keyCode = codeOptionsData.find(
        (item) => item.GP_CODE_ID === element.value
      );

      temp.push({
        codeId: keyCode.GP_CODE_ID,
        codeName: keyCode.GP_CODE_NAME,
        codeDesc: keyCode.GP_CODE_DESCRIPTION,
        loName: keyLoc.LO_NAME,
        ...formValues,
      });
    });

    // console.log(temp);
    setAddedCodeData([...addedcodeData, ...temp]);
    $(".clearInput").val("");
    // unregister2("");
    // console.log(addedcodeData);
  };
  const removeItem = (indexParam) => {
    setAddedCodeData([
      ...addedcodeData.slice(0, indexParam),
      ...addedcodeData.slice(indexParam + 1),
    ]);
  };
  const onEditPatient = (formValues) => {
    let body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      editType: "general",
      ...formValues,
      userToken: token,
    };

    // console.log(body);
    // return;
    BOM.LoadAlert(cnt.LOAD, "Processing");
    /** SEND REQ */
    BOM.SendReqAction(body, api.EditEpisode, "/manage-episode");
  };
  const onEditPatientCode = () => {
    const comment = document.getElementById("comment").value;

    let body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      episodeId: ggParam.EP_ID,
      patientId: ggParam.PATIENT_ID,
      codeData: addedcodeData,
      comment,
      userToken: token,
    };

    // console.log(body);
    // return;
    // BOM.LoadAlert(cnt.LOAD, "Processing");

    BOM.GlobalApproveRejectAction(
      body,
      api.EditEpisode,
      "dashboard",
      ggParam.EP_ID,
      appLabel.editEpisodeNo
    );
  };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.editEpisode} />

      <form method="POST" id="NewItemForm" autoComplete="off">
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="ibox-tools">
                    <a
                      onClick={() => BOM.BackButton()}
                      className="btn btn-white btn-xs m-r"
                    >
                      <i className="fa fa-long-arrow-left text-info" />
                      &nbsp;
                      {appLabel.backBtn}
                    </a>
                    {/* <button type="submit" className="btn btn-info btn-xs">
                      <i className="fa fa-save" />
                      &nbsp;{appLabel.save}
                    </button> */}
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <Col12BlockFacility topTitle={appLabel.generalInfo}>
                      {fieldData.map((item, index) => (
                        <InputTextFacility
                          key={index}
                          inputLabel={item.name}
                          inputValue={item.value}
                        />
                      ))}
                    </Col12BlockFacility>
                    <div className="col-md-12">
                      <div className="form-group col-md-4">
                        <label className="al-subtitle">
                          {appLabel.comment}
                          {/* {`( ${appLabel.optional} )`}&nbsp; */}
                        </label>
                        <textarea
                          rows={2}
                          cols={50}
                          className="form-control  list-text-area"
                          placeholder={appLabel.brefComment}
                          {...register("comment")}
                          id="comment"
                        ></textarea>
                      </div>
                    </div>
                    <Col12BlockFacility topTitle={appLabel.consultationInfo}>
                      {/* <div className="form-group col-md-3">
                        <label className="al-subtitle">
                          {appLabel.codeName}{" "}
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control  list-text-area clearInput"
                          {...register2("codeId", {
                            required: appLabel.fieldReq,
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {codeData.map((item, index) => (
                            <option value={item.GP_CODE_ID} key={index}>
                              {item.GP_CODE_NAME}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors2?.codeId?.message}
                        </span>
                      </div> */}
                      <div className="form-group col-md-3">
                        <label className="al-subtitle">
                          {appLabel.codeName}{" "}
                          <span className="asterisks">*</span>
                        </label>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={codeOptionsData}
                          onChange={(opt) => setSelectedCodeData(opt)}
                          isMulti
                        />

                        <span className="asterisks">
                          {errors2?.codeId?.message}
                        </span>
                      </div>
                      <div className="form-group col-md-3">
                        <label className="al-subtitle">
                          {appLabel.date} <span className="asterisks">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control  list-text-area "
                          max={currentDate}
                          {...register2("consultationDate", {
                            required: appLabel.fieldReq,
                          })}
                        />
                        <span className="asterisks">
                          {errors2?.consultationDate?.message}
                        </span>
                      </div>
                      <div className="form-group col-md-3">
                        <label className="al-subtitle">
                          {appLabel.locationName}{" "}
                          <span className="asterisks">*</span>
                        </label>
                        <select
                          className="form-control  list-text-area"
                          {...register2("loId", {
                            required: appLabel.fieldReq,
                          })}
                          disabled
                        >
                          <option value="">{appLabel.choose}</option>
                          {locationData.map((item, index) => (
                            <option value={item.LO_ID} key={index}>
                              {item.LO_NAME}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors2?.loId?.message}
                        </span>
                      </div>
                      <div className="form-group col-md-3">
                        <label className="control-label">&nbsp;</label>
                        <div className="input-group"></div>
                        <button
                          type="button"
                          className="btn btn-info btn-xs"
                          onClick={handleSubmit2(onAddCode)}
                        >
                          <i className="fa fa-plus-circle" />
                          &nbsp; {appLabel.add}
                        </button>
                      </div>
                    </Col12BlockFacility>
                    {addedcodeData.length !== 0 && (
                      <Col12BlockFacility topTitle={appLabel.consultationInfo}>
                        <div className="feed-activity-list">
                          {addedcodeData.length === 0 && (
                            <div className="alert alert-warning text-center">
                              <a className="alert-link">
                                {appLabel.noDataAvailableMsg}
                              </a>
                            </div>
                          )}
                          {addedcodeData.map((item, index) => (
                            <div key={index} className="feed-element">
                              <span className="pull-left text-navy">
                                <i className="fa fa-medkit fa-2x" />
                              </span>
                              <div className="media-body ">
                                <small className="pull-right">
                                  {item.loName}
                                </small>
                                <strong>{item.codeName}</strong> <br />
                                <small className="text-muted">
                                  {item.consultationDate}
                                </small>
                                <div className="well">{item.codeDesc}</div>
                                <div className="actions">
                                  <a
                                    className="btn btn-xs btn-white"
                                    onClick={() => removeItem(index)}
                                  >
                                    <i className="fa fa-times text-info" />{" "}
                                    {appLabel.deleteBtn}
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Col12BlockFacility>
                    )}
                  </div>
                  {addedcodeData.length !== 0 && (
                    <div className="row">
                      <div className="col-sm-12">
                        <div style={{ marginTop: "10px" }}>
                          <div className="form-group">
                            <div className="col-sm-6">
                              <button
                                className={`${
                                  ROLE_SHORT.toLowerCase() === "sys"
                                    ? "hide"
                                    : "btn btn-info btn-xs"
                                }`}
                                type="button"
                                onClick={() => onEditPatientCode()}
                              >
                                <i className="fa fa-save" /> &nbsp;{" "}
                                {appLabel.save}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditEpisode);
