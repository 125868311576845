import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {rightAction} from "../../services/redux/actions/staticData-act"
const RightSide = (props) => {

  const {rightAction, rightBtn}=props
  const globalVar = window.globalData;

  // const [addBtn, setAddBtn] = useState("btn-default disabled");
  // const [saveBtn, setSaveBtn] = useState("btn-default disabled");
  // const [detailBtn, setDetailBtn] = useState("btn-default disabled");
  // const [editBtn, setEditBtn] = useState("btn-default disabled");
  // const [deleteBtn, setDeleteBtn] = useState("btn-default disabled");
  // const [approveBtn, setApproveBtn] = useState("btn-default disabled");
  // const [rejectBtn, setRejectBtn] = useState("btn-default disabled");

  // useEffect(()=>{
  //   console.log(window.location.host);
    
    
  // }, [])

  return (
    <div className="sidebard-panel">
      <div
        className="row text-center"
        style={{ marginTop: "40px", marginBottom: "20px" }}
      >
        <Link
          to={`/${globalVar.addNewLink}`}
          id="addBtn"
          className={`btn ${rightBtn.addBtn} btn-circle btn-lg btn-outline`}
          title="Add New"
        >
          <i className="fa fa-plus"></i>
        </Link>
      </div>
      <div className="row text-center" style={{ marginBottom: "20px" }}>
        <button
          type="submit"
          id="saveBtn"
          className={`btn ${rightBtn.saveBtn} btn-circle btn-lg btn-outline`}
          title="Save"
        >
          <i className="fa fa-save"></i>
        </button>
      </div>
      <div className="row text-center" style={{ marginBottom: "20px" }}>
        {/* <button
          type="submit"
          id="detailBtn"
          className={`btn ${rightBtn.detailBtn} btn-circle btn-lg btn-outline`}
          title="Get details"
        >
          <i className="fa fa-folder-open-o"></i>
        </button> */}
        
        <Link
          to={{
            pathname: globalVar.viewLink.path,
            state:{
              ggParam:globalVar.viewLink.obj,
            }
          }}
          className={`btn ${rightBtn.detailBtn} btn-circle btn-lg btn-outline`}
          title="Get details"
        >
             <i className="fa fa-folder-open-o"></i>
        </Link>
        
      </div>

      <div className="row text-center" style={{ marginBottom: "20px" }}>
        {/* <button
            id="editBtn"
            className={`btn ${rightBtn.editBtn} btn-circle btn-lg btn-outline`}
            type="button"
            title="Edit"
          >
            <i className="fa fa-edit"></i>
        </button> */}
        <Link
          to={{
            pathname: globalVar.editLink.path,
            state:{
              ggParam:globalVar.editLink.obj,
            }
          }}
          className={`btn ${rightBtn.editBtn} btn-circle btn-lg btn-outline`}
          title="Edit"
        >
            <i className="fa fa-edit"></i>
        </Link>
      </div>

      <div className="row text-center" style={{ marginBottom: "20px" }}>
        <button
          id="deleteBtn"
          className={`btn ${rightBtn.deleteBtn} btn-circle btn-lg btn-outline`}
          type="button"
          title="Delete"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>

      <div className="row text-center" style={{ marginBottom: "20px" }}>
        <button
          id="approveBtn"
          className={`btn ${rightBtn.approveBtn} btn-circle btn-lg btn-outline`}
          type="button"
          title="Approve"
        >
          <i className="fa fa-thumbs-up"></i>
        </button>
      </div>

      <div className="row text-center" style={{ marginBottom: "20px" }}>
        <button
          id="rejectBtn"
          className={`btn ${rightBtn.rejectBtn} btn-circle btn-lg btn-outline`}
          type="button"
          title="Reject"
        >
          <i className="fa fa-thumbs-down"></i>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return{
    rightBtn: state.rightSideBtn
}
}

const mapDispatchToProps = {rightAction};

export default connect(mapStateToProps, mapDispatchToProps)(RightSide);
