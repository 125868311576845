import React, { useEffect, useState } from "react";
import Main from "../layouts/Main";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";
import ContentTop from "../ui-setup/ContentTop";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { Link } from "react-router-dom";
import appStatic from "../../config/appStaticData";
import { ColBlockFac, handleSkeletonFac } from "../layouts/Facility";
import Select from "react-select";

const ReportScreen = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { location, userData, staticData } = props;
  const { state, pathname } = location;
  // const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const { tableData, skeletonCountData, statusList } = appStatic;
  const [episodeData, setEpisodeData] = useState([]);
  const [icon, setIcon] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSearchCriteria, setCurrentSearchCriteria] = useState("");

  const [locationData, setLocationData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [episodeCount, setEpisodeCount] = useState(0);
  const currentDate = BOM.CurrentDate();

  const [selectedData, setSelectedData] = useState([]);

  const searchCriteria = [
    {
      title: appLabel.episode,
      criteria: "episode",
      callback: function (params) {
        handleSearchCriteriaShowHide(appLabel.episode, params);
      },
    },
    {
      title: appLabel.patient,
      criteria: "patient",
      callback: function (params) {
        handleSearchCriteriaShowHide(appLabel.patient, params);
      },
    },
    {
      title: appLabel.locationName,
      criteria: "location",
      callback: function (params) {
        handleSearchCriteriaShowHide(appLabel.locationName, params);
      },
    },
  ];

  useEffect(() => {
    getLocation();
    getPatient();
  }, []);
  
  const handleSearchCriteriaShowHide = (criteria, params) => {
    //initialise
    setValue("repId", "");
    setValue("repType", "");
    setEpisodeData([]);
    setEpisodeCount(0);

    //assigned
    setValue("repType", criteria);

    // console.log(criteria);
    setCurrentSearchCriteria(criteria);
    setIsLoading(false);

  };

  function getLocation() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: "ACTIVATED",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetLocation, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        // setLocationData(res.locationListing);

        let objList = res.locationListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.LO_ID,
            label: element.LO_NAME,
            ...element,
          });
        });

        setLocationData(temp);
      }
    });
  }
  function getPatient() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: "ACTIVATED",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetPatient, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.patientListing;
        let temp = [];

        objList.forEach((element) => {
          temp.push({
            value: element.PATIENT_ID,
            label: element.PATIENT_FULLNAME,
            ...element,
          });
        });

        setPatientData(temp);
      }
    });
  }

  

  const onReport = (formData) => {
    if (!formData.repId && formData.repType.toLowerCase() !== "episode") {
      return BOM.AlertMsg(cnt.WARNING, `Please Select ${formData.repType}`);
    }

    setIsLoading(true);
    setEpisodeData([]);
    setEpisodeCount(0);
    
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      userToken: token,
      ...formData,
    };

    // console.log(body);
    // return;

    BOM.FetchReqAction(body, api.GetReport, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.reportListing
        setEpisodeData(objList);
        // setbackUpData(objList);
        setIsLoading(false);
        setEpisodeCount(objList.length);
      }
    });
  };

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.report} />
      <div className="animated fadeInRight">
        <div className="row m-b">
          <div className="col-xs-12 col-xs-offset-1 col-sm-6 col-sm-offset-4 col-md-6 col-md-offset-4">
            {/* <div className="col-sm-6 col-lg-4"> */}
            <div data-toggle="buttons" className="btn-group">
              <label
                className="btn btn-sm btn-white"
                onClick={() => BOM.BackButton()}
              >
                {" "}
                <i className="fa fa-long-arrow-left text-info" />{" "}
                {appLabel.back} &nbsp;
              </label>
              <label className="btn btn-sm btn-white">
                <a
                  className="dropdown-toggle"
                  style={{ color: "inherit" }}
                  data-toggle="dropdown"
                >
                  <i className={`fa fa-${icon} text-info`}></i> &nbsp;{" "}
                  {/* {titleRight} &nbsp; */}
                  {`${appLabel.totalResult} ( ${episodeCount} )`} &nbsp;
                </a>
              </label>
              <label className="btn btn-sm btn-white">
                <a
                  className="dropdown-toggle"
                  style={{ color: "inherit" }}
                  data-toggle="dropdown"
                >
                  <i className={`fa fa-search text-info`}></i> &nbsp;{" "}
                  {`${appLabel.search} ${currentSearchCriteria}`} &nbsp;
                  <i className="fa fa-chevron-down"></i>
                </a>
                <ul className="dropdown-menu dropdown-user">
                  {searchCriteria.map((item, index) => {
                    if (item.title !== currentSearchCriteria) {
                      return (
                        <li key={index}>
                          <a onClick={() => item.callback(item)}>
                            {" "}
                            {item.title}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          {isLoading && (
            handleSkeletonFac()
            )}
            <div className={`${isLoading ? "hide":""}`}>
              <ColBlockFac>
                <div className={`${currentSearchCriteria ? "" : "hide"}`}>
                  {currentSearchCriteria === appLabel.locationName && (
                    <div className="form-group col-md-3">
                      <label className="al-subtitle">
                        {appLabel.location} <span className="asterisks">*</span>
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={locationData}
                        onChange={(e) => setValue("repId", e.LO_ID)}
                      />
                    </div>
                  )}
                  {currentSearchCriteria === appLabel.patient && (
                    <div className="form-group col-md-3">
                      <label className="al-subtitle">
                        {appLabel.patientFullName}{" "}
                        <span className="asterisks">*</span>
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={patientData}
                        onChange={(e) => setValue("repId", e.PATIENT_ID)}
                      />
                    </div>
                  )}
                 <div className="form-group col-md-2">
                    <label className="al-subtitle">
                      {appLabel.status} <span className="asterisks">*</span>
                    </label>
                    <select
                          className="form-control  list-text-area"
                          {...register("status", {
                            required: appLabel.fieldReq,
                          })}
                        >
                          <option value="">{appLabel.choose}</option>
                          {statusList.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span className="asterisks">
                          {errors?.status?.message}
                        </span>
                  </div>

                  <div className="form-group col-md-2">
                    <label className="al-subtitle">
                      {appLabel.startDate} <span className="asterisks">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control  list-text-area "
                      max={currentDate}
                      {...register("startDate", {
                        required: appLabel.fieldReq,
                      })}
                    />
                    <span className="asterisks">
                      {errors?.startDate?.message}
                    </span>
                  </div>
                  <div className="form-group col-md-2">
                    <label className="al-subtitle">
                      {appLabel.endDate} <span className="asterisks">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control  list-text-area "
                      max={currentDate}
                      {...register("endDate", {
                        required: appLabel.fieldReq,
                      })}
                    />
                    <span className="asterisks">
                      {errors?.endDate?.message}
                    </span>
                  </div>
                  <div className="form-group col-md-3">
                    <label className="control-label">&nbsp;</label>
                    <div className="input-group"></div>
                    <button
                      type="button"
                      className="btn btn-info btn-sm"
                      onClick={handleSubmit(onReport)}
                    >
                      {isLoading && (
                        <span style={{ color: "#000", fontWeight: "600" }}>
                          <i className="fa fa-spinner fa-spin" /> &nbsp;{" "}
                          {appLabel.processing}
                        </span>
                      )}

                      {!isLoading && (
                        <span>
                          <i className="fa fa-search" />
                          &nbsp; {appLabel.searchBtn}
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </ColBlockFac>

              <ColBlockFac>
                {episodeData.length === 0 && (
                  <div
                    className="alert alert-warning text-center"
                    style={{ marginTop: "5%" }}
                  >
                    <a className="alert-link">{appLabel.noDataAvailableMsg}</a>
                  </div>
                )}
              </ColBlockFac>
              <ColBlockFac>
                {episodeData.map((item, index) => (
                  <div key={index} className="col-sm-6 col-md-4 col-lg-4 m-t">
                    <div className="contact-box center-version">
                      <small className="pull-right font-bold">
                        {" "}
                        <i className="fa fa-ambulance text-info" /> &nbsp;{" "}
                        {/* {BOM.Uwfirst(item.EP_STATUS)} */}
                        {item.EP_STATUS.toLowerCase()==='closed' ? BOM.Uwfirst('CLAIMED') : BOM.Uwfirst(item.EP_STATUS)}
                      </small>
                      <Link
                        to={{
                          pathname: "/episode-detail",
                          state: {
                            ggParam: item,
                          },
                        }}
                      >
                        <i className="fa fa-medkit text-navy fa-2x m-b" />
                        <h3 className="m-b-xs">
                          {/* <strong>{item.PATIENT_FULLNAME}</strong> */}
                          <i className="fa fa-hand-o-right text-navy" />
                          &nbsp;
                          <strong>
                            {item.PATIENT_FULLNAME.length < 17
                              ? item.PATIENT_FULLNAME
                              : item.PATIENT_FULLNAME.substring(0, 17) + "..."}
                          </strong>
                        </h3>
                        <div className="font-light">
                          {BOM.Uwfirst(item.PATIENT_GENDER)}
                        </div>
                        <div className="font-bold h6 text-info">
                          <>{item.LO_NAME}</>
                        </div>
                      </Link>
                      <div
                        className={`${show ? "contact-box-footer" : "hide"}`}
                      >
                        <div className="m-t-xs btn-group">
                          <Link
                            to={{
                              pathname: "/image-setup",
                              state: {
                                ggParam: item,
                              },
                            }}
                            className="btn btn-xs btn-white"
                          >
                            <i className="fa fa-file-image-o text-warning" />{" "}
                            {appLabel.image}
                          </Link>
                          <Link
                            to={{
                              pathname: "/edit-episode",
                              state: {
                                ggParam: item,
                              },
                            }}
                            className="btn btn-xs btn-white"
                          >
                            <i className="fa fa-edit text-info" />{" "}
                            {appLabel.edit}
                          </Link>
                          <Link
                            to={{
                              pathname: "/hand-over-episode",
                              state: {
                                ggParam: item,
                              },
                            }}
                            className="btn btn-xs btn-white"
                          >
                            <i className="fa fa-share-alt text-info" />{" "}
                            {appLabel.handOver}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}{" "}
              </ColBlockFac>
            </div>
          
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ReportScreen);
