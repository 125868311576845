import { cnt } from "../../constant.action";

const INITIAL_STATE = {
  isSignedIn: false,
  userToken: false,
  countParam: false,
  userData: false,
  lockscreenData: {
    username: null,
    fullName: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case cnt.SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        userToken: payload.token,
        userData: payload,
        lockscreenData: {
          username: payload.USERNAME,
          fullName: payload.USER_FNAME + " " + payload.USER_LNAME,
        },
      };
    case cnt.AUTH_BUFFER:
      return {
        ...state,
        authBuffer: payload,
      };
    case cnt.SIGN_OUT:
      return { ...INITIAL_STATE };
    case cnt.LOCKSCREEN:
      return { ...state, isSignedIn: false, userToken: false, userData: false, countParam: false };
    case cnt.COUNT_PARAM:
      return { ...state, countParam: true};
    default:
      return state;
  }
};
