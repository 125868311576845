import React, { useEffect, useState } from "react";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import Main from "../layouts/Main";
import ContentTop from "../ui-setup/ContentTop";
import history from "../../history";
import RecordStatus from "../ui-setup/RecordStatus";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import RightSide from "../ui-setup/RightSide";
import AppModal from "../layouts/AppModal";
import appStatic from "../../config/appStaticData";
import { ColBlockFac, InputTextFacility } from "../layouts/Facility";
function ImageSetup(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { location, userData, staticData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const [fieldData, setFieldData] = useState([]);
  const [fileDetail, setFileDetail] = useState({});

  useEffect(() => {
    // console.log(ggParam);

    setFieldData([
      // {
      //   name: appLabel.episodeId,
      //   value: ggParam.EP_ID,
      // },
      {
        name: appLabel.userFullName,
        value: ggParam.PATIENT_FNAME + " " + ggParam.PATIENT_LNAME,
      },
    ]);

    getUploadedFile();
  }, []);

  function getUploadedFile() {
    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      moduleId: ggParam.EP_ID,
      docModule: "patient",
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetUploadedFile, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.documents;
        setFileDetail(objDetail);
      }
    });
  }
  const onUpload = () => {
    let formdata = new FormData();
    var imagedata = document.querySelector('input[type="file"]').files[0];

    //validate image size
    if (!imagedata) {
      BOM.AlertMsg(cnt.WARNING, "Please select an image");
      return;
    }

    console.log(imagedata);
    /** CHECK FOR IMAGE TYPE */
    
    switch (imagedata.type) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/jpg':
            console.log(imagedata.type + ' file type is allowed');
        break;  
      default:
        return BOM.AlertMsg(
             cnt.WARNING,
             'The ' + imagedata.type + ' is not supported. Your file must be of the type image (JPEG, JPG, or PNG)'
           );
          
    }

    if (imagedata.size > 4000000) {
      BOM.AlertMsg(
        cnt.WARNING,
        "The file size of " + imagedata.name + " must be less than 4 MB."
      );
      return;
    }

    //append image
    formdata.append("file[]", imagedata);

    let body = {
      profileId: PROFILE_ID,
      coId: CO_ID,

      moduleId: ggParam.EP_ID,
      docType: "patient_image",
      docDesc: "patient image",
      docModule: "patient",

      userToken: token,
    };

    //append body into formData
    for (const key in body) {
      formdata.append(key, body[key]);
    }

    //send request
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(formdata, api.UploadFile, "/manage-episode");
  };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.imageSetup} />

      <form method="POST" autoComplete="off">
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="ibox-tools">
                    <a
                      onClick={() => BOM.BackButton()}
                      className="btn btn-white btn-xs m-r"
                    >
                       <i className="fa fa-long-arrow-left text-info" />
                      &nbsp;
                      {appLabel.backBtn}
                    </a>
                    {/* <button
                      type="button"
                      onClick={() => onUpload()}
                      className="btn btn-info btn-xs"
                    >
                      <i className="fa fa-save" />
                      &nbsp;{appLabel.save}
                    </button> */}
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <ColBlockFac topTitle={appLabel.generalInfo}>
                      {fieldData.map((item, index) => (
                        <InputTextFacility
                          key={index}
                          inputLabel={item.name}
                          inputValue={item.value}
                        />
                      ))}
                    </ColBlockFac>
                    <ColBlockFac topTitle={appLabel.uploadInfo}>
                      <div className="form-group col-md-4">
                        <label className="al-subtitle">
                          {appLabel.image} &nbsp;
                          <span className="asterisks">*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control input-sm list-text-area"
                          accept=".jpg, .jpeg, .png, .gif"
                        />
                      </div>
                      {fileDetail.DOCUMENT ? (
                        <div className="col-sm-8 co-xs-8">
                          <div className="row" style={{ marginTop: 15 }}>
                            <div className="col-md-3 col-sm-3 co-xs-12">
                              <div className="box">
                                <a href={fileDetail.DOCUMENT} target={"_blank"}>
                                  <img
                                    style={{ width: 201, height: 200 }}
                                    src={fileDetail.DOCUMENT}
                                    className="img-ht img-fluid rounded"
                                  />
                                </a>
                              </div>
                              <label
                                className="control-label"
                                style={{ marginTop: 5 }}
                              >
                                {/* &nbsp; {fileDetail.DOC_DESCRIPTION} */}
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-sm-8 co-xs-8">
                          <div className="alert alert-warning text-center">
                            <a className="alert-link">{appLabel.noImage}</a>
                          </div>
                        </div>
                      )}
                    </ColBlockFac>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        <hr />
                        <div className="form-group">
                          <div className="col-sm-6">
                            <button
                              type="button"
                              onClick={() => onUpload()}
                              className="btn btn-info btn-xs"
                            >
                              <i className="fa fa-save" />
                              &nbsp;{appLabel.save}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ImageSetup);
