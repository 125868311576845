import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import RecordStatus from "../../ui-setup/RecordStatus";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import RightSide from "../../ui-setup/RightSide";
import AppModal from "../../layouts/AppModal";
import appStatic from "../../../config/appStaticData";

function NewLocation(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    resetField: resetField2,
    formState: { errors: errors2 },
  } = useForm();

  const { location, userData } = props;
  const { state } = location;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;

  useEffect(() => {}, []);

  const formSubmit = (formValues) => {
    let body = {
      profileId: PROFILE_ID,
      coId: CO_ID,
      ...formValues,
      userToken: token,
    };

    // console.log(body);
    BOM.LoadAlert(cnt.LOAD, "Processing");
    /** SEND REQ */
    // BOM.SendReqAction(body, api.NewLocation, "/manage-location");

    BOM.FetchReqAction(body, api.NewLocation, (err, res) => {
      if (err) {
        // BOM.AlertMsg(cnt.DANGER, err);
        BOM.NotifyMsg(cnt.DANGER, err);
      } else {
        BOM.NotifyMsg(cnt.SUCCESS, res.message);
          history.push("/manage-location", {
            ggParam: BOM.RedirectListObj,
          })
      }
    });
  };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.newLocation} />

      <form
        method="POST"
        id="NewItemForm"
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-location"
                      className="btn btn-white btn-xs m-r"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                    {/* <button
                      type="submit"
                      className={`${
                        ROLE_SHORT.toLowerCase() === "sys"
                          ? "hide"
                          : "btn btn-info btn-xs"
                      }`}
                    >
                      <i className="fa fa-save" />
                      &nbsp;{appLabel.save}
                    </button> */}
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle text-navy" />
                        &nbsp; {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />

                      <div className="form-group col-md-3">
                        {/* <label className="al-subtitle">
                          {appLabel.locationName}&nbsp;
                          <span className="asterisks">*</span>
                        </label> */}
                        <input
                          type="text"
                          className="form-control  list-text-area"
                          placeholder={appLabel.locationName}
                          {...register("loName", {
                            required: appLabel.fieldReq,
                          })}
                        />
                        <span className="asterisks">
                          {errors?.loName?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        <hr />
                        <div className="form-group">
                          <div className="col-sm-6">
                            <button
                              type="submit"
                              className={`${
                                ROLE_SHORT.toLowerCase() === "sys"
                                  ? "hide"
                                  : "btn btn-info btn-xs"
                              }`}
                            >
                              <i className="fa fa-save" />
                              &nbsp;{appLabel.save}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewLocation);
