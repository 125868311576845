import appLabel from "./appLabel";

const appStatic = {
  userTitle: [
    { id: "1", value: "MS", name: "MS - Miss" },
    { id: "2", value: "MR", name: "MR - Mister" },
    // { id: "3", value: "MRS", name: "MRS" },
    { id: "4", value: "DR", name: "DR - Doctor" },
    { id: "5", value: "PROF", name: "PROF - Professor" },
    // { id: "6", value: "ADV", name: "ADV - Advocate" },
    { id: "7", value: "HON", name: "HON - Honorable" },
    // { id: "8", value: "AMB", name: "AMB - Ambassador" },
    { id: "9", value: "REV", name: "REV - Reverend" },
  ],
  gender: [
    { id: "FEMALE", name: "Female" },
    { id: "MALE", name: "Male" },
  ],
  bankAccountType: [
    { id: "Current", name: "Current" },
    { id: "Saving", name: "Saving" },
  ],
  subActionList: [
    {
      value: "1",
      name: "create",
    },
    {
      value: "1",
      name: "view",
    },
    {
      value: "1",
      name: "edit",
    },
    {
      value: "1",
      name: "approve",
    },
    {
      value: "1",
      name: "reject",
    },
  ],
  menuData: [
    {
      DESCRIPTION: "Dashboard",
      FUNCTION_ID: "001",
      ICON: "home",
      POSITION: "1",
      URL: "/dashboard",
      SUB_MENU: [],
    },
    {
      DESCRIPTION: appLabel.report,
      FUNCTION_ID: "003",
      ICON: "pie-chart",
      POSITION: "3",
      URL: "/report",
      SUB_MENU: [],
    },
    {
      DESCRIPTION: "Configuration",
      FUNCTION_ID: "006",
      ICON: "cog",
      POSITION: "6",
      URL: "",
      HAS_RIGHT: 1,
      SUB_MENU: [
        {
          DESCRIPTION: "Administrator",
          FUNCTION_ID: "0072",
          URL: "/manage-user",
          HAS_RIGHT: 1,
        },
        {
          DESCRIPTION: "Patient",
          FUNCTION_ID: "0074",
          URL: "/manage-patient",
          HAS_RIGHT: 1,
        },
        {
          DESCRIPTION: "Code",
          FUNCTION_ID: "0071",
          URL: "/manage-code",
          HAS_RIGHT: 1,
        },
        {
          DESCRIPTION: "Location",
          FUNCTION_ID: "0074",
          URL: "/manage-location",
          HAS_RIGHT: 1,
        },
      ],
    },
    // {
    //   DESCRIPTION: "Episode",
    //   FUNCTION_ID: "006",
    //   ICON: "stethoscope",
    //   POSITION: "6",
    //   URL: "",
    //   HAS_RIGHT: 1,
    //   SUB_MENU: [
    //     {
    //       DESCRIPTION: "Manage Episole",
    //       FUNCTION_ID: "0072",
    //       URL: "/manage-episode",
    //       HAS_RIGHT: 1,
    //     },
    //   ],
    // },
  ],

  countryData: [
    {
      id: "1",
      enName: "Angola",
      frName: "Angola",
      flag: "ao",
    },
    {
      id: "2",
      enName: "China",
      frName: "Chine",
      flag: "cn",
    },
    {
      id: "3",
      enName: "Spain",
      frName: "Espagne",
      flag: "es",
    },
    {
      id: "4",
      enName: "France",
      frName: "France",
      flag: "fr",
    },
    {
      id: "5",
      enName: "South Africa",
      frName: "Afrique du Sud",
      flag: "za",
    },
    {
      id: "6",
      enName: "Belgium",
      frName: "Belgique",
      flag: "be",
    },
    {
      id: "7",
      enName: "Canada",
      frName: "Canada",
      flag: "ca",
    },
    {
      id: "8",
      enName: "Republic of the Congo",
      frName: "République du Congo",
      flag: "cg",
    },
  ],

  tableData: {
    userList: [
      { name: appLabel.id },
      { name: appLabel.lastName },
      { name: appLabel.firstName },
      { name: appLabel.username },
    ],
    codeList: [{ name: appLabel.consultationCode }],
    locationList: [{ name: appLabel.locationName }],
    patientList: [
      { name: appLabel.lastName },
      { name: appLabel.firstName },
      { name: appLabel.gender },
    ],
    patientDateList: [
      { name: appLabel.date },
      { name: appLabel.codeName },
      { name: appLabel.codeDescription },
      { name: appLabel.locationName },
    ],
  },
  searchCriteria:[
    {name:appLabel.patient},
    {name:appLabel.locationName},
    {name:appLabel.entryDate},
  ],
  statusList: [
    { name: appLabel.all, value:appLabel.all },
    { name: appLabel.new, value:appLabel.new },
    { name: appLabel.discharged, value:appLabel.discharged },
    { name: appLabel.rejected, value:appLabel.rejected },
    { name: appLabel.private, value:appLabel.private },
    { name: appLabel.claimed, value:appLabel.closedStatus },
  ],
};

export default appStatic;
