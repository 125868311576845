import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logoLogin/ncare.png";
import appLabel from "../../config/appLabel";
import { BOM } from "../../services/defined.action";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import appStatic from "../../config/appStaticData";
// import history from "../../history";

const Sidebar = (props) => {
  const { isSignedIn, userData } = props;
  const { profileId, session, userRoleId } = userData;
  const { menuData } = appStatic;
  const [activo, setActivo] = useState("");
  const [secondLevel, setSecondLevel] = useState(false);
  const [roleMenuData, setRoleMenuData] = useState([]);

  useEffect(() => {
    // console.log("sidebar: ", props);
    // getRoleMenu(userRoleId);
    $("#side-menu").metisMenu();
  }, [menuData]);

  function getRoleMenu(roleId) {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId,
      roleId,
      session,
    };

    BOM.FetchReqAction(body, api.GetRoleMenu, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        var roleMenu = [];
        roleMenu = res.roleData.FUNCTIONS;

        // setRoleMenuData(roleMenu);
        // MetsiMenu

        /** ORDER ITM S BY POSITION */
        const myData = []
          .concat(roleMenu)
          .sort((a, b) => (a.POSITION > b.POSITION ? 1 : -1));
        setRoleMenuData([...myData]);

        $("#side-menu").metisMenu();
      }
    });
  }

  const activeRoute = (routeName) => {
    // console.log(routeName);
    if (props.location.pathname.toLowerCase() === routeName.toLowerCase()) {
      return "active";
    }
    return "";
  };

  const renderMenu = menuData.map((menu, index) => {
    return (
      <li className={activeRoute(menu.URL)} key={index}>
        {menu.SUB_MENU.length === 0 ? (
          <Link to={menu.URL}>
            <i className={`fa fa-${menu.ICON} text-navy`}></i>{" "}
            <span className="nav-label">{menu.DESCRIPTION}</span>
          </Link>
        ) : (
          <React.Fragment>
            <a>
              <i className={`fa fa-${menu.ICON} text-navy`}></i>{" "}
              <span className="nav-label">{menu.DESCRIPTION}</span>
              <span className="fa arrow" />
            </a>
            {/* <ul className={secondLevel ? 'nav nav-second-level' : 'nav nav-second-level collapse'}> */}
            <ul className="nav nav-second-level collapse">
              {menu.SUB_MENU.map((submenu, subIndex) => {
                return (
                  <li className={activeRoute(submenu.URL)} key={subIndex}>
                    <Link to={submenu.URL}>{submenu.DESCRIPTION}</Link>
                  </li>
                );
              })}
            </ul>
          </React.Fragment>
        )}
      </li>
    );
  });

  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="dropdown profile-element">
              {" "}
              <span>
                <img
                  alt="image"
                  style={{
                    width: "100px",
                    marginLeft: "35px",
                  }}
                  src={Logo}
                />
              </span>
            </div>

            <div className="logo-element">{appLabel.systemName}</div>
          </li>
          {renderMenu}
        </ul>
      </div>
      <div style={{ marginTop: "100px", textAlign: "center", color: "azure" }}>
        <b>{appLabel.version}</b>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  menuData: state.staticData.menuData,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
