/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import useGeolocation from "react-hook-geolocation";
import { apis as api } from "../../services/api.action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { SignOutAction } from "../../services/redux/actions/auth-act";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import appStatic from "../../config/appStaticData";
import history from "../../history";
import Bg from "../../assets/img/logoLogin/bg1.jpg";

const NewCompany = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { SignOutAction, match } = props;
  const { params } = match;

  const [companyTypeData, setCompanyTypeData] = useState([]);

  useEffect(() => {
    // console.log("Param: ", params);
    getCompanyType();
  }, []);

  function getCompanyType() {
    const body = {
      // profileId: PROFILE_ID,
      criteria: "ACTIVATED",
      // userToken: token,
    };

    BOM.FetchReqAction(body, api.GetCoType, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        setCompanyTypeData(res.companyTypeListing);
      }
    });
  }
  const formSubmit = (formValues) => {
    let body = {
      ...formValues,
    };

    // console.log(body);
    // return;
    // /** SEND REQ */
    BOM.LoadAlert(cnt.LOAD, "Processing");
    BOM.SendReqAction(body, api.NewCompany, "/sign-in");
  };

  return (
    <React.Fragment>
      <img className="auth-bg" src={Bg} />
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <header>
              <div
                className="animated fadeInDown login-card"
                style={{
                  maxWidth: "600px",
                  zIndex: "100",
                  margin: "0 auto",
                  //  paddingTop: "40px",
                  marginBottom: "3%",
                  marginTop: "5%",
                }}
              >
                <div
                  className="slogan text-center"
                  style={{ marginBottom: "20px" }}
                >
                  <h2>
                    <i className="fa fa-circle-o-notch text-navy" />{" "}
                    <small>{appLabel.welcomeTo}</small>&nbsp;{" "}
                    {appLabel.systemName}
                  </h2>
                </div>
                <p>{appLabel.fillTogetStarted}</p>

                <form
                  // className="m-t"
                  onSubmit={handleSubmit(formSubmit)}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a
                            data-toggle="tab"
                            href="#personalInfo"
                            target="_self"
                          >
                            <i className="fa fa-user text-navy" />
                            &nbsp;{appLabel.personalInfo}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#companyInfo"
                            target="_self"
                          >
                            <i className="fa fa-building text-navy" />
                            &nbsp;{appLabel.companyInfo}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="personalInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              <p>
                                <i className="fa fa-warning text-warning" />
                                &nbsp;
                                <i>
                                  All fields with <b className="asterisks">*</b>{" "}
                                  are mandatory.
                                </i>
                              </p>
                              <br />
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.title}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-sm"
                                  {...register("title", {
                                    required: appLabel.fieldReq,
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {appStatic.userTitle.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors?.title?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.username}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder={appLabel.username}
                                  {...register("username", {
                                    required: appLabel.fieldReq,
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Le nom d'utilisateur doit comporter plus de 3 caractères.",
                                    },
                                    maxLength: {
                                      value: 10,
                                      message:
                                        "Le nom d'utilisateur ne doit pas comporter plus de 10 caractères.",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.username?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.lastName}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder={appLabel.lastName}
                                  {...register("lname", {
                                    required: appLabel.fieldReq,
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.lname?.message}
                                </span>
                              </div>
                              {/* <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.mdleName}{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder={appLabel.mdleName}
                                  {...register("mdlName")}
                                />
                              </div> */}
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.firstName}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder={appLabel.firstName}
                                  {...register("fname", {
                                    required: appLabel.fieldReq,
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.fname?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.gender}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-sm"
                                  {...register("gender", {
                                    required: appLabel.fieldReq,
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {appStatic.gender.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors?.gender?.message}
                                </span>
                              </div>
                              {/* <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.dob}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control input-sm"
                                  max={"2004-01-01"}
                                  placeholder={appLabel.dob}
                                  {...register("dob", {
                                    required: appLabel.fieldReq,
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.dob?.message}
                                </span>
                              </div> */}
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.phoneNumber}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder={appLabel.phoneNumber}
                                  {...register("phone", {
                                    required: "Field is required.",
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.phone?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.email}&nbsp;
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder={appLabel.email}
                                  {...register("email", {
                                    required: appLabel.fieldReq,
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                      message: "Invalid email address",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.email?.message}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="companyInfo">
                          <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-sm-12">
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.companyType}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <select
                                  className="form-control input-sm"
                                  {...register("coTypeId", {
                                    required: appLabel.fieldReq,
                                  })}
                                >
                                  <option value="">{appLabel.choose}</option>
                                  {companyTypeData.map((item, index) => (
                                    <option value={item.CO_TYPE_ID} key={index}>
                                      {item.CO_TYPE_NAME}
                                    </option>
                                  ))}
                                </select>
                                <span className="asterisks">
                                  {errors?.coTypeId?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.coName}{" "}
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder={appLabel.coName}
                                  {...register("coName", {
                                    required: appLabel.fieldReq,
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.coName?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.coPhone}&nbsp;
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder={appLabel.coPhone}
                                  {...register("coPhone", {
                                    required: appLabel.fieldReq,
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.coPhone?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.coEmail}&nbsp;
                                  <span className="asterisks">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder={appLabel.coEmail}
                                  {...register("coEmail", {
                                    required: appLabel.fieldReq,
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                                      message: "Adresse e-mail non valide",
                                    },
                                  })}
                                />
                                <span className="asterisks">
                                  {errors?.coEmail?.message}
                                </span>
                              </div>
                              <div className="form-group col-md-9">
                                <label className="al-subtitle">
                                  {appLabel.coAddress}&nbsp;
                                  <span className="asterisks">*</span>
                                </label>
                                <textarea
                                  rows={5}
                                  cols={50}
                                  className="form-control input-sm"
                                  placeholder={appLabel.coAddress}
                                  {...register("coAddress", {
                                    required: appLabel.fieldReq,
                                  })}
                                ></textarea>
                                <span className="asterisks">
                                  {errors?.coAddress?.message}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div>
                        <hr />
                        <div className="form-group">
                          <div className="col-sm-6">
                            <Link
                              to="/sign-in"
                              className="btn btn-white btn-sm m-r"
                            >
                              <i className="fa fa-arrow-circle-left" />
                              &nbsp; {appLabel.backBtn}
                            </Link>
                            <button
                              className="btn btn-info btn-sm "
                              type="submit"
                            >
                              <i className="fa fa-arrow-circle-right" /> &nbsp;{" "}
                              {appLabel.save}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </header>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { SignOutAction })(NewCompany);
