import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import RightSide from "../../ui-setup/RightSide";
import AppModal from "../../layouts/AppModal";
import appStatic from "../../../config/appStaticData";
import { RecordStatus } from "../../layouts/Facility";

function LocationDetail(props) {
  const { location, userData, staticData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const [locationDetail, setLocationDetail] = useState({});
  const [record, setRecord] = useState({});

  useEffect(() => {
    // console.log(state.ggParam);
    getDetailById(ggParam);
  }, []);
  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      loId: params.LO_ID,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetLocation, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objDetail = res.locationDetail;
        setLocationDetail(objDetail);
      }
    });
  };

  const updateItem = (criteria, statusLabel) => {
    let body = {
      profileId: PROFILE_ID,
      loId: ggParam.LO_ID,
      criteria,
      userToken: token,
    };

    // console.log(body);

    BOM.GlobalApproveRejectAction(
      body,
      api.ActiveInactiveLocation,
      "manage-location",
      locationDetail.LO_NAME,
      statusLabel + " "
    );
  };

  // const deleteItem = () => {
  //   let body = {
  //     profileId: PROFILE_ID,
  //     vaId: locationDetail.VA_ID,
  //     userToken: token,
  //   };

  //   // console.log(body);

  //   BOM.GlobalApproveRejectAction(
  //     body,
  //     api.DeleteVisaApplication,
  //     "manage-visa-application",
  //     locationDetail.VA_ID,
  //     "Supprimer la demande n° "
  //   );
  // };

  function x(obj) {
    return obj;
  }

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.locationDetail} />

      <form method="POST" autoComplete="off">
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="ibox-tools">
                    <Link
                      to="manage-location"
                      className="btn btn-white btn-xs m-r"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                    {locationDetail.LO_STATUS === "DEACTIVATED" && (
                      <button
                        type="button"
                        className={`${
                          ROLE_SHORT.toLowerCase() === "sys"
                            ? "hide"
                            : "btn btn-info btn-xs"
                        }`}
                        onClick={() =>
                          updateItem("ACTIVATED", appLabel.activateBtn)
                        }
                      >
                        <i className="fa fa-thumbs-up" />
                        &nbsp; {appLabel.activateBtn}
                      </button>
                    )}

                    {locationDetail.LO_STATUS === "ACTIVATED" && (
                      <button
                        type="button"
                        className={`${
                          ROLE_SHORT.toLowerCase() === "sys"
                            ? "hide"
                            : "btn btn-info btn-xs"
                        }`}
                        onClick={() =>
                          updateItem("DEACTIVATED", appLabel.deactivateBtn)
                        }
                      >
                        <i className="fa fa-thumbs-down" />
                        &nbsp; {appLabel.deactivateBtn}
                      </button>
                    )}
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="al-heading">
                        <i className="fa fa-circle text-navy" />
                        &nbsp; {appLabel.generalInfo}
                      </label>
                      <div
                        className="hr-line-dashed"
                        style={{ marginTop: 0 }}
                      />

                      <div className="form-group col-md-3">
                        <label className="al-subtitle">
                          {appLabel.locationName}&nbsp;
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm"
                          disabled
                          defaultValue={locationDetail.LO_NAME}
                        />
                      </div>
                    </div>
                    {/* record status */}
                    {/* <RecordStatus objParam={locationDetail} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetail);
