/**
 * @author Gedeon NdundeCode
 * @date 08/12/2021
 */
import Auth, { apis as api, headerOptions as options } from "./api.action";
import Notiflix from "notiflix";
import { cnt } from "./constant.action";
import history from "../history";
import { store } from "./redux/store";
import {
  jaidsValidator,
  localTokenSession,
} from "../components/layouts/Helpers";
import appLabel from "../config/appLabel";
// const currentUser = {
//   profileId: store.getState().auth.userData.profileId,
//   token: store.getState().auth.userToken //localTokenSession.token,
// };

/** BM (Block Of Methods) */
export const BOM = {
  FetchReqAction: async (body, apiUrl, callback) => {
    const headers = { ...options.headers, apisessionkey: body.session };

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      // const response = res.data.data;
      const response = res.data;
      const status = res.data.status;

      switch (status.toLowerCase()) {
        case "ok":
          BOM.LoadTimeout();
          $(".footable").footable();

          callback(null, response);
          break;
        case "forbidden":
          callback(response.message, null);
          history.push("/sign-in");
          break;
        // case "bad_request":
        //   BOM.AlertMsg(
        //     cnt.DANGER,
        //     "Une mauvaise requête s'est produite. Veuillez contacter le support de l'équipe de développement du système!"
        //   );
        //   break;
        default:
          if (response.message.toLowerCase() === "invalid session") {
            history.push("/sign-in");
          }
          callback(response.message, null);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      callback("Something went wrong with your request.", null);
    }
  },
  SendReqAction: async (body, apiUrl, redirectUrl) => {
    const headers = { ...options.headers, apisessionkey: body.session };

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.AlertMsg(cnt.SUCCESS, response.message);
          history.push(redirectUrl);
          break;
        case "bad request":
          BOM.AlertMsg(
            cnt.DANGER,
            "A bad request occured. Please contact the dev support!"
          );
          break;
        case "failed":
          BOM.AlertMsg(cnt.WARNING, response.message);
          break;
        default:
          BOM.AlertMsg(cnt.WARNING, response.message);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      BOM.AlertMsg(cnt.DANGER, "Something went wrong with your request.");
    }
  },
  SendReqActWithReload: async (body, apiUrl) => {
    const headers = { ...options.headers, apisessionkey: body.session };

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          Notiflix.Report.success(cnt.SUCCESS, response.message, "OK", () => {
            BOM.ReloadComponent();
          });
          break;
        case "bad request":
          BOM.AlertMsg(
            cnt.DANGER,
            "A bad request occured. Please contact the dev support!"
          );
          break;
        case "failed":
          BOM.AlertMsg(cnt.WARNING, response.message);
          break;
        default:
          BOM.AlertMsg(cnt.WARNING, response.message);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      BOM.AlertMsg(cnt.DANGER, "Something went wrong with your request.");
    }
  },
  SendPOReqAction: async (body, apiUrl, body2, apiUrl2, redirectUrl) => {
    const headers = { ...options.headers, apisessionkey: body.session };

    body.channel = "self service";
    body2.channel = "self service";

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          body2.orderId = response.orderId;

          const res2 = await Auth.post(apiUrl2, body2, { headers });
          const response2 = res2.data.data;
          switch (response2.status.toLowerCase()) {
            case "ok":
              BOM.AlertMsg(cnt.SUCCESS, response.message);
              history.push(redirectUrl);
              break;
            case "bad request":
              BOM.AlertMsg(
                cnt.DANGER,
                "A bad request occured. Please contact the dev support!"
              );
              break;
            case "failed":
              BOM.AlertMsg(cnt.DANGER, response2.message);
              break;
            default:
              BOM.AlertMsg(
                cnt.DANGER,
                "Something went wrong with your request."
              );
              break;
          }

          break;
        case "bad request":
          BOM.AlertMsg(
            cnt.DANGER,
            "A bad request occured. Please contact the dev support!"
          );
          break;
        case "failed":
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
        default:
          BOM.AlertMsg(cnt.DANGER, "Something went wrong with your request.");
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      BOM.AlertMsg(cnt.DANGER, "Something went wrong with your request.");
    }
  },
  SessionLessReqAction: async (body, apiUrl, callback) => {
    const headers = { ...options.headers };
    // LoadAlert(cnt.LOAD, "Processing");

    try {
      const res = await Auth.post(apiUrl, body, { headers });

      const response = res.data;
      const status = res.data.status;

      // console.log(status);

      switch (status.toLowerCase()) {
        case "ok":
          BOM.LoadTimeout();
          callback(null, response);
          break;
        case "forbidden":
          callback(response.message, null);
          history.push("/sign-in");
          break;
        default:
          callback(response.message, null);
          break;
      }
    } catch (error) {
      console.log("error: ", error);
      callback("Failed to load response data.", null);
    }
  },
  AlertMsg: (type, message) => {
    Notiflix.Loading.remove();
    try {
      switch (type) {
        case cnt.WARNING:
          return Notiflix.Report.warning("WARNING", message, "OK");
        case cnt.SUCCESS:
          return Notiflix.Report.success("SUCCESS", message, "OK");
        case cnt.DANGER:
          return Notiflix.Report.failure("ERROR", message, "OK");
        case cnt.INFO:
          return Notiflix.Report.info("INFO", message, "OK");
        case cnt.COPIED:
          return Notiflix.Report.success("COPIED", message, "OK");
        default:
          return Notiflix.Report.warning("WARNING", message, "OK");
      }
    } catch (error) {
      console.log("error wow: ", error);
    }
  },
  NotifyMsg: (type, message) => {
    Notiflix.Loading.remove();
    try {
      switch (type) {
        case cnt.WARNING:
          return Notiflix.Notify.warning(message);
        case cnt.SUCCESS:
          return Notiflix.Notify.success(message);
        case cnt.DANGER:
          return  Notiflix.Notify.failure(message);
        case cnt.INFO:
          return  Notiflix.Notify.info(message);
        default:
          return Notiflix.Notify.warning(message);
      }
    } catch (error) {
      console.log("error wow: ", error);
    }
  },
  LoadAlert: (type, message) => {
    try {
      switch (type.toLowerCase()) {
        case cnt.PROCESS:
          return Notiflix.Loading.dots(message + "...");
        case cnt.LOAD:
          return Notiflix.Loading.dots(message + "...");
        default:
          return Notiflix.Loading.dots(message + "...");
      }
    } catch (error) {
      console.log("error on loadingAlert func: ", error);
    }
  },
  ActivateRightBtnAction: (formName, rightBtn, submitMethod) => {
    setTimeout(() => {
      // let validate = new jaidsValidator();
      jaidsValidator().formValidationInit(formName, rightBtn, submitMethod);
    }, 500);
  },
  GetBrowserName: () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) !== -1
    ) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      !document.documentMode == true
    ) {
      return "Internet Explorer";
    } else {
      return "Not sure!";
    }
  },
  DatatableUsage: (title = "Record") => {
    $(document).ready(function () {
      setTimeout(function () {
        $("#myTable").DataTable({
          retrieve: true,
          // destroy: true,
          dom: '<"html5buttons"B>lTfgitp',
          buttons: [
            // {
            //   extend: "copy",
            // },
            // {
            //   extend: "csv",
            // },
            // {
            //   extend: "excel",
            //   title: title + "_list",
            // },
            {
              extend: "pdf",
              title: title + "_list",
            },

            {
              extend: "print",
              customize: function (win) {
                $(win.document.body).addClass("white-bg");
                $(win.document.body).css("font-size", "10px");

                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
            },
          ],
        });
      }, 700);
    });
  },
  GenerateTimestamp: () => {
    // get uniq time for a document sent;
    var now = new Date();
    var timestamp = now.getFullYear().toString();
    timestamp += (now.getMonth() + 1).toString();
    timestamp += now.getDate().toString() + "";
    timestamp += now.getHours().toString();
    timestamp += now.getMinutes().toString();
    timestamp += now.getSeconds().toString();
    timestamp += now.getMilliseconds().toString();
    return timestamp;
  },
  GetUnique: (arr, comp) => {
    // store the comparison  values in array
    const unique = arr
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.lastIndexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  },
  RmvAccentOnly: (str_param) => {
    // str_param.replace(/\s+/g, " ");
    let mystr = str_param.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return mystr; //.replace(/'/g, "A");
  },
  GetWindowData: (key, option = "") => {
    switch (key.toLowerCase()) {
      case "origin":
        return window.location.origin + option;
      case "host":
        return window.location.host;
      case "hostname":
        return window.location.hostname;
      case "href":
        return window.location.href;
      case "pathname":
        return window.location.pathname;
      case "port":
        return window.location.port;
      case "protocol":
        return window.location.protocol;
      default:
        return window.location.origin;
    }
  },
  Uwfirst: (str) => {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  },
  IsApproveOrReject: (status = "exit") => {
    /** CLEAR ALL CURRENT BTN RIGHT ACTION THEN ASSIGN NEW BASE ON CASE*/

    switch (status.toLowerCase().trim()) {
      case "a":
        [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_REJECT_BTN].map((item) => {
          store.dispatch({
            type: item,
          });
        });
        break;

      case "r":
        [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_APPROVE_BTN].map((item) => {
          store.dispatch({
            type: item,
          });
        });
        break;
      case "u":
        [cnt.CLEAR_RIGHT_BTN, cnt.RIGHT_REJECT_BTN, cnt.RIGHT_APPROVE_BTN].map(
          (item) => {
            store.dispatch({
              type: item,
            });
          }
        );
        break;
      default:
        [cnt.CLEAR_RIGHT_BTN].map((item) => {
          store.dispatch({
            type: item,
          });
        });
        break;
    }
  },
  GlobalApproveRejectAction: (
    body,
    reqUrl,
    redirectUrl,
    label = null,
    action
  ) => {
    // return async () => {

    // confirm
    Notiflix.Confirm.show(
      "Are you sure ?",
      action + label,

      "Yes",
      "No",
      function () {
        // Yes
        BOM.LoadAlert(cnt.LOAD, "Processing");
        BOM.SendReqAction(body, reqUrl, redirectUrl);
      },
      function () {
        // No button
      }
    );
    // };
  },
  SendReqWithConfirm: (body, reqUrl, action, label = "") => {
    // return async () => {

    // confirm
    Notiflix.Confirm.show(
      "Are you sure ?",
      action + label,

      "Yes",
      "No",
      function () {
        // Yes
        // BOM.LoadAlert(cnt.LOAD, "Processing");
        BOM.SendReqActWithReload(body, reqUrl);
      },
      function () {
        // No button
      }
    );
    // };
  },
  LoadTimeout: () => {
    Notiflix.Loading.remove();
  },
  SystemTimeout: (param) => {
    return setTimeout(() => {
      BOM.LoadTimeout();
      console.log(`timeout occured on: ${param.toUpperCase()} request API`);
      Notiflix.Report.warning(
        "WARNING",
        "The server encountered something unexpected that didn't allow it to complete the request. We apologize.",
        "OK"
      );
    }, 60000);
  },
  CurrentDate: () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  NewRecordStatusdata: () => {
    return {
      FLG_STATUS: "U",
      MAKER_ID: "gedeon@openfactorgroup.com",
      MAKER_DATE: BOM.CurrentDate(),
      CHECKER_ID: "Not Applicable",
      CHECKER_DATE: "Not Applicable",
    };
  },
  handleFileRead: async (event, setState_param) => {
    const file = event.target.files[0];
    if (!file) {
      // if file is empty
      setState_param("");
      return;
    }

    const base64 = await BOM.convertBase64(file);
    let strB64 = base64.split("base64,")[1]; //.replace(/^data:image\/[a-z]+;base64,/, "");
    setState_param(strB64);
    // console.log(strB64);
  },
  convertBase64: (file) => {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  },
  DownloadAction: (base64File, fileName) => {
    fileName = BOM.ReplaceSpace(fileName, "_");
    let fileType = BOM.DetectMimeType(base64File);

    const source = "data:" + fileType + ";base64," + base64File;

    const downloadLink = document.createElement("a");

    downloadLink.href = source;
    downloadLink.download = fileName.replace(/ /g, ""); // rename your file
    downloadLink.click();
  },
  DetectMimeType: (b64) => {
    let signatures = {
      JVBERi0: "application/pdf",
      iVBORw0KGgo: "image/png",
      "/9j/4AAQSkZJRgABAQ": "image/jpeg",
    };
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  },
  ReplaceSpace: (str, character) => {
    return str.replace(/ /g, character);
  },
  ReformatDate: (dateParam) => {
    dateParam = new Date(dateParam);
    let dd = dateParam.getDate();
    let mm = dateParam.getMonth() + 1;
    let yyyy = dateParam.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (yyyy + "-" + mm + "-" + dd).toString();
  },
  NumberGenerator: (startIndex = 0, length = 10) => {
    let arrData = [];
    for (let index = startIndex; index <= length; index++) {
      arrData.push(index);
    }

    return arrData;
  },
  RemoveItem: (index, state_param, setState_param) => {
    setState_param([
      ...state_param.slice(0, index),
      ...state_param.slice(index + 1),
    ]);
  },
  PrepareConfigData: (roleParam, coIdParam, coIdConfigParam) => {
    let coId = coIdParam;
    switch (roleParam.toLowerCase()) {
      case "sys":
        return (coId = coIdConfigParam);
      default:
        return coId;
    }
  },
  BackButton: () => {
    window.history.back();
  },
  ReloadComponent: () => {
    window.location.reload(false);
  },
  RedirectListObj:{
    title: appLabel.authorized,
    otherTitle: "",
    color: "warning",
    icon: "tag",
    criteria: "ACTIVATED",
  },
};

/** RPB (Right Privilege Button) */
export const RPB = (props) => {
  /** CALLING GLOBAL VARIABLE */
  const globalVar = window.globalData;

  store.dispatch({
    type: cnt.CLEAR_RIGHT_BTN,
  });

  switch (props.location.pathname.toLowerCase()) {
    case "/dashboard":
      // store.dispatch({
      //   type: cnt.RIGHT_DETAIL_BTN,
      // });
      // globalVar.viewLink = {
      //   path: "/manage-role",
      //   obj: {
      //     FLG_STATUS: "U",
      //     MAKER_ID: "jared@fakemail.com",
      //   },
      // };
      // let pathArray = ["RIGHT_DETAIL_BTN", "RIGHT_EDIT_BTN"];

      // pathArray.map((item) => {
      //   store.dispatch({
      //     type: item,
      //   });
      // });
      break;

    case "/dashboard2":
      store.dispatch({
        type: cnt.RIGHT_SAVE_BTN,
      });
      break;
    case "/role-detail":
      [cnt.RIGHT_APPROVE_BTN, cnt.RIGHT_REJECT_BTN].map((item) => {
        // if (item !== cnt.RIGHT_APPROVE_BTN) {
        store.dispatch({
          type: item,
        });
        // }
      });
      break;
    case "/manage-company":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-company";
      break;
    case "/manage-user":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-user";
      break;
    case "/manage-order":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-order";
      break;
    case "/order-upload":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-order-upload";
      break;
    case "/manage-tax":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-tax";
      break;
    case "/manage-currency":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-currency";
      break;
    case "/manage-surcharge":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-surcharge";
      break;
    case "/manage-trust-account":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-trust-account";
      break;
    case "/manage-bank":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-bank";
      break;
    case "/manage-company-bank":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-company-bank";
      break;
    case "/manage-contract":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-contract";
      break;
    case "/manage-invoice":
      store.dispatch({
        type: cnt.RIGHT_ADD_BTN,
      });
      globalVar.addNewLink = "new-invoice";
      break;
    case "/new-company":
    case "/new-subrole":
    case "/edit-subrole":
    case "/new-subrole-menu":
    case "/add-to-subrole-menu":
    case "/new-entity-menu":
    case "/add-to-entity-menu":
    case "/new-user":
    case "/edit-user":
    case "/new-order":
    case "/new-order-upload":
    case "/new-share-order":
    case "/new-tax":
    case "/new-currency":
    case "/new-surcharge":
    case "/new-trust-account":
    case "/new-bank":
    case "/new-company-bank":
    case "/new-contract":
    case "/new-invoice":
    case "/new-share-invoice":
      store.dispatch({
        type: cnt.RIGHT_SAVE_BTN,
      });
      break;
      // case "/role-detail":
      //   [cnt.RIGHT_APPROVE_BTN, cnt.RIGHT_REJECT_BTN].map((item) => {
      //     // if (item !== cnt.RIGHT_APPROVE_BTN) {
      //     store.dispatch({
      //       type: item,
      //     });
      //     // }
      //   });
      break;
  }
};

/** RAL (Right Action Logic) */
export const RAL = {
  GetStoreMenu: async () => {
    let menu = [];
    setTimeout(() => {
      // console.log(store.getState().staticData.menuData);
      menu = store.getState().staticData.menuData;
    }, 500);

    return menu;
  },
  searchAction: function (submenuDescription) {
    return this.checkSearchAction(RAL.GetStoreMenu(), submenuDescription);
  },
  checkSearchAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.search === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.search === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action create :', found);
      return true;
    } else {
      console.log("Action create :", found);
      return false;
    }
  },
  createAction: function (submenuDescription) {
    return this.checkCreateAction(RAL.GetStoreMenu(), submenuDescription);
  },
  checkCreateAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.create === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.create === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action create :', found);
      return true;
    } else {
      console.log("Action create :", found);
      return false;
    }
  },
  approvAction: function (submenuDescription) {
    return this.checkApprovAction(RAL.GetStoreMenu(), submenuDescription);
  },
  checkApprovAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.approve === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.approve === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action approve :', found);
      return true;
    } else {
      console.log("Action approve :", found);
      return false;
    }
  },
  rejectAction: function (submenuDescription) {
    return this.checkRejectAction(RAL.GetStoreMenu(), submenuDescription);
  },
  checkRejectAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.reject === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.reject === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action reject :', found);
      return true;
    } else {
      console.log("Action reject :", found);
      return false;
    }
  },
  editAction: function (submenuDescription) {
    return this.checkEditAction(RAL.GetStoreMenu(), submenuDescription);
  },
  checkEditAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.edit === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.edit === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      // console.log('Action edit :', found);
      return true;
    } else {
      // console.log('Action edit :', found);
      return false;
    }
  },
  checkViewAction: function (myArr, keySub) {
    console.log(myArr);
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.view === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.view === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      return true;
    } else {
      console.log("Action view :", found);
      return false;
    }
  },
  viewAction: function (submenuDescription) {
    return this.checkViewAction(RAL.GetStoreMenu(), submenuDescription);
  },
  checkDeleteAction: function (myArr, keySub) {
    let found = false;
    for (let index = 0; index < myArr.length; index++) {
      const element = myArr[index];

      for (let sub = 0; sub < element.SUB_MENU.length; sub++) {
        const subElement = element.SUB_MENU[sub];

        if (
          subElement.DESCRIPTION.toLowerCase().trim() === keySub.toLowerCase()
        ) {
          if (subElement.ACTION.delete === "1") {
            found = true;
            break;
          }
        } else {
          for (const key in subElement.CHILD_SUB_MENU) {
            const childElmt = subElement.CHILD_SUB_MENU[key];

            if (
              childElmt.DESCRIPTION.toLowerCase().trim() ===
              keySub.toLowerCase()
            ) {
              if (childElmt.ACTION.delete === "1") {
                found = true;
                break;
              }
            }
          }
        }
      }
    } // end for

    if (found) {
      return true;
    } else {
      console.log("Action delete :", found);
      return false;
    }
  },
  deleteAction: function (submenuDescription) {
    return this.checkDeleteAction(RAL.GetStoreMenu(), submenuDescription);
  },
};

/** initialise notiflix pop up */
export const notifInitialization = () => {
  Notiflix.Confirm.init({
    className: "notiflix-confirm",
    width: "300px",
    zindex: 4003,
    position: "center",
    distance: "10px",
    backgroundColor: "#f8f8f8",
    borderRadius: "25px",
    backOverlay: true,
    backOverlayColor: "rgba(0,0,0,0.5)",
    rtl: false,
    fontFamily: "Quicksand",
    cssAnimation: true,
    cssAnimationDuration: 300,
    cssAnimationStyle: "fade",
    plainText: true,
    titleColor: "#EC4E4F",
    titleFontSize: "16px",
    titleMaxLength: 34,
    messageColor: "#1e1e1e",
    messageFontSize: "14px",
    messageMaxLength: 200,
    buttonsFontSize: "15px",
    buttonsMaxLength: 34,
    okButtonColor: "#f8f8f8",
    okButtonBackground: "#EC4E4F",
    cancelButtonColor: "#f8f8f8",
    cancelButtonBackground: "#a9a9a9",
  });
};

export const ggTemplateInit = () => {
  /** TEMPLATE JQUERY */
  $(document).ready(function () {
    // Add body-small class if window less than 768px
    if ($(this).width() < 769) {
      $("body").addClass("body-small");
    } else {
      $("body").removeClass("body-small");
    }

    // MetsiMenu
    $("#side-menu").metisMenu();

    // Collapse ibox function
    $(".collapse-link").click(function () {
      var ibox = $(this).closest("div.ibox");
      var button = $(this).find("i");
      var content = ibox.find("div.ibox-content");
      content.slideToggle(200);
      button.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
      ibox.toggleClass("").toggleClass("border-bottom");
      setTimeout(function () {
        ibox.resize();
        ibox.find("[id^=map-]").resize();
      }, 50);
    });

    // Close ibox function
    $(".close-link").click(function () {
      var content = $(this).closest("div.ibox");
      content.remove();
    });

    // Fullscreen ibox function
    $(".fullscreen-link").click(function () {
      var ibox = $(this).closest("div.ibox");
      var button = $(this).find("i");
      $("body").toggleClass("fullscreen-ibox-mode");
      button.toggleClass("fa-expand").toggleClass("fa-compress");
      ibox.toggleClass("fullscreen");
      setTimeout(function () {
        $(window).trigger("resize");
      }, 100);
    });

    // Close menu in canvas mode
    $(".close-canvas-menu").click(function () {
      $("body").toggleClass("mini-navbar");
      SmoothlyMenu();
    });

    // Run menu of canvas
    $("body.canvas-menu .sidebar-collapse").slimScroll({
      height: "100%",
      railOpacity: 0.9,
    });

    // Open close right sidebar
    $(".right-sidebar-toggle").click(function () {
      $("#right-sidebar").toggleClass("sidebar-open");
    });

    // Initialize slimscroll for right sidebar
    $(".sidebar-container").slimScroll({
      height: "100%",
      railOpacity: 0.4,
      wheelStep: 10,
    });

    // Open close small chat
    $(".open-small-chat").click(function () {
      $(this).children().toggleClass("fa-comments").toggleClass("fa-remove");
      $(".small-chat-box").toggleClass("active");
    });

    // // Initialize slimscroll for small chat
    // $(".small-chat-box .content").slimScroll({
    //   height: "234px",
    //   railOpacity: 0.4,
    // });

    // // Small todo handler
    // $(".check-link").click(function () {
    //   var button = $(this).find("i");
    //   var label = $(this).next("span");
    //   button.toggleClass("fa-check-square").toggleClass("fa-square-o");
    //   label.toggleClass("todo-completed");
    //   return false;
    // });

    // // Minimalize menu
    // $(".navbar-minimalize").click(function () {
    //   $("body").toggleClass("mini-navbar");
    //   SmoothlyMenu();
    // });

    // Tooltips demo
    $(".tooltip-demo").tooltip({
      selector: "[data-toggle=tooltip]",
      container: "body",
    });

    // Move modal to body
    // Fix Bootstrap backdrop issu with animation.css
    $(".modal").appendTo("body");

    // Full height of sidebar
    function fix_height() {
      var heightWithoutNavbar = $("body > #wrapper").height() - 61;
      $(".sidebard-panel").css("min-height", heightWithoutNavbar + "px");

      var navbarHeigh = $("nav.navbar-default").height();
      var wrapperHeigh = $("#page-wrapper").height();

      if (navbarHeigh > wrapperHeigh) {
        $("#page-wrapper").css("min-height", navbarHeigh + "px");
      }

      if (navbarHeigh < wrapperHeigh) {
        $("#page-wrapper").css("min-height", $(window).height() + "px");
      }

      if ($("body").hasClass("fixed-nav")) {
        if (navbarHeigh > wrapperHeigh) {
          $("#page-wrapper").css("min-height", navbarHeigh - 60 + "px");
        } else {
          $("#page-wrapper").css("min-height", $(window).height() - 60 + "px");
        }
      }
    }

    fix_height();

    // Fixed Sidebar
    $(window).bind("load", function () {
      if ($("body").hasClass("fixed-sidebar")) {
        $(".sidebar-collapse").slimScroll({
          height: "100%",
          railOpacity: 0.9,
        });
      }
    });

    // Move right sidebar top after scroll
    $(window).scroll(function () {
      if ($(window).scrollTop() > 0 && !$("body").hasClass("fixed-nav")) {
        $("#right-sidebar").addClass("sidebar-top");
      } else {
        $("#right-sidebar").removeClass("sidebar-top");
      }
    });

    $(window).bind("load resize scroll", function () {
      if (!$("body").hasClass("body-small")) {
        fix_height();
      }
    });

    $("[data-toggle=popover]").popover();

    // Add slimscroll to element
    $(".full-height-scroll").slimscroll({
      height: "100%",
    });
  });
  function SmoothlyMenu() {
    if (
      !$("body").hasClass("mini-navbar") ||
      $("body").hasClass("body-small")
    ) {
      // Hide menu in order to smoothly turn on when maximize menu
      $("#side-menu").hide();
      // For smoothly turn on menu
      setTimeout(function () {
        $("#side-menu").fadeIn(400);
      }, 200);
    } else if ($("body").hasClass("fixed-sidebar")) {
      $("#side-menu").hide();
      setTimeout(function () {
        $("#side-menu").fadeIn(400);
      }, 100);
    } else {
      // Remove all inline style from jquery fadeIn function to reset menu state
      $("#side-menu").removeAttr("style");
    }
  }
  /** END JQUERY */
};
