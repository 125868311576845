import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Main from "../../layouts/Main";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { apis as api } from "../../../services/api.action";
import ContentTop from "../../ui-setup/ContentTop";
import { cnt } from "../../../services/constant.action";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { Link } from "react-router-dom";
import appStatic from "../../../config/appStaticData";
// import { TableFacility, handleStatusBtnFac } from "../../layouts/Facility";
import Notiflix from "notiflix";

const ManageCode = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { location, userData, staticData } = props;
  const { state } = location;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const { tableData } = appStatic;
  const [codeData, setCodeData] = useState([]);
  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");
  const [color, setColor] = useState("");
  const [icon, setIcon] = useState("");
  const [isEdit, setisEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recordCount, setRecordCount] = useState(0);

  const [tiles, setTiles] = useState([
    {
      title: appLabel.authorized,
      otherTitle: "",
      color: "primary",
      icon: "thumbs-up",
      criteria: "ACTIVATED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
    {
      title: appLabel.unauthorized,
      otherTitle: "",
      color: "warning",
      icon: "thumbs-down",
      criteria: "DEACTIVATED",
      callback: function (params) {
        getListByCriteria(params);
      },
    },
  ]);

  useEffect(() => {
    getListByCriteria(state ? state.ggParam : tiles[0]);
  }, []);

  function getListByCriteria(params) {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);
    setRecordCount(0)

    setCodeData([]);
    setTitleLeft(params.otherTitle);
    setTitleRight(params.title);
    setColor(params.color);
    setIcon(params.icon);

    const body = {
      profileId: PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      criteria: params.criteria,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetCoCode, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.gpCodeListing;

        /** ADD KEY IN EACH OBJECT */
        const tempArr = objList.map((item) => {
          return {
            ...item,
            isSelected: false,
          };
        });
        setCodeData(tempArr);
        // console.log("tempArr: ", tempArr);
        setIsLoading(false);
        setRecordCount(tempArr.length)
        
      }
    });
  }
  const handleStatusBtn = (key) => {
    switch (key.toLowerCase()) {
      case "activated":
        return (
          <>
            <i className="fa fa-thumbs-down text-info" />{" "}
            {appLabel.deactivateBtn}
          </>
        );
      case "deactivated":
        return (
          <>
            <i className="fa fa-thumbs-up text-info" /> {appLabel.activateBtn}
          </>
        );
    }
  };
  const updateItem = (objParam) => {
    // console.log(objParam);

    const { GP_CODE_STATUS, GP_CODE_ID, GP_CODE_NAME } = objParam;
    const body = {
      profileId: PROFILE_ID,
      gpCodeId: GP_CODE_ID,
      userToken: token,
    };
    let statusLabel = "";
    switch (GP_CODE_STATUS.toLowerCase()) {
      case "activated":
        body.criteria = "DEACTIVATED";
        statusLabel = appLabel.deactivateBtn;
        break;
      case "deactivated":
        body.criteria = "ACTIVATED";
        statusLabel = appLabel.activateBtn;
        break;
    }

    // BOM.SendReqWithConfirm(body, api.ActiveInactiveCode, statusLabel);
    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.ActiveInactiveCode, (err, res) => {
          if (err) {
            // BOM.AlertMsg(cnt.DANGER, err);
            BOM.NotifyMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);

            /** REDIRECTION TABLE */
            switch (GP_CODE_STATUS.toLowerCase()) {
              case "activated":
                getListByCriteria(tiles[0]);
                break;
              case "deactivated":
                getListByCriteria(tiles[1]);
                break;
              default:
                getListByCriteria(tiles[0]);
                break;
            }
          }
        });
      },
      function () {
        // No button
      }
    );
  };

  const handleEditBtn = (objParam, keyParam) => {
    // console.log(objParam);

    switch (keyParam.toLowerCase()) {
      case "proceed":
        var tempArr = codeData.map((item) =>
          item.GP_CODE_ID === objParam.GP_CODE_ID
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        );
        break;

      default:
        var tempArr = codeData.map((item) =>
          item.GP_CODE_ID === objParam.GP_CODE_ID
            ? { ...item, isSelected: false }
            : { ...item, isSelected: false }
        );
        break;
    }

    // console.log(tempArr);
    setCodeData(tempArr);
  };
  const deleteItem = (objParam) => {
    const { GP_CODE_ID, GP_CODE_NAME, GP_CODE_STATUS } = objParam;

    let body = {
      profileId: PROFILE_ID,
      itemId: GP_CODE_ID,
      itemType: "code",
      userToken: token,
    };

    // console.log(body);

    const statusLabel = appLabel.deleteBtn + " " + GP_CODE_NAME;
    // BOM.SendReqWithConfirm(body, api.DeleteItem, statusLabel);
    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.DeleteItem, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
            // BOM.NotifyMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);
            setCodeData(
              codeData.filter((item) => item.GP_CODE_ID !== GP_CODE_ID)
            );
          }
        });
      },
      function () {
        // No button
      }
    );
  };
  const onFormSubmit = (objParam) => {
    const { GP_CODE_ID, GP_CODE_NAME, GP_CODE_STATUS } = objParam;
    // console.log(objParam);

    const codeName = document.getElementById("codeName" + GP_CODE_ID).value;
    const codeDescription = document.getElementById(
      "codeDescription" + GP_CODE_ID
    ).value;

    const body = {
      profileId: PROFILE_ID,
      gpCodeId: GP_CODE_ID,
      codeName,
      codeDescription,
      userToken: token,
    };

    // console.log(body);
    // return;
    const statusLabel = appLabel.editCode;
    // BOM.SendReqWithConfirm(body, api.EditCoCode, statusLabel);

    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.EditCoCode, (err, res) => {
          if (err) {
            // BOM.AlertMsg(cnt.DANGER, err);
            BOM.NotifyMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);

            /** REDIRECTION TABLE */
            switch (GP_CODE_STATUS.toLowerCase()) {
              case "activated":
                getListByCriteria(tiles[0]);
                break;
              case "deactivated":
                getListByCriteria(tiles[1]);
                break;
              default:
                getListByCriteria(tiles[0]);
                break;
            }
          }
        });
      },
      function () {
        // No button
      }
    );
  };

  const handleSkeletonFac = () =>
    Array(4)
      .fill()
      .map((el, elIndex) => (
        <div key={elIndex} className="feed-element">
          <span className="pull-left text-navy">
            <Skeleton circle={true} height={35} width={35} />
          </span>
          <div className="media-body ">
            <small className="pull-right"></small>
            <strong>
              {" "}
              <Skeleton />
            </strong>{" "}
            <br />
            <Skeleton />
            <Skeleton />
            <div className="actions">
              <a className="btn btn-xs btn-white">
                <Skeleton height={18} width={40} />
              </a>
              <a className="btn btn-xs btn-white">
                <Skeleton height={18} width={40} />
              </a>
              <a className="btn btn-xs btn-white">
                <Skeleton height={18} width={40} />
              </a>
            </div>
          </div>
        </div>
      ));

  /** RENDER JXS DATA */
  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.manageCode} />

      <div className="animated fadeInRight">
        <div className="row m-b">
          <div className="col-xs-12 col-xs-offset-2 col-sm-6 col-sm-offset-4 col-md-6 col-md-offset-4">
            <div data-toggle="buttons" className="btn-group">
              <label
                className={`${
                  ROLE_SHORT.toLowerCase() === "sys"
                    ? "hide"
                    : "btn btn-sm btn-white"
                }`}
              >
                <Link to="/new-code" style={{ color: "inherit" }}>
                  <i className="fa fa-plus-circle text-info" /> &nbsp;{" "}
                  {appLabel.add1}{" "}
                </Link>
              </label>
              <label className="btn btn-sm btn-white">
                <a
                  className="dropdown-toggle"
                  style={{ color: "inherit" }}
                  data-toggle="dropdown"
                >
                  <i className={`fa fa-${icon} text-info`}></i> &nbsp;{" "}
                  {/* {titleRight} &nbsp; */}
                  {`${titleRight} ( ${recordCount} )`} &nbsp;
                  <i className="fa fa-chevron-down"></i>
                </a>
                <ul className="dropdown-menu dropdown-user">
                  {tiles.map((tile, tileIndex) => {
                    if (tile.title !== titleRight) {
                      return (
                        <li key={tileIndex}>
                          <a onClick={() => tile.callback(tile)}>
                            {" "}
                            {tile.title}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                {/* <h5></h5> */}
                <div className="ibox-tools">
                  <span className="label label-navy-light pull-right">
                    {`${
                      codeData.length > 1
                        ? codeData.length + " Records"
                        : codeData.length + " Record"
                    }`}
                  </span>
                </div>
              </div>
              <div className="ibox-content panel-primary contentCard">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="feed-activity-list">
                      {isLoading ? (
                        handleSkeletonFac()
                      ) : (
                        <>
                          {codeData.length === 0 && (
                            <div className="alert alert-warning text-center">
                              <a className="alert-link">
                                {appLabel.noDataAvailableMsg}
                              </a>
                            </div>
                          )}
                          {codeData.map((item, index) => (
                            <div key={index} className="feed-element">
                              <span className="pull-left text-navy">
                                <i className="fa fa-code-fork fa-2x" />
                              </span>
                              <div className="media-body ">
                                <small className="pull-right"></small>
                                <strong>{item.GP_CODE_NAME}</strong> <br />
                                {/* <small className="text-muted">
                              Yesterday 5:20 pm - 12.06.2014
                            </small> */}
                                <div
                                  className={`${
                                    item.isSelected ? "hide" : "well"
                                  }`}
                                >
                                  {item.GP_CODE_DESCRIPTION}
                                </div>
                                <div
                                  className={`${item.isSelected ? "" : "hide"}`}
                                >
                                  <input
                                    type="text"
                                    className="form-control  list-text-area"
                                    id={`codeName${item.GP_CODE_ID}`}
                                    placeholder={appLabel.codeName}
                                    defaultValue={item.GP_CODE_NAME}
                                  />
                                  <textarea
                                    rows={2}
                                    cols={5}
                                    className="form-control  list-text-area"
                                    id={`codeDescription${item.GP_CODE_ID}`}
                                    placeholder={appLabel.codeDescription}
                                    defaultValue={item.GP_CODE_DESCRIPTION}
                                  ></textarea>
                                </div>
                                <div
                                  className={`${
                                    item.isSelected ? "hide" : "actions"
                                  }`}
                                >
                                  <a
                                    className="btn btn-xs btn-white m-r"
                                    onClick={() =>
                                      handleEditBtn(item, "proceed")
                                    }
                                  >
                                    <i className="fa fa-edit text-info" />{" "}
                                    {appLabel.edit}
                                  </a>
                                  <a
                                    className="btn btn-xs btn-white m-r"
                                    onClick={() => updateItem(item)}
                                  >
                                    {handleStatusBtn(item.GP_CODE_STATUS)}
                                  </a>
                                  <a
                                    className="btn btn-xs btn-white"
                                    onClick={() => deleteItem(item)}
                                  >
                                    <i className="fa fa-times text-info" />{" "}
                                    {appLabel.deleteBtn}
                                  </a>
                                </div>
                                <div
                                  className={`${
                                    item.isSelected ? "actions" : "hide"
                                  }`}
                                >
                                  <a
                                    className="btn btn-xs btn-white m-r"
                                    onClick={() => onFormSubmit(item)}
                                  >
                                    <i className="fa fa-save text-info" />{" "}
                                    {appLabel.save}
                                  </a>
                                  <a
                                    className="btn btn-xs btn-white m-r"
                                    onClick={() =>
                                      handleEditBtn(item, "cancel")
                                    }
                                  >
                                    <i className="fa fa-times text-navy" />{" "}
                                    {appLabel.cancelBtn}
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ManageCode);
