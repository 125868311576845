import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { ColBlockFac } from "../../layouts/Facility";
import history from "../../../history";

function NewCode(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    resetField: resetField2,
    formState: { errors: errors2 },
  } = useForm();

  const { location, userData } = props;
  const { state } = location;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;

  useEffect(() => {}, []);

  const formSubmit = (formValues) => {
    let body = {
      profileId: PROFILE_ID,
      coId: CO_ID,
      ...formValues,
      userToken: token,
    };

    // console.log(body);
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    /** SEND REQ */
    // BOM.SendReqAction(body, api.NewCoCode, "/manage-code");

    BOM.FetchReqAction(body, api.NewCoCode, (err, res) => {
      if (err) {
        // BOM.AlertMsg(cnt.DANGER, err);
        BOM.NotifyMsg(cnt.DANGER, err);
      } else {
        BOM.NotifyMsg(cnt.SUCCESS, res.message);
        history.push("/manage-code", {
          ggParam: BOM.RedirectListObj,
        });
      }
    });
  };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.newCode} />

      <form method="POST" id="NewItemForm" autoComplete="off">
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5></h5>
                  <div className="ibox-tools">
                    <Link
                      to="/manage-code"
                      className="btn btn-white btn-xs m-r"
                    >
                      <i className="fa fa-arrow-circle-left" />
                      &nbsp;
                      {appLabel.backBtn}
                    </Link>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <ColBlockFac>
                      <div className="feed-activity-list">
                        <div className="feed-element">
                          <div className="media-body ">
                            <label className="al-subtitle">
                              {appLabel.codeName}{" "}
                              <span className="asterisks">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control  list-text-area"
                              placeholder={appLabel.codeName}
                              {...register("codeName", {
                                required: appLabel.fieldReq,
                              })}
                            />
                            <span className="asterisks">
                              {errors?.codeName?.message}
                            </span>

                            <div>
                              <label
                                className="al-subtitle"
                                style={{ marginTop: "10px" }}
                              >
                                {appLabel.codeDescription}
                              </label>
                            </div>
                            <textarea
                              rows={5}
                              cols={50}
                              className="form-control  list-text-area"
                              placeholder={appLabel.codeDescription}
                              {...register("codeDescription")}
                            ></textarea>
                            <div className="actions">
                              <a
                                className={`${
                                  ROLE_SHORT.toLowerCase() === "sys"
                                    ? "hide"
                                    : "btn btn-info btn-xs"
                                }`}
                                onClick={handleSubmit(formSubmit)}
                              >
                                <i className="fa fa-save" />
                                &nbsp;{appLabel.save}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ColBlockFac>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewCode);
