
/**
 * @author Gedeon NdundeCode
 * @date 10/2022
 */
 import React, { useState, useEffect } from "react";
 import useGeolocation from "react-hook-geolocation";
 import { connect, useDispatch } from "react-redux";
 import { Link } from "react-router-dom";
 import { useForm } from "react-hook-form";
 import { cnt } from "../../services/constant.action";
 import { BOM } from "../../services/defined.action";
 import {
   SignInAction,
   SignOutAction,
 } from "../../services/redux/actions/auth-act";
 import appLabel from "../../config/appLabel";
 import Logo from "../../assets/img/logoLogin/ncare_black.png";
 import Bg from "../../assets/img/logoLogin/bg1.jpg";
 
 const LockScreen = (props) => {
   /** INITIALIZE VARIABLES */
   const {
     register,
     handleSubmit,
     formState: { errors },
   } = useForm();
   const { SignInAction, SignOutAction, lockscreenData } = props;
   const {username,fullName} = lockscreenData
   const [passwordShown, setPasswordShown] = useState(false);
 
   const dispatch = useDispatch();
 
 
   useEffect(() => {
     dispatch({
       type: cnt.LOCKSCREEN
     });
   }, []);
 
   // Password toggle handler
   const togglePassword = () => {
     setPasswordShown(!passwordShown);
   };
   /** SUBMISSION */

   const onSignIn = (formData) => {
    formData.username = username;
    const body = {
      ...formData,
    };
    // console.log(body);

    SignInAction(body);
  };
 
   return (
     <React.Fragment>
        <img className="auth-bg" src={Bg} />
       <div className="centeredContent">
         <div className="row">
           <div className="col-md-12">
               <div
                 className="middle-box loginscreen animated fadeInDown login-card"
                 style={{ marginBottom: "0%" }}
               >
                 <div
                   className="text-center"
                 >
                   <img
                     alt="image"
                     style={{ width: "150px", marginTop: "10px", marginBottom: "25px" }}
                     src={Logo}
                   />
                 </div>
 
                 {/* <div className="slogan text-center">
                   <h2>&nbsp;</h2>
                 </div> */}
                 <form
                   // className="m-t"
                   onSubmit={handleSubmit(onSignIn)}
                   autoComplete="off"
                 >
                   <div className="form-group">
                     <label className="text-left">
                       <p style={{ fontWeight: "normal", fontSize:'14px' }}>
                         Hi, <strong>{fullName}</strong>, please enter your nCare password to login.
                       </p>
                      
                     </label>
                   </div>
                   <div className="form-group">
                     <label className="text-left">{appLabel.password}</label>
                     <input
                       type={passwordShown ? "text" : "password"}
                       className="form-control input-sm list-text-area"
                       placeholder="********"
                       {...register("password", {
                         required: "Field is required.",
                         minLength: {
                            value: 3,
                            message: appLabel.pwdShort,
                          },
                       })}
                     />
                     <span className="p-viewer" onClick={() => togglePassword()}>
                       <i
                         className={
                           passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                         }
                       ></i>
                     </span>
                     <span className="asterisks">
                       {errors?.password?.message}
                     </span>
                   </div>
                   <div className="form-group text-center">
                     <button
                       type="submit"
                       className="btn btn-primary btn-sm block full-width"
                       style={{
                         marginTop: 30,
                         boxShadow: "0 10px 6px -6px #fff",
                       }}
                     >
                       <i className="fa fa-unlock" /> &nbsp;{" "}
                       {appLabel.unlockBtn}
                     </button>
                   </div>
                   <div className="form-group text-center">
                     <Link to="/sign-in"
                       style={{ fontWeight: "bold", color: "rgb(204, 153, 61)" }}
                     >
                      <i className="fa fa-chevron-circle-left" /> <small>{appLabel.goToLogin}</small>
                     </Link>
                   </div>
                 </form>
               </div>
             
           </div>
         </div>
       </div>
     </React.Fragment>
   );
 };
 
 const mapStateToProps = (state) => ({
   userData: state.auth.userData,
   lockscreenData: state.auth.lockscreenData,
 });
 const mapDispatchToProps = { SignInAction, SignOutAction }
 
 export default connect(mapStateToProps, mapDispatchToProps)(LockScreen);