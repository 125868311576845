import React, { useEffect, useState } from "react";
import { apis as api } from "../../../services/api.action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { cnt } from "../../../services/constant.action";
import Main from "../../layouts/Main";
import ContentTop from "../../ui-setup/ContentTop";
import history from "../../../history";
import { BOM } from "../../../services/defined.action";
import appLabel from "../../../config/appLabel";
import { RecordStatus } from "../../layouts/Facility";

function UserDetail(props) {
  const { location, userData, staticData } = props;
  const { state } = location;
  const { ggParam } = state;
  const { PROFILE_ID, token, ROLE_SHORT, CO_ID } = userData;
  const { coConfig } = staticData;

  const [userDetail, setUserDetail] = useState({});
  const [statusBtn, setStatusBtn] = useState("");
  const [record, setRecord] = useState({});

  useEffect(() => {
    // console.log(state.ggParam);
    getDetailById(state.ggParam);
  }, []);
  const getDetailById = (params) => {
    BOM.LoadAlert(cnt.LOAD, "Processing");

    let body = {
      profileId: PROFILE_ID,
      userId: params.PROFILE_ID,
      coId: BOM.PrepareConfigData(ROLE_SHORT, CO_ID, coConfig.CO_ID),
      roleShort: ROLE_SHORT,
      userToken: token,
    };

    /** FETCH ROLE */
    BOM.FetchReqAction(body, api.GetProfile, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objDetail = res.profileDetail;
        // handleStatusBtn(objDetail.USER_STATUS);
        setUserDetail(objDetail);
        setRecord(objDetail.RECORD_STATUS);
      }
    });
  };
  const updateItem = (agentStatus, statusLabel) => {
    let body = {
      profileId: PROFILE_ID,
      userId: ggParam.PROFILE_ID,
      agentStatus,
      userToken: token,
    };

    // console.log(body);

    BOM.GlobalApproveRejectAction(
      body,
      api.ActiveInactiveProfile,
      "manage-user",
      ggParam.USER_FNAME,
      statusLabel + " "
    );
  };
  const handleStatusBtn = (key) => {
    let tempBtn = "";
    setStatusBtn(tempBtn);

    switch (key.toLowerCase()) {
      case "activated":
        return setStatusBtn(
          <button
            type="button"
            className={`${
              ROLE_SHORT.toLowerCase() === "sys"
                ? "hide"
                : "btn btn-info btn-xs"
            }`}
            onClick={() => updateItem("DEACTIVATED", appLabel.deactivateBtn)}
          >
            <i className="fa fa-thumbs-down" />
            &nbsp; {appLabel.deactivateBtn}
          </button>
        );
      case "deactivated":
        return setStatusBtn(
          <button
            type="button"
            className={`${
              ROLE_SHORT.toLowerCase() === "sys"
                ? "hide"
                : "btn btn-info btn-xs"
            }`}
            onClick={() => updateItem("ACTIVATED", appLabel.activateBtn)}
          >
            <i className="fa fa-thumbs-up" />
            &nbsp; {appLabel.activateBtn}
          </button>
        );
    }

    setStatusBtn(tempBtn);
  };
  // const deleteItem = () => {
  //   let body = {
  //     profileId: PROFILE_ID,
  //     vaId: userDetail.VA_ID,
  //     userToken: token,
  //   };

  //   // console.log(body);

  //   BOM.GlobalApproveRejectAction(
  //     body,
  //     api.DeleteVisaApplication,
  //     "manage-visa-application",
  //     userDetail.VA_ID,
  //     "Supprimer la demande n° "
  //   );
  // };

  return (
    <Main {...props}>
      <ContentTop pageTitle={appLabel.manageUser} />

      <form method="POST" autoComplete="off">
        <div className="animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox">
                <div className="ibox-title">
                  <div className="ibox-tools">
                    <a
                      onClick={() => BOM.BackButton()}
                      className="btn btn-white btn-xs m-r"
                    >
                       <i className="fa fa-long-arrow-left text-info" />
                      &nbsp;
                      {appLabel.backBtn}
                    </a>
                  </div>
                </div>
                <div className="ibox-content contentCard panel-primary">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="text-center">
                        <img
                          src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          className="avatar img-circle img-thumbnail"
                          width={100}
                          alt="avatar"
                        />
                      </div>
                      <br />
                    </div>
                    <div className="col-sm-9">
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a
                            data-toggle="tab"
                            href="#personalInfo"
                            target="_self"
                          >
                            <i className="fa fa-user text-navy" />
                            &nbsp; {appLabel.personalInfo}
                          </a>
                        </li>
                        <li>
                          <a
                            data-toggle="tab"
                            href="#contactInfo"
                            target="_self"
                          >
                            <i className="fa fa-phone text-navy" />
                            &nbsp; {appLabel.contactInformation}
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#roleInfo" target="_self">
                            <i className="fa fa-tag text-navy" />
                            &nbsp;{appLabel.roleName}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="personalInfo">
                          <div className="row" style={{ marginTop: "50px" }}>
                            <div className="col-sm-12">
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.userId}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm list-text-area"
                                  defaultValue={userDetail.PROFILE_ID}
                                  disabled
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.username}
                                </label>
                                <input
                                  type="email"
                                  className="form-control input-sm list-text-area"
                                  defaultValue={userDetail.USERNAME}
                                  disabled
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.lastName}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm list-text-area"
                                  defaultValue={userDetail.USER_LNAME}
                                  disabled
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.mdleName}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm list-text-area"
                                  defaultValue={userDetail.USER_MDL_NAME}
                                  disabled
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.firstName}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm list-text-area"
                                  defaultValue={userDetail.USER_FNAME}
                                  disabled
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.dob}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm list-text-area"
                                  defaultValue={userDetail.USER_DOB}
                                  disabled
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.gender}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm list-text-area"
                                  defaultValue={userDetail.USER_GENDER}
                                  disabled
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label className="al-subtitle">
                                  {appLabel.lastLogin}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm list-text-area"
                                  defaultValue={userDetail.USER_LAST_LOGIN}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="contactInfo">
                          <div className="row" style={{ marginTop: "50px" }}>
                            <div className="form-group col-md-4">
                              <label className="al-subtitle">
                                {appLabel.userEmail}
                              </label>
                              <input
                                type="email"
                                className="form-control input-sm list-text-area"
                                defaultValue={userDetail.USER_EMAIL}
                                disabled
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="al-subtitle">
                                {appLabel.phoneNumber}
                              </label>
                              <input
                                type="text"
                                className="form-control input-sm list-text-area"
                                defaultValue={userDetail.USER_PHONE}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="roleInfo">
                          <div className="row" style={{ marginTop: "50px" }}>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="al-subtitle">
                                  {appLabel.roleName}
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-sm list-text-area"
                                  defaultValue={userDetail.ROLE_NAME}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*/tab-content*/}
                    <RecordStatus record={record} />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  staticData: state.staticData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
