/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
 import React from "react";
 import { Provider } from "react-redux";
 import { Router } from "react-router-dom";
 import { store, persistor } from "./services/redux/store";
 import { PersistGate } from "redux-persist/integration/react";
 import { RoutedContent } from "./routes";
 import history from "./history";

//  import './assets/css/bootstrap.min.css'
// import './assets/font-awesome/css/font-awesome.css'
// import './assets/css/animate.css'
// import './assets/css/style.css'

 const App = () => {
   return (
     <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
         <Router history={history}>
           <RoutedContent />
         </Router>
       </PersistGate>
     </Provider>
   );
 };
 
 export default App;
 