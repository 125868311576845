/**
 * @author Gedeon NdundeCode
 * @date 10/2022
 */
import React, { useState, useEffect } from "react";
import useGeolocation from "react-hook-geolocation";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apis as api } from "../../services/api.action";

import {
  SignInAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import appLabel from "../../config/appLabel";
import Logo from "../../assets/img/logoLogin/ncare_black.png";
import Bg from "../../assets/img/logoLogin/bg1.jpg";
import { SubmitBtnLoad } from "../layouts/Facility";
import { BOM } from "../../services/defined.action";
import { cnt } from "../../services/constant.action";


const SignIn = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { SignInAction, SignOutAction, history } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const [ip, setIP] = useState({});
  // const geolocation = useGeolocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    SignOutAction();
  }, []);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);

    // console.log("latitude: ", geolocation.latitude);
    // console.log("longitude: ", geolocation.longitude);
  };

  /** SUBMISSION */
  const onSignIn = (formData) => {
    const body = {
      ...formData,
    };
    // console.log(body);

    // SignInAction(body);
    setIsLoading(true)
    // return
    BOM.SessionLessReqAction(body, api.Login, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false)
      } else {

        const objDetail= res.userData;

        // BOM.NotifyMsg(cnt.SUCCESS, `Welcome back ${objDetail.USER_TITLE} ${objDetail.USER_LNAME}`);

        dispatch({
          type: cnt.SIGN_IN,
          payload: objDetail,
        });

        /** REDIRECT USER */
        switch (objDetail.ROLE_SHORT.toLowerCase()) {
          case "sys":
            history.push("/landing");
            break;
          default:
            switch (objDetail.USER_DEFAULT_PWD.toLowerCase()) {
              case "default":
                history.push("/change-password");
                break;
              case "edited":
                history.push("/dashboard");
                break;
            }
            break;
        }
      }
    });
  };

  return (
    <React.Fragment>
      <img className="auth-bg" src={Bg} />
      <div className="centeredContent">
        <div className="row">
          <div className="col-sm-12">
            <div
              className="middle-box loginscreen animated fadeInDown login-card"
              style={{ marginBottom: "0%" }}
            >
              <div
                className="text-center"
                // style={{
                //   height: "150px",
                //   backgroundColor: "#fff",
                //   borderRadius: "3px",
                // }}
              >
                <img
                  alt="image"
                  style={{ width: "150px", marginTop: "10px"}}
                  src={Logo}
                />
              </div>

              <div className="slogan text-center">
                <h2>{appLabel.mainTitle}</h2>
              </div>
              <form
                // className="m-t"
                onSubmit={handleSubmit(onSignIn)}
                autoComplete="off"
              >
                <div className="form-group">
                  <label  className="al-subtitle">{appLabel.username} </label>
                  <input
                    type="text"
                    className={`form-control input-sm list-text-area $ ${
                      errors.userName ? "outline-error" : ""
                    }`}
                    placeholder={appLabel.username}
                    {...register("username", {
                      required: appLabel.fieldReq,
                    })}
                  />
                  <span className="asterisks">{errors?.username?.message}</span>
                </div>
                <div className="form-group">
                  <label  className="al-subtitle">{appLabel.password}</label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control input-sm list-text-area"
                    placeholder={appLabel.password}
                    {...register("password", {
                      required: appLabel.fieldReq,
                      minLength: {
                        value: 3,
                        message: appLabel.pwdShort,
                      },
                    })}
                  />
                  <span className="p-viewer" onClick={() => togglePassword()}>
                    <i
                      className={
                        passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                      }
                    ></i>
                  </span>
                  <span className="asterisks">{errors?.password?.message}</span>
                </div>
                <div className="form-group text-center">
                  {/* <button
                    type="submit"
                    className="btn btn-primary btn-sm block full-width"
                    style={{
                      marginTop: 30,
                      boxShadow: "0 10px 6px -6px #fff",
                    }}
                  >
                    <i className="fa fa-sign-in" /> &nbsp; {appLabel.btnLogin}
                  </button> */}

                  <SubmitBtnLoad isLoading={isLoading} btnName={appLabel.btnLogin} isFullWidth={true} icon={"fa fa-sign-in"}  />
                </div>
                <div className="form-group text-center">
                  <Link
                    to="/forgot-password"
                    style={{ fontWeight: "bold", color: "gray" }}
                  >
                    <small>{appLabel.forgotpass}</small>
                  </Link>
                </div>
                <div className="form-group text-center">
                  <label className="text-left">
                    <small>{appLabel.newTo}</small>
                  </label>
                  &nbsp;
                  <Link
                    to="/new-company"
                    style={{ fontWeight: "bold", color: "#EC4E4F" }}
                  >
                    <small>{appLabel.createAccount}</small>
                  </Link>
                </div>
              </form>
            </div>
            <div
              className="middle-box text-center text-white loginscreen animated fadeInDown"
              style={{ marginTop: 0, zIndex: 2 }}
            >
              {appLabel.poweredBy}{" "}
              <a
                href="https://nacomoda.com/"
                target="_blank"
                style={{
                  fontWeight: "900",
                  fontSize: "16px",
                  color: "inherit",
                }}
              >
                {appLabel.poweredByLabel}
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { SignInAction, SignOutAction })(SignIn);
