// import _ from "lodash";
import { cnt } from "../../constant.action";
import { BOM } from "../../defined.action";

const INITIAL_STATE = {
  success_response: {},
  failed_response: {},
  coConfig: {},
  menuData: [],
};
export const staticDataRed = (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case cnt.RESPONSE_SUCCESS_DATA:
      return {
        ...state,
        success_response: payload,
      };
    case cnt.RESPONSE_FAILED_DATA:
      return {
        ...state,
        failed_response: payload,
      };
    case cnt.CURRENT_CO:
      return {
        ...state,
        coConfig: payload,
      };
    case cnt.MENU_DATA:
      return {
        ...state,
        menuData: payload,
      };
    case cnt.CLEAR_STATIC_DATA:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

const RIGHT_INITIAL_STATE = {
  addBtn: "btn-default disabled",
  saveBtn: "btn-default disabled",
  detailBtn: "btn-default disabled",
  editBtn: "btn-default disabled",
  deleteBtn: "btn-default disabled",
  approveBtn: "btn-default disabled",
  rejectBtn: "btn-default disabled",
};
export const rightSideBtnRed = (state = RIGHT_INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case cnt.RIGHT_ADD_BTN:
      return { ...state, addBtn: "btn-primary" };
    case cnt.RIGHT_SAVE_BTN:
      return { ...state, saveBtn: "btn-primary" };
    case cnt.RIGHT_DETAIL_BTN:
      return { ...state, detailBtn: "btn-primary" };
    case cnt.RIGHT_EDIT_BTN:
      return { ...state, editBtn: "btn-primary" };
    case cnt.RIGHT_DELETE_BTN:
      return { ...state, deleteBtn: "btn-primary" };
    case cnt.RIGHT_APPROVE_BTN:
      return { ...state, approveBtn: "btn-primary" };
    case cnt.RIGHT_REJECT_BTN:
      return { ...state, rejectBtn: "btn-primary" };
    case cnt.CLEAR_RIGHT_BTN:
      return { ...RIGHT_INITIAL_STATE };
    default:
      return state;
  }
};

const INITIAL_RECORD_STATE = {
  FLG_STATUS: "U",
  MAKER_ID: "gedeon@openfactorgroup.com",
  MAKER_DATE: BOM.CurrentDate(),
  CHECKER_ID: "Not Applicable",
  CHECKER_DATE: "Not Applicable",
};
export const recordStatus = (state = INITIAL_RECORD_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case cnt.RECORD_STATUS:
      return payload;
    case cnt.CLEAR_STATIC_DATA:
      return {
        ...INITIAL_RECORD_STATE,
      };

    default:
      return state;
  }
};
